import axios from 'axios';
import { pdfjs } from "react-pdf"
import emailjs from '@emailjs/browser';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewContract({ showContract, setShowContract, contractPreview, contract, user, loading, project, preSigFile }){
    const [sent, setSent] = useState(false);

    const handleSendRequest = async () => {
        const pdf = await pdfjs.getDocument({ url: contractPreview }).promise;
        const numPages = pdf.numPages;
        let signaturePageNumber = null;

        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map((item) => item.str).join(" ");
            if (pageText.includes("IN WITNESS WHEREOF")) {
                signaturePageNumber = pageNumber;
                break;
            }
        }

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contract/" + contract.id + "/",
            data: {
                status: true,
                project: contract.project,
                pre_signature_contract: preSigFile,
                sign_page: signaturePageNumber,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
        .then(response => {
            console.log(response);
            setSent(true);
        })
        .catch(error => {
            console.log(error);
        })

        //axios put project
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/project/" + project.id + "/",
            data: {
                address: project.address,
                desc: project.desc,
                published: true,
                owner: project.owner,
                signature_requested: true,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        //send email
        if (project.bid_choice) {
            emailjs.send('service_p6pe8ud', 'template_mkp49db', {
                subject: 'Bobyard contract signature request',
                message: 
                `Hello ${contract.new_contractor_name},
    
                ${contract.new_name} is requesting your signature, please log in to sign:
                `,
                link_message: "Sign contract",
                url: 'https://www.bobyard.com/contractor-sign/' + project.uuid,
                ending: 
                `Best wishes,
                Bobyard Team`,
                to_email: contract.new_contractor_email,
                from_name: contract.new_name,
                reply_to: 'info@bobyard.com',
                bcc: 'mding26@stanford.edu'
            }, 'UPyEH6AdQ86GCk5j6')
            .then((result) => {
                console.log(result);
            }, (error) => {
                console.log(error); 
            });

            emailjs.send('service_p6pe8ud', 'template_yynx8io', {
                subject: `Bobyard project, signature request sent`,
                message: 
                `Hello ${contract.new_name},
                
                This is confirmation that your signature request has been sent to the contractor. After they sign, you will be able to complete the agreement by countersigning. 
                
                Best wishes,
                Bobyard team`,
                to_email: contract.new_email,
                from_name: "Bobyard",
                reply_to: 'info@bobyard.com',
                bcc: 'mding26@stanford.edu'
            }, 'UPyEH6AdQ86GCk5j6')
            .then((result) => {
                console.log(result);
            }, (error) => {
                console.log(error); 
            });
        }else if(project.bid_added_choice){
            emailjs.send('service_p6pe8ud', 'template_mkp49db', {
                subject: 'Bobyard contract signature request',
                message: 
                `Hello ${contract.new_contractor_name},
    
                ${contract.new_name} is requesting your signature, please click in the link below to sign:
                `,
                link_message: "Sign contract",
                url: 'https://www.bobyard.com/sign-contract/' + project.uuid,
                ending: 
                `Best wishes,
                Bobyard Team`,
                to_email: contract.new_contractor_email,
                from_name: contract.new_name,
                reply_to: 'info@bobyard.com',
                bcc: 'mding26@stanford.edu'
            }, 'UPyEH6AdQ86GCk5j6')
            .then((result) => {
                console.log(result);
            }, (error) => {
                console.log(error); 
            });

            emailjs.send('service_p6pe8ud', 'template_yynx8io', {
                subject: `Bobyard project, signature request sent`,
                message: 
                `Hello ${contract.new_name}},
                
                This is confirmation that your signature request has been sent to the contractor. After they sign, you will be able to complete the agreement by countersigning. 
                
                Best wishes,
                Bobyard team`,
                to_email: contract.new_email,
                from_name: "Bobyard",
                reply_to: 'info@bobyard.com',
                bcc: 'mding26@stanford.edu'
            }, 'UPyEH6AdQ86GCk5j6')
            .then((result) => {
                console.log(result);
            }, (error) => {
                console.log(error); 
            });
        }
    }

    return (
        <Modal show={showContract} onHide={() => setShowContract(false)} className="viewcontract-modal">
            <Modal.Header closeButton className='viewcontract-header'>
                <Modal.Title className='viewcontract-title'>Preview contract</Modal.Title>
                {sent 
                ?   <div className='viewcontract-sent'><nobr>Email sent!</nobr></div>
                :   <div><small><b>Warning</b>: once you request, everything becomes view only (including finalized bid and contract).</small></div>
                }
                {(!user?.groups?.includes(3) && contract?.new_contractor_name && contract?.new_name && contract?.new_contractor_email && contract?.new_contractor_email) && 
                    <button onClick={() => handleSendRequest()} className='active-button'><nobr>Request signatures</nobr></button>
                }
            </Modal.Header>

            <Modal.Body>
                {loading 
                ?   <div className="editproject-sowgeneration-spinner-container">
                        <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                        Loading...
                    </div>
                :   <iframe src={contractPreview} type="application/pdf" className='viewcontract-file'/>
                }
            </Modal.Body>
        </Modal>
    )
}