import { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';

import "../styles/ContractorDocument.css";
import { SOW } from '../data/SOW';
import NotFoundPage from "../components/NotFoundPage";
import DropDownMenu from "../HomeOwnerPages/EditProjectDetailPages/DropDownMenu";
import ContractorProjectRenderPDF from "./ContractorCreateProjectPages/ContractorProjectRenderPDF";
import ContractorProjectPDFTemplate from "./ContractorCreateProjectPages/ContractorProjectPDFTemplate";
import { shapes } from "konva/lib/Shape";
import ContractorCreatedProjectSideNav from "./ContractorCreateProjectPages/ContractorCreatedProjectSideNav";
import InfoHover2 from "../components/InfoHover2";
import InfoHover3 from "../components/InfoHover3";

import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Parchment = Quill.import('parchment');
let ClassAttr = new Parchment.Attributor.Attribute('class', 'class', { scope: Parchment.Scope.INLINE });
let EditAttr = new Parchment.Attributor.Attribute('contenteditable', 'contenteditable', { scope: Parchment.Scope.INLINE });
let IdAttr = new Parchment.Attributor.Attribute('commentid','commentid', { scope: Parchment.Scope.INLINE});
Parchment.register(IdAttr);
// Parchment.register(ClassAttr);
Parchment.register(EditAttr);

// let node = document.createElement('div');
// Align.add(node, 'right');
// console.log(node.outerHTML);  // Will print <div class="blot-align-right"></div>

// Quill.register(dataId);
export default function ContractorDocument({ user }){
    const params = useParams();
    const projectUUID = params.projectUUID;
    const navigate = useNavigate();
    
    const location = useLocation();
    const [sID, ] = useState(location.state?.sID);
    // const sID = parseInt(params.sID);

    const [project, setProject] = useState();
    const [contractor, setContractor] = useState();

    const [projectTotals, setProjectTotals] = useState([]);
    const [projectTotal, setProjectTotal] = useState(0);

    const [showSectionDetail, setShowSectionDetail] = useState({});

    const [descEdit, setDescEdit] = useState(false);
    const [desc, setDesc] = useState('');
    const [extraDesc, setExtraDesc] = useState('');
    const [extra, setExtra] = useState(0);

    const [loading, setLoading] = useState(true);
    // const [toSow, setToSow] = useState(false);

    const [published, setPublished] = useState(false);
    const [addingSection, setAddingSection] = useState(false);
    const [notFoundError, setNotFoundError] = useState(false);

    useEffect(() => {
        if(!location.state?.sID){
            // setToSow(true);
            navigate("/contractor-createproject/" + projectUUID);
        }
    }, [location])

    useEffect(() => {
        if(user?.groups.includes(1)){
            setNotFoundError(true);        
        }
    }, [user])

    useEffect(() => {
        setProjectTotal(Object.values(projectTotals).reduce((a, b) => a + b, 0));
    }, [projectTotals])

    useEffect(() => {
        //axios get contract
        axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectcontract/', {
            params: { project_uuid: projectUUID },
        })
        .then((response) => {
            console.log(response);

            if(response.data.length && response.data[0].contractor_signed_contract){
                setPublished(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/get-contractor-project/",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                "project_uuid": projectUUID,
            }
        })
        .then(response => {
            console.log(response);
            setProject(response.data);
            setDesc(response.data.desc);
            setExtra(response.data.extra);
            setExtraDesc(response.data.extra_desc);

            if (Object.values(response.data.sections).length){
                let sectionIds = {};
                Object.keys(response.data.sections).map(sID => {
                    sectionIds[String(sID)] = false;
                })
                //show first section default
                // sectionIds[String(Object.values(response.data.sections).sort((a, b) => a.order - b.order)[0].id)] = true;
                setShowSectionDetail(sectionIds);
            }

            setLoading(false);
        })
        .catch(error => {
            console.log(error);
        })
    }, [projectUUID]);

    useEffect(() => {
        axios.get('https://bob-backendapi.herokuapp.com/api/generalcontractor/', {
            params: { user: user.id },
        })
        .then((response) => {
            console.log(response);
            setContractor(response.data[0]);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [user]);

    useEffect(() => {
        setProjectTotals(prevTotals => ({...prevTotals, ['extra']: +extra}));
    }, [extra])

    const handleSaveDesc = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorproject/" + project.id + '/',
            data: {
                generalcontractor: contractor.id,
                address: project.address,
                published: project.published,
                desc: desc,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setDescEdit(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleAddSection = () => {
        setAddingSection(true);

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/",
            data: {
                choice: "Living room",
                group: "Living spaces",
                verb: "Remodel",
                desc: "",
                sectionID: Object.keys(project.sections) ? Object.values(project.sections).filter((section) => section.choice === "Living room").map((section) => section.sectionID).reduce((a, b) => Math.max(a, b), 0) + 1 : 1,
                order: Object.keys(project.sections) ? Object.values(project.sections).map((section) => section.order).reduce((a, b) => Math.max(a, b), 0) + 1 : 0,
                contractorproject: project.id 
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            console.log(response);

            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-section-change/",
                data: {
                    "sectionID": response.data.id,
                    "roomGroup": 'Living spaces',
                    "roomChoice": 'Living room',
                    "roomVerb": "Remodel",
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(updateResponse => {
                console.log(updateResponse);
                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[updateResponse.data.id] = updateResponse.data;
                    return {...temp};
                })
    
                setShowSectionDetail(prev => {
                    let temp = {...prev};
                    temp[updateResponse.data.id] = true;
                    return {...temp};
                })
                setAddingSection(false);
            })
            .catch(error => {
                console.log(error);
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if(notFoundError){
        return (
            <NotFoundPage />
        )
    }

    return (
        <div className="contractorbid-container">
            {/* {project && <SideNav sections={project?.sections}/>} */}
            <ContractorCreatedProjectSideNav projectUUID={projectUUID} current={"Scope of work"}/>
            <div className="contractorbid-header">
                <div>
                    <div className="contractorbid-title">Section Document</div>
                </div>
                <div>
                    <div>
                        <button className="default-button contractorbid-back-button">
                            <a className="contractorbid-back-link" href={"/contractor-createproject/" + projectUUID}>Back</a>
                        </button>
                    </div>
                </div>
            </div>
            <div className="contractorbidtrade-options-container">
                {/* <div className="contractorbidtrade-sow-options-container">
                    <InfoHover3
                        message={"Browse through templates you have made and import them into this project. To make a new templates, click on the gear icon next to the section you want to save as a template."}
                    >
                        <a className="dashboard-project-actionlink" href={'/contractor-createproject-templates/' + projectUUID}>
                            <button className="default-button"><nobr>Templates</nobr></button>
                        </a>
                    </InfoHover3>

                    <ContractorProjectRenderPDF
                        projectIN={project}
                        projectUUID={projectUUID}
                        projectTotal={projectTotal}
                        projectTotals={projectTotals}
                    />

                    <InfoHover3
                        message={"The client is able to view the SOW and make comments on it through this link. Simply give this link's URL to the client."}
                    >
                        <a className="dashboard-project-actionlink" href={"/contractor-createdproject-owner-sow/" + projectUUID} target="_blank">
                            <button className="default-button"><nobr>Owner view</nobr></button>
                        </a>
                    </InfoHover3>
                </div> */}

                {/* <div className="editprojectdetail-collapseall">
                    {showSectionDetail && Object.values(showSectionDetail).includes(false) && 
                        <InfoHover3
                            message={"Expand all sections"}
                        >
                            <div 
                                onClick={() => {
                                    let tempShowDetails = {};
                                    Object.keys(showSectionDetail)?.map(k => {
                                        tempShowDetails[k] = true;
                                    })
                                    setShowSectionDetail({...tempShowDetails});
                                }}
                                className="contractorbid-section-collapseall-expand"
                            >
                                +
                            </div>
                        </InfoHover3>
                    }
                    
                    {showSectionDetail && Object.values(showSectionDetail).includes(true) && 
                        <InfoHover3
                            message={"Collapse all sections"}
                        >
                            <div 
                                onClick={() => {
                                    let tempShowDetails = {};
                                    Object.keys(showSectionDetail)?.map(k => {
                                        tempShowDetails[k] = false;
                                    })
                                    setShowSectionDetail({...tempShowDetails});
                                }}
                                className="contractorbid-section-collapseall-collapse"
                            >
                                -
                            </div>
                        </InfoHover3>
                    }
                </div> */}
            </div>
            {/*navigate away if no sections match with sID*/}
            {(project && !Object.values(project.sections).find(s => s.id === sID)) && <Navigate to={"/contractor-createproject/" + projectUUID} />} 
            <div className="editprojectdetail-sections">
                    {(contractor && project?.sections) && Object.values(project?.sections)?.filter(s => s.id === sID).map(s => 
                        <ProjectSection 
                            key={s.id} 
                            s={s}
                            contractor={contractor}
                            setProjectTotals={setProjectTotals} 
                            published={published}
                            project={project}
                            setProject={setProject}
                            showSectionDetail={showSectionDetail}
                            setShowSectionDetail={setShowSectionDetail}
                        />
                    )}
            </div>
                 
            <br/>
            <br/>

            {/* {addingSection && 
                <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...
                </div>
            }

            {!loading && !published && <div className="contractorsow-addsection">
                <button className="active-button" onClick={() => handleAddSection()}>Add new section</button>
            </div>} */}
            <TextEditor s={sID}/>
            
            {/* {toSow && <Navigate to={"/contractor-createproject/" + projectUUID} />} */}
        </div>
    )
}

const TextEditor = ({s}) => {
    const [editorHtml, setEditorHtml] = useState('');
    const quillRef = useRef(null);
    const [prevSave, setPrevSave] = useState('');

    //document id
    const [id, setId] = useState();
  
    const [comments, setComments] = useState([]);
    const [commentIdList, setCommentIdList] = useState([0]);

    //array of comments that are shown
    const [showComments, setShowComments] = useState([]);

    useEffect(() => {
        const editor = quillRef.current.getEditor();
        let list = editor.root.querySelectorAll('[commentid]');

        for(let i = 0; i < list.length; i++){
            const listener = () => {
                if(comments.filter(comment => comment.commentId === +list[i].getAttribute('commentid'))[0]){
                    setShowComments(prevShowComments => ({...prevShowComments, [list[i].getAttribute('commentid')]: comments.filter(comment => comment.commentId === +list[i].getAttribute('commentid'))[0]}));
                }
                
        }
        list[i].onclick = listener;
        }
    }, [comments, showComments])
            
    //fetch all comments
    useEffect(() => {
        axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcomment/', {
            params: { contractorprojectsectiondocument: id },
        })
        .then((response) => {
            console.log(response);
            setComments(response.data);
            setCommentIdList([0, ...response.data.map(comment => comment.commentId)]);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [id])

    useEffect(() => {
        axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocument/', {
            params: { contractorprojectsection: s},
        })
        .then((response) => {
            if(response.data.length === 0){
                axios({
                    method: "post",
                    url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocument/",
                    data: {
                        "htmlContent": "",
                        "contractorprojectsection": s,
                    },
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then(response => {
                    console.log("created new doc", response);
                    setEditorHtml(response.data.htmlContent);
                    setPrevSave(response.data.htmlContent);
                    setId(response.data.id);

                })
                .catch(error => {
                    console.log(error);
                })
            }
            setId(response.data[0].id);
            const editor = quillRef.current.getEditor();
            editor.clipboard.dangerouslyPasteHTML(response.data[0].htmlContent);
            
            setPrevSave(response.data[0].htmlContent);
            
        })
        .catch((error) => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
          if(prevSave !== editorHtml)
            handleSaveHtml(id, editorHtml);
        }, 5000); // Auto-save every 5 seconds after writing nothing
    
        return () => {
          clearInterval(intervalId);
        };
      }, [id, editorHtml, prevSave]);

      //save when adding a comment
      useEffect(() => {      
        handleSaveHtml(id, editorHtml);
      }, [comments]);

    const handleSaveHtml = (id, editorHtml) => {
        if(id){
            axios({
                method: "put",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocument/" + id + "/",
                data: {
                    "htmlContent": editorHtml,
                    "contractorprojectsection": s,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => {
                console.log(response);
                setPrevSave(editorHtml);
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const Inline = Quill.import('blots/inline');
    class CommentTextBlot extends Inline {
        static blotName = 'comment-blot';
        static tagName = 'span';    
        //classname prevents quill from applying the format to all spans on load in 
        static className = 'comment-blot-text'; 

        static create(value) {
            if (!value) return super.create(false);
            if(!value.uniqueId) return super.create(false);
            const node = super.create(value);
            IdAttr.add(node, value.uniqueId)
            EditAttr.add(node, 'false')
            return node;
        }
        
        formats() {
            let formats = super.formats();
    
            formats['comment-blot'] = CommentTextBlot.formats(this.domNode);

            return formats;
        }
    }
    Quill.register(CommentTextBlot, true);

    // const Tooltip = Quill.import('ui/tooltip');

 
    const insertComment = () => {
        const editor = quillRef.current.getEditor();
        const unpriveligedEditor = quillRef.current.makeUnprivilegedEditor(editor);

        console.log(unpriveligedEditor.getSelection());
        if(unpriveligedEditor.getSelection() && unpriveligedEditor.getSelection().length > 0){
            const uniqueId = Math.max(...commentIdList) + 1;
            console.log(commentIdList)
            console.log(uniqueId);
            editor.formatText(unpriveligedEditor.getSelection(), 'comment-blot', {uniqueId: uniqueId});
            
            setCommentIdList(prevCommentIdList => [...prevCommentIdList, uniqueId]);
            handleAddComment(uniqueId);
        }
    }

    const handleAddComment = (uniqueId) => {
        if(uniqueId){
            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcomment/",
                data: {
                    "commentId": uniqueId,
                    "contractorprojectsectiondocument": id,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => {
                console.log(response);
                //update comments so that it adds the new comment
                setComments(prevComments => [...prevComments, response.data]);
                //update showComments so that it adds the new comment
                setShowComments(prevShowComments => ({...prevShowComments, [response.data.commentId]: response.data}));
            }
            ).catch(error => {
                console.log(error);
            })
        }
    }

    const handleDeleteComment = (cid, uniqueId) => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcomment/" + cid + "/",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(response => {
            console.log(response);
            setComments(prevComments => prevComments.filter(comment => comment.id !== cid));
            setCommentIdList(prevCommentIdList => prevCommentIdList.filter(commentId => commentId !== uniqueId));

            //queryselect the element with the unqiueId and remove all attributes
            const editor = quillRef.current.getEditor();
            const unpriveligedEditor = quillRef.current.makeUnprivilegedEditor(editor);
            let list = editor.root.querySelectorAll('[commentid="' + uniqueId + '"]');
            console.log(list);
            for(let i = 0; i < list.length; i++){
                console.log(list[i]);
                list[i].removeAttribute('commentid');
                list[i].removeAttribute('contenteditable');
                list[i].removeAttribute('class');
            }
            //update showComments so that it removes the old comment
            setShowComments(prevShowComments => {
                let temp = {...prevShowComments};
                delete temp[uniqueId];
                return {...temp};
            }
            )
        }
        ).catch(error => {
            console.log(error);
        })
    }
    //add comments to showComments that are not currently in showComments or remove all comments from showComments
    const toggleAllComments = () => {
        if(Object.keys(showComments).length === 0){
            //add all comments to showComments
            let temp = {};
            comments.map(comment => {
                temp[comment.commentId] = comment;
            })
            setShowComments(temp);
        }else{
            //remove all comments from showComments
            setShowComments({});
        }
    }

    return (
        <div>
            {/* {comments.length > 0 && 
                <div>
                    <h2>Comment list</h2>
                    {comments.map(comment =>
                        <div key={comment.commentId}>
                            <DocumentComments
                            handleDeleteComment={handleDeleteComment}
                            setShowComments={setShowComments}
                            c={comment}
                            />
                        </div>)
                </div>
            } */}
            {prevSave !== editorHtml ? <div className="quill-save-hint"><i class="fa-solid fa-arrows-rotate fa-rotate-90"></i> Saving...</div> : <div className="quill-save-hint"><i class="fa-regular fa-circle-check"></i></div>}

            <div 
                onClick={toggleAllComments}
                className="contractordocument-toggle-comment-button"
            >
                <InfoHover3
                    message={`Toggle on and off all comments`}
                >
                    <i class="fa-regular fa-eye"></i>
                </InfoHover3>
            </div>

            <button 
                onClick={() => insertComment()}
                className="contractordocument-insert-comment-button"
            >
                <InfoHover3
                    message={`Add a comment to the currently selected text`}
                >
                    + <i class="fa-regular fa-comment"></i>
                </InfoHover3>
            </button>
            {/* <button className="contractordocument-insert-comment-button" onClick={() => insertComment()}>Insert Comment</button>  */}
            {Object.keys(showComments)?.length > 0 &&
            <div className="contractordocument-comment-list">
                {Object.values(showComments)?.map(comment =>
                    <div className="contractordocument-comment" key={comment.commentId}>
                        <DocumentComments
                        handleDeleteComment={handleDeleteComment}
                        setShowComments={setShowComments}
                        c={comment}
                        />
                    </div>)}
            </div>}

            <div className="quill-container">
                <ReactQuill
                ref={quillRef}
                value={editorHtml}
                onChange={setEditorHtml}
                onBlur={() => handleSaveHtml(id, editorHtml)}
                placeholder="write something..."
                modules={{
                    toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }], 
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { indent: '-1' },
                    { indent: '+1' }],
                    ['bold', 'italic', 'underline'],
                    ['image'],
                    [{ 'align': [] }],
                    ['clean'],
                    ],
                }}
                />
            </div>

      </div>
    );
};

function DocumentComments({ c, handleDeleteComment, setShowComments }){
    const [changed, setChanged] = useState(false);

    const [newComment, setNewComment] = useState('');

    const [commentsComments, setCommentsComments] = useState([]);

    //fetch all commentscomment from backend
    useEffect(() => {
        if(c){
            axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/', {
                params: { contractorprojectsectiondocumentcomment: c.id },
            })
            .then((response) => {
                console.log(response);
                setCommentsComments(response.data);
            })
            .catch((error) => {
                console.log(error);
            }
            );
        }
    }, [c])

    const handleAddComment = () => {
        axios({
            method: "post",
            url: " https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/",
            data: {
                contractorprojectsectiondocumentcomment: c.id,
                comment: newComment,
                author: "Contractor"
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setCommentsComments(prevComments => [...prevComments, response.data]);

            setNewComment('');
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div 
            className="contractordocument-section-document-comment-container"
        >
            <div className="contractorsow-section-comment-header">
                <b>Text Comments</b> 
                {/*set show comments to remove this comment from the list*/}
                <div className="delete-x"><small>
                    <i 
                        class="fa-solid fa-trash"
                        onClick={() => handleDeleteComment(c.id, c.commentId)}
                    ></i>
                </small></div>
            
                <div className="delete-x" onClick={() => {
                    //remove the key with this commentId from showComments
                    setShowComments(prevShowComments => {
                        let temp = {...prevShowComments};
                        delete temp[c.commentId];
                        return {...temp};
                    }
                    )
                }}>X</div>
            </div>

            {Object.values(commentsComments)?.length === 0 &&
                <div>
                    No comments yet.
                </div>
            }

            <div> 
                {Object.values(commentsComments)?.sort((a, b) => a.id - b.id).map(comment =>
                    <DocumentCommentsComment
                        key={comment.id}
                        comment={comment}
                        setCommentsComments={setCommentsComments}
                    />
                    // <div>
                    // <p key={comment.id}>hello, my id is {comment.id}</p>
                    // {/* <p>{comment.comment}</p>
                    // <span>my c.id is {c.id}</span> */}
                    // </div>
                )}
            </div>

            <div className="contractorbidtrade-trade-comment-add">
                Add a comment
            </div>

            <TextareaAutosize
                minRows={1}
                maxRows={5}
                value={newComment}
                onChange={(e) => {
                    setNewComment(e.target.value);

                    setChanged(true);
                }}
                onBlur={() => {
                    if (changed && newComment) {
                        handleAddComment();
                    }
                }}
                className="contractorbidtrade-trade-comment-input"
                placeholder="Comment for this text..."
            />
        </div>
    )
}

function DocumentCommentsComment({ comment, setCommentsComments }){
    const [editing, setEditing] = useState(false);
    const [changed, setChanged] = useState(false);
    const [newComment, setNewComment] = useState(comment.comment);
    
    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/" + comment.id + "/",
            data: {
                comment: newComment,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setNewComment(response.data.comment);

            setEditing(false);
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/" + comment.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);
            const cid = comment.id;
            setCommentsComments(prevComments => prevComments.filter(comment => comment.id !== cid));
            
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (comment.author === "Contractor"){
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Contractor</b></div>

                    <div className="contractorbidtrade-trade-comment-header">
                        <div><small>{comment?.date_edited?.split('T')[0]}</small></div>

                        &nbsp;

                        {/* <InfoHover3
                            message={"Delete comment"}
                        > */}
                        <div className="delete-x"><small>
                            <i 
                                class="fa-solid fa-trash"
                                onClick={() => handleDelete()}
                            ></i>
                        </small></div>
                        {/* </InfoHover3> */}
                    </div>
                </div>
                
                <div>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={newComment}
                        onChange={(e) => {
                            setNewComment(e.target.value);

                            setChanged(true);
                        }}
                        onBlur={() => {
                            handleSave();
                        }}
                        className="contractorbidtrade-trade-comment-input"
                        placeholder="Leave a comment for this section"
                    />
                </div>
            </div>
        )
    }else {
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Homeowner</b></div>
                    <div><small>{comment?.date_edited?.split('T')[0]}</small></div>
                </div>

                <div>{comment.comment}</div>
            </div>
        )
    }    
}

function ProjectSection({ s, contractor, setProjectTotals, published, project, setProject, showSectionDetail, setShowSectionDetail }){
    const navigate = useNavigate();
    const [sectionTotal, setSectionTotal] = useState(0);
    const [subTotals, setSubTotals] = useState({});
    const [showDetails, setShowDetails] = useState({});

    const [otherChoice, setOtherChoice] = useState(s.choice);
    const [otherChoiceEdit, setOtherChoiceEdit] = useState(false);

    const [sectionDesc, setSectionDesc] = useState(s.desc);
    const [descEdit, setDescEdit] = useState(false);

    const [showUpHint, setShowUpHint] = useState(false);
    const [showDownHint, setShowDownHint] = useState(false);

    const [showComment, setShowComment] = useState(false);

    const [loading, setLoading] = useState(false);

    const [showOptions, setShowOptions] = useState(false);

    // const [toSow, setToSow] = useState(false);

    useEffect(() => {
        if(s?.subsections){
            let tempShowDetails = {};
            Object.values(s.subsections)?.map(sub => {
                tempShowDetails[sub.id] = false;
            })
            setShowDetails(tempShowDetails);
        }
    }, [s])

    useEffect(() => {
        setProjectTotals(prevTotals => ({...prevTotals, [s.id]: sectionTotal}));
    }, [sectionTotal])

    useEffect(() => {
        setSectionTotal(Object.values(subTotals).reduce((a, b) => a + b, 0));
    }, [subTotals])

    const moveUp = () => {
        let sectionsList = Object.values(project.sections).sort((a, b) => a.order - b.order);
        let index = sectionsList.findIndex(i => i.id === s.id);

        let curSectionId = s.id;
        let curOrder = sectionsList[index].order;
        let otherSectionId = sectionsList[index - 1].id;
        let otherOrder = sectionsList[index - 1].order;

        setProject(prev => {
            let temp = {...prev};
            temp.sections[curSectionId].order = otherOrder;
            temp.sections[otherSectionId].order = curOrder;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + curSectionId + "/",
            data: {
                order: otherOrder,
                contractorproject: s.contractorproject,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + otherSectionId + "/",
            data: {
                order: curOrder,
                contractorproject: s.contractorproject,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveDown = () => {
        let sectionsList = Object.values(project.sections).sort((a, b) => a.order - b.order);
        let index = sectionsList.findIndex(i => i.id === s.id);

        let curSectionId = s.id;
        let curOrder = sectionsList[index].order;
        let otherSectionId = sectionsList[index + 1].id;
        let otherOrder = sectionsList[index + 1].order;

        setProject(prev => {
            let temp = {...prev};
            temp.sections[curSectionId].order = otherOrder;
            temp.sections[otherSectionId].order = curOrder;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + curSectionId + "/",
            data: {
                order: otherOrder,
                contractorproject: s.contractorproject,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + otherSectionId + "/",
            data: {
                order: curOrder,
                contractorproject: s.contractorproject,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleChoiceChange = (e) => {
        if(e.group === "Other"){   
            axios({
                method: "put",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + s.id + "/",
                data: {
                    choice: e.value,
                    group: e.group,
                    desc: s.desc,
                    contractorproject: s.contractorproject
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);
                setProject(prev => ({
                    ...prev, 
                    sections: {
                        ...prev.sections,
                        [s.id]: {
                            ...prev.sections[s.id],
                            choice: s.choice,
                            group: e.group,
                        }
                    }
                }));

                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
        }else {
            setLoading(true);
            setShowSectionDetail(prev => ({...prev, [s.id]: true}));

            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-section-change/",
                data: {
                    "sectionID": s.id,
                    "roomGroup": e.group,
                    "roomChoice": e.value,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);
                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[s.id] = response.data;
                    return {...temp};
                })
    
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
        }

        /*if(e.group !== 'Other' && s.subsections){
            Object.values(s.subsections).map((subsection) => {
                axios({
                    method: "delete",
                    url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + subsection.id + "/",
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                })
            })
        }

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + s.id + "/",
            data: {
                choice: e.value,
                group: e.group,
                desc: s.desc,
                contractorproject: s.contractorproject
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id] = response.data;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })*/
    }

    const handleSaveDesc = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + s.id + '/',
            data: {
                choice: s.choice,
                group: s.group,
                order: s.order,
                desc: sectionDesc,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setDescEdit(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const deleteSection = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/" + s.id + '/',
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id];
                return {...temp};
            })
            // navigate("/contractor-createproject/" + project.uuid);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const addSubsection = () => {
        if(s.group === "Other"){
            let subsection_group = Object.keys(SOW["Other"]["Other"])[0];
            let subsection_choice = Object.keys(SOW["Other"]["Other"][subsection_group])[0];
            let subsection_verb = Object.keys(SOW["Other"]["Other"][subsection_group][subsection_choice])[0];

            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-add-subsection/",
                data: {
                    "sectionID": s.id,
                    "roomGroup": "Other",
                    "roomChoice": "Other",
                    "group": subsection_group,
                    "choice": subsection_choice,
                    "verb": subsection_verb,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);

                setProject(prev => {
                    let temp = {...prev};
                    if(!temp.sections[s.id].subsections){
                        temp.sections[s.id].subsections = {};
                    }

                    temp.sections[s.id].subsections[response.data.id] = response.data;
                    return {...temp};
                })
            })
            .catch(error => {
                console.log(error);
            });
            
            /*axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/",
                data: {
                    "group": "Other",
                    "choice": "Other",
                    "verb": "Replace",
                    "order": (s.subsections && Object.keys(s.subsections)?.length) ? Object.values(s.subsections).map((sub) => sub.order).reduce((a, b) => Math.max(a, b), 0) + 1 : 0,
                    "contractorprojectsection": s.id,
                    "allow_generate": false,
                    "total": 0,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);

                setProject(prev => {
                    let temp = {...prev};
                    if(!temp.sections[s.id].subsections){
                        temp.sections[s.id].subsections = {};
                    }

                    temp.sections[s.id].subsections[response.data.id] = response.data;
                    return {...temp};
                })
            })
            .catch(error => {
                console.log(error);
            });*/
        }else {
            let subsection_group = Object.keys(SOW[s.group][s.choice])[0];
            let subsection_choice = Object.keys(SOW[s.group][s.choice][subsection_group])[0];
            let subsection_verb = Object.keys(SOW[s.group][s.choice][subsection_group][subsection_choice])[0];

            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-add-subsection/",
                data: {
                    "sectionID": s.id,
                    "roomGroup": s.group,
                    "roomChoice": s.choice,
                    "group": subsection_group,
                    "choice": subsection_choice,
                    "verb": subsection_verb,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);

                setProject(prev => {
                    let temp = {...prev};
                    if(!temp.sections[s.id].subsections){
                        temp.sections[s.id].subsections = {};
                    }

                    temp.sections[s.id].subsections[response.data.id] = response.data;
                    return {...temp};
                })
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    const handleVerbChange = (e) => {
        setLoading(true);
        setShowSectionDetail(prev => ({...prev, [s.id]: true}));

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractor-project-section-change/",
            data: {
                "sectionID": s.id,
                "roomGroup": s.group,
                "roomChoice": s.choice,
                "roomVerb": e.value,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
            setProject(prev => ({
                ...prev,
                sections: {
                    ...prev.sections,
                    [s.id]: response.data,
                }
            }));

            setLoading(false);
        })
        .catch(error => {
            console.log(error);
        })

    }

    const handleSwapSubsections = (sub1index, sub2index) => {
        let subsections = Object.values(s.subsections).sort((a, b) => a.order - b.order);
        let sub1ID = subsections[sub1index].id;
        let sub2ID = subsections[sub2index].id;

        let sub1Order = s.subsections[sub1ID].order;
        let sub2Order = s.subsections[sub2ID].order;

        setProject(prev => {
            let temp = {...prev};

            //loop through subsections and update order
            Object.values(temp.sections[s.id].subsections).forEach((sub) => {
                if (sub1Order < sub2Order){
                    if (sub.order > sub1Order && sub.order <= sub2Order){
                        sub.order = sub.order - 1;
                    }
                }else if (sub1Order > sub2Order){
                    if (sub.order < sub1Order && sub.order >= sub2Order){
                        sub.order = sub.order + 1;
                    }
                }
            })

            temp.sections[s.id].subsections[sub1ID].order = sub2Order;
            
            return {...temp};
        })

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/drag-contractor-project-subsection/",
            data: {
                sectionID: s.id,
                sourceID: sub1ID,
                destinationID: sub2ID,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);                
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="editprojectdetail-section">
             {/* {toSow && <Navigate to={"/contractor-createproject/" + project.uuid} />} */}
            <div 
                className="editprojectdetail-section-choice-container contractorprojectsow-section-header"
                id={'nav-section-' + s.id}
                onClick={() => {
                    if(showSectionDetail){
                        setShowComment(false);
                    }
                    setShowSectionDetail(prev => ({...prev, [s.id]: !prev[s.id]}))
                }}
            >
                {/* <div 
                className="contractorsow-section-redirect-container"
                onClick={() => {
                    navigate("/contractor-createproject/" + project.uuid)
                    // setToSow(true);
                }}>
                    <InfoHover3 className='contractorsow-section-redirect-info'
                        message={`Return to SOW page`}
                    >  
                        <div className="contractorsow-section-redirect-icon">
                            <i class="fa-solid fa-arrow-turn-up"></i>
                        </div>
                    </InfoHover3>    
                </div> */}
               
                <div className="contractorsow-subsection-move-container">
                    <div>
                        {showSectionDetail[s.id] 
                        ?   <i class="fa-solid fa-caret-down"></i>
                        :   <i class="fa-solid fa-caret-right"></i>
                        }
                    </div>

                    {/* {!published && (s.order !== Object.values(project.sections).map((section) => section.order).reduce((a, b) => Math.min(a, b), 999) || s.order !== Object.values(project.sections).map((section) => section.order).reduce((a, b) => Math.max(a, b), -1)) &&
                        <div className="contractorsow-updown-container">
                            {s.order !== Object.values(project.sections).map((section) => section.order).reduce((a, b) => Math.min(a, b), 999) &&
                                <div className="editprojectdetail-sectionup-container">
                                    {showUpHint &&
                                        <div className="editprojectdetail-updown-hint">
                                            Move up
                                        </div>
                                    }
                                    <button 
                                        className="editprojectdetail-sectionup-button" 
                                        onClick={(e) => {moveUp(); e.stopPropagation();}}
                                        onMouseOver={() => setShowUpHint(true)}
                                        onMouseOut={() => setShowUpHint(false)}
                                    >
                                        &and;
                                    </button>
                                </div>
                            }

                            {s.order !== Object.values(project.sections).map((section) => section.order).reduce((a, b) => Math.max(a, b), -1) &&
                                <div className="editprojectdetail-sectionup-container">
                                    {showDownHint &&
                                        <div className="editprojectdetail-updown-hint">
                                            Move down
                                        </div>
                                    }
                                    <button 
                                        className="editprojectdetail-sectionup-button" 
                                        onClick={(e) => {moveDown(); e.stopPropagation();}}
                                        onMouseOver={() => setShowDownHint(true)}
                                        onMouseOut={() => setShowDownHint(false)}
                                    >
                                        &or;
                                    </button>
                                </div>
                            }
                        </div>
                    } */}
                </div>

                {/*s?.group !== "Other" && s?.sectionID &&
                    <div>
                        ID: {s.sectionID}
                    </div>
                */}

                <SectionComments
                    s={s}
                    setProject={setProject}
                    showSectionDetail={showSectionDetail}
                    setShowSectionDetail={setShowSectionDetail}
                    showComment={showComment}
                    setShowComment={setShowComment}
                />

                <DropDownMenu
                    value={{label: s.group === 'Other' ? 'Other' : s.choice + ' (' + s.sectionID + ')', value: s.choice, group: s.group}}
                    options={[
                        ...Object.keys(SOW).filter(g => g !== 'Other').map(g => ( 
                            {
                                label: g,
                                options: [...Object.keys(SOW[g]).map(i => ({label: i, value: i, group: g}))],
                            }
                        ))
                    ]}
                    onChange={handleChoiceChange} 
                    published={published}
                />

                {s?.group !== "Other" && s?.choice !== "General Conditions" && s?.choice !== "Basic construction" &&
                    <DropDownMenu
                        value={{
                            label: s?.verb,
                            value: s?.verb,
                            group: s?.group,
                        }}
                        options={[
                            {   
                                label: "Actions",
                                options: [{label: "Remodel", value: "Remodel"}, {label: "Add", value: "Add"}]
                            }
                        ]}
                        onChange={handleVerbChange} 
                        published={published}
                        type='large-verb'
                    />
                }

                {s.group === "Other" &&
                    <>
                        <div>
                            <input
                                type="text"
                                value={otherChoice}
                                onChange={e => {setOtherChoice(e.target.value); setOtherChoiceEdit(true);}}
                                onBlur={() => {
                                    if(otherChoiceEdit){
                                        handleChoiceChange({value: otherChoice, group: "Other"});
                                        setOtherChoiceEdit(false);
                                    }
                                }}
                                className="editprojectdetail-section-other"
                                placeholder="Please specify here..."
                                onClick={(e) => e.stopPropagation()}
                                {...(published && {disabled:true})}
                            />
                        </div>
                        {/*otherChoiceEdit && 
                            <div>
                                <button 
                                    className="active-button"
                                    onClick={() => {handleChoiceChange({value: otherChoice, group: "Other"}); setOtherChoiceEdit(false);}}
                                >
                                    Save
                                </button>
                            </div>
                        */}
                    </>
                }

                {/*<button 
                    className="editprojectdetail-deletesection-button delete-button"
                    onClick={(e) => {deleteSection(); e.stopPropagation();}}
                >
                    <i class="fa-solid fa-trash"></i>
                </button>*/}
                
                <SectionOptionModal
                    s={s}
                    setShowOptions={setShowOptions}
                    showOptions={showOptions}
                    setProject={setProject}
                    project={project}
                    deleteSection={deleteSection}
                />
                
                <button
                    className="contractorprojectsow-section-detail-button"
                    onClick={(e) => {setShowOptions(true); e.stopPropagation();}}
                >
                    <i class="fa-solid fa-gear"></i>
                </button>

                {/*(showSectionDetail[s?.id] && !published)
                ?   <button 
                        className="editprojectdetail-deletesection-button delete-button"
                        onClick={deleteSection}
                    >
                        <i class="fa-solid fa-trash"></i>
                    </button>
                :   <div className="editprojectdetail-deletesection-button contractorsow-section-total">
                        Section total: ${sectionTotal}
                    </div>
                */}
            </div>
            
            {showSectionDetail[s?.id] && 
            <div className="editprojectdetail-section-body-container">
                <div>
                    <div>
                        <div><b>Description</b></div>
                        {/*descEdit && <button onClick={() => handleSaveDesc()} className="active-button-small">Save</button>*/}
                    </div>
                    <TextareaAutosize
                        maxRows={3}
                        value={sectionDesc} 
                        onChange={e => {setSectionDesc(e.target.value); setDescEdit(true);}}
                        onBlur={() => {
                            if(descEdit){
                                handleSaveDesc();
                            }
                        }}
                        className="editprojectdetail-section-desc"
                        placeholder={"Example: kitchen for main unit, needs an eletrical rework and a new island."}
                        {...(published && {disabled:true})}
                    />
                </div>

                <div className="contractorsow-subsections-header">
                    <div className="editprojectdetail-subsections-title" id={`nav-sow-${s.id}`}>
                    </div>
                    <div className="contractorbid-section-collapseall">
                        {Object.values(showDetails).includes(false) && 
                            <InfoHover3
                                message={"Expand all subsections"}
                            >
                                <div 
                                    onClick={() => {
                                        let tempShowDetails = {};
                                        Object.values(s?.subsections)?.map(sub => {
                                            tempShowDetails[sub.id] = true;
                                        })
                                        setShowDetails(tempShowDetails);
                                    }}
                                    className="contractorbid-section-collapseall-expand"
                                >
                                    +
                                </div>
                            </InfoHover3>
                        }
                        
                        {Object.values(showDetails).includes(true) && 
                            <InfoHover3
                                message={"Collapse all subsections"}
                            >
                                <div 
                                    onClick={() => {
                                        let tempShowDetails = {};
                                        Object.values(s?.subsections)?.map(sub => {
                                            tempShowDetails[sub.id] = false;
                                        })
                                        setShowDetails(tempShowDetails);
                                    }}
                                    className="contractorbid-section-collapseall-collapse"
                                >
                                    -
                                </div>
                            </InfoHover3>
                        }
                    </div>
                </div>

                {loading
                ?   <div className="editproject-sowgeneration-spinner-container">
                        <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                        Loading...
                    </div>
                :   (s?.subsections && Object.values(s?.subsections).length > 0) 
                    ?   <div className="contractorsow-subsections">
                            <DragDropContext
                                onDragEnd={(...props) => {
                                    console.log(props);
                                    handleSwapSubsections(props[0].source.index, props[0].destination.index);
                                }}
                            >
                                <Droppable
                                    droppableId="contractorsow-subsections-drop"
                                    type="subsections"
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {Object.values(s?.subsections)?.sort((a, b) => a.order - b.order).map(sub => 
                                                <Subsection 
                                                    index={Object.values(s?.subsections)?.sort((a, b) => a.order - b.order).findIndex(i => i.id === sub.id)}
                                                    key={sub.id} 
                                                    sub={sub} 
                                                    s={s}
                                                    published={published}
                                                    contractor={contractor} 
                                                    setSubTotals={setSubTotals}
                                                    showDetails={showDetails}
                                                    setShowDetails={setShowDetails}
                                                    project={project}
                                                    setProject={setProject}
                                                />
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    :   <div style={{ color: "gray"}}>No subsections added, click on the button below to add them!</div>
                }

                <div className="contractorsow-subsection-footer">
                    {published
                    ?   <div></div>
                    :   <button onClick={addSubsection} className="active-button">
                            Add subsection
                        </button>
                    }

                    {/*<div className="contractorsow-section-total">
                        Section total: ${sectionTotal}
                    </div>*/}
                </div>
            </div>}
        </div>
    );
}

function SectionComments({ s, setProject, showSectionDetail, setShowSectionDetail, showComment, setShowComment }){
    const [changed, setChanged] = useState(false);

    const [newComment, setNewComment] = useState('');

    const handleAddComment = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectioncomment/",
            data: {
                contractorprojectsection: s.id,
                comment: newComment,
                author: "Contractor"
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].comments[response.data.id] = response.data;
                return temp;
            })

            setNewComment('');
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (!showComment) {
        return (
            <div 
                className="contractorsow-section-comment-icon"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowSectionDetail(prev => ({...prev, [s.id]: true}));
                    setShowComment(true);
                }}
            >   
                {s?.comments && Object.values(s?.comments)?.length > 0 
                ?   <>{Object.values(s?.comments)?.length} <i class="fa-solid fa-comment"></i></>
                :   <>
                        <InfoHover3
                            message={`Add comment for ${s.choice} (${s.sectionID})`}
                        >
                            + <i class="fa-regular fa-comment"></i>
                        </InfoHover3>
                    </>
                }
            </div>
      
        )
    }

    return (
        <div 
            className="contractorbidtrade-section-comment-container"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="contractorsow-section-comment-header">
                <b>Section comments</b> <div className="delete-x" onClick={() => setShowComment(false)}>X</div>
            </div>
            <div>
                {s.choice} ({s.sectionID})
            </div>

            {Object.values(s?.comments)?.length === 0 &&
                <div>
                    No comments yet.
                </div>
            }

            <div>
                {Object.values(s?.comments)?.sort((a, b) => a.id - b.id).map(comment =>
                    <SectionComment
                        key={comment.id}
                        comment={comment}
                        s={s}
                        setProject={setProject}
                    />
                )}
            </div>

            <div className="contractorbidtrade-trade-comment-add">
                Add a comment
            </div>

            <TextareaAutosize
                minRows={1}
                maxRows={5}
                value={newComment}
                onChange={(e) => {
                    setNewComment(e.target.value);

                    setChanged(true);
                }}
                onBlur={() => {
                    if (changed && newComment) {
                        handleAddComment();
                    }
                }}
                className="contractorbidtrade-trade-comment-input"
                placeholder="Comment for this section..."
            />
        </div>
    )
}

function SectionComment({ comment, s, setProject }){
    const [editing, setEditing] = useState(false);
    const [changed, setChanged] = useState(false);
    const [newComment, setNewComment] = useState(comment.comment);
    
    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectioncomment/" + comment.id + "/",
            data: {
                comment: newComment,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].comments[comment.id] = response.data;
                return temp;
            })

            setEditing(false);
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectioncomment/" + comment.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id].comments[comment.id];
                return temp;
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (comment.author === "Contractor"){
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Contractor</b></div>

                    <div className="contractorbidtrade-trade-comment-header">
                        <div><small>{comment?.date_edited?.split('T')[0]}</small></div>

                        &nbsp;

                        <InfoHover3
                            message={"Delete comment"}
                        >
                            <div className="delete-x"><small>
                                <i 
                                    class="fa-solid fa-trash"
                                    onClick={() => handleDelete()}
                                ></i>
                            </small></div>
                        </InfoHover3>
                    </div>
                </div>
                
                <div>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={newComment}
                        onChange={(e) => {
                            setNewComment(e.target.value);

                            setChanged(true);
                        }}
                        onBlur={() => {
                            handleSave();
                        }}
                        className="contractorbidtrade-trade-comment-input"
                        placeholder="Leave a comment for this section"
                    />
                </div>
            </div>
        )
    }else {
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Homeowner</b></div>
                    <div><small>{comment?.date_edited?.split('T')[0]}</small></div>
                </div>

                <div>{comment.comment}</div>
            </div>
        )
    }    
}

function SectionOptionModal({ s, setShowOptions, showOptions, setProject, project, deleteSection }){
    const [showDelete, setShowDelete] = useState(false);

    const [templateName, setTemplateName] = useState('');

    const [toProject, setToProject] = useState(false);

    const handleCreateTemplate = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/create-contractor-sow-template/",
            data: {
                "sectionID": s.id,
                "name": templateName,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
            setShowOptions(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <Modal
            show={showOptions}
            onHide={() => setShowOptions(false)}
            className='contractorbid-section-option-modal'
            onClick={(e) => e.stopPropagation()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Section options</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='contractortakeoff-groupdetails-modal-section'>
                    <div className='contractortakeoff-groupdetails-modal-section-header'>Save section as template</div>
                    <div className='contractortakeoff-groupdetails-modal-section-delete-warning'>
                        Save this section as a template to use in other projects.
                    </div>

                    <div>
                        <input
                            type='text'
                            value={templateName}
                            onChange={e => setTemplateName(e.target.value)}
                            placeholder='Template name'
                            className='contractortakeoff-groupdetails-modal-section-template-name'
                        />
                    </div>

                    {templateName !== '' &&
                        <button 
                            className='active-button'
                            onClick={() => handleCreateTemplate()}
                        >
                            Save template
                        </button>
                    }
                </div>

                <br></br>

                <div className='contractortakeoff-groupdetails-modal-section'>
                    <div className='contractortakeoff-groupdetails-modal-section-header'>Delete section</div>
                    <div className='contractortakeoff-groupdetails-modal-section-delete-warning'>
                        Are you sure you want to delete this section? This action cannot be undone.
                    </div>

                    {showDelete
                    ?   <div className='contractortakeoff-groupdetails-modal-section-delete'>
                            <button className='default-button' 
                            onClick={() => {
                                deleteSection()
                                // setToProject(true)
                            }}
                            >Delete</button>
                            <button className='active-button' onClick={() => setShowDelete(false)}>Cancel</button>
                        </div>
                    :   <button className='delete-button' onClick={() => setShowDelete(true)}>Delete section</button>
                    }
                </div>

                {toProject && <Navigate to={'/contractor-createproject/' + project.uuid}/>}
            </Modal.Body>

            <Modal.Footer>
                <button className='default-button' onClick={() => setShowOptions(false)}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

function Subsection({ sub, s, contractor, setSubTotals, published, setShowDetails, showDetails, setProject, project, index }){
    const [subsection, setSubsection] = useState(sub);
    const [bid, setBid] = useState(sub);
    const [edit, setEdit] = useState(false);
    const [otherChoice, setOtherChoice] = useState(sub.choice);
    const [otherChoiceEdit, setOtherChoiceEdit] = useState(false);
    const [generatingSOW, setGeneratingSOW] = useState(false);

    const [showComment, setShowComment] = useState(false);

    const [showUpHint, setShowUpHint] = useState(false);
    const [showDownHint, setShowDownHint] = useState(false);

    useEffect(() => {
        setSubTotals(prev => ({...prev, [sub.id]:(+bid.materials + +bid.installation_materials + +bid.installation_price)}));
    }, [bid])

    const handleSave = () => {
        if(bid.materials === ''){
            bid.materials = 0;
        }

        if(bid.installation_materials === ''){
            bid.installation_materials = 0;
        }

        if(bid.installation_price === ''){
            bid.installation_price = 0;
        }

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + bid.id + '/',
            data: {
                materials: bid.materials,
                installation_materials: bid.installation_materials,
                installation_price: bid.installation_price,
                extra: bid.extra,
                extra_desc: bid.extra_desc,
                quantity: bid.quantity,
                total: +bid.materials + +bid.installation_materials + +bid.installation_price,
                contractorprojectsection: bid.contractorprojectsection,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setEdit(false);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const moveUp = () => {
        let subsectionsList = Object.values(s.subsections).sort((a, b) => a.order - b.order);
        let index = subsectionsList.findIndex(i => i.id === sub.id);

        let curSubsectionId = sub.id;
        let curOrder = subsectionsList[index].order;
        let otherSubsectionId = subsectionsList[index - 1].id;
        let otherOrder = subsectionsList[index - 1].order;

        setProject(prev => {
            let temp = {...prev};
            temp.sections[s.id].subsections[curSubsectionId].order = otherOrder;
            temp.sections[s.id].subsections[otherSubsectionId].order = curOrder;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + curSubsectionId + "/",
            data: {
                order: otherOrder,
                contractorprojectsection: sub.contractorprojectsection,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + otherSubsectionId + "/",
            data: {
                order: curOrder,
                contractorprojectsection: sub.contractorprojectsection,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveDown = () => {
        let subsectionsList = Object.values(s.subsections).sort((a, b) => a.order - b.order);
        let index = subsectionsList.findIndex(i => i.id === sub.id);

        let curSubsectionId = sub.id;
        let curOrder = subsectionsList[index].order;
        let otherSubsectionId = subsectionsList[index + 1].id;
        let otherOrder = subsectionsList[index + 1].order;

        setProject(prev => {
            let temp = {...prev};
            temp.sections[s.id].subsections[curSubsectionId].order = otherOrder;
            temp.sections[s.id].subsections[otherSubsectionId].order = curOrder;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + curSubsectionId + "/",
            data: {
                order: otherOrder,
                contractorprojectsection: sub.contractorprojectsection,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + otherSubsectionId + "/",
            data: {
                order: curOrder,
                contractorprojectsection: sub.contractorprojectsection,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleChoiceChange = (e) => {
        if(e.group === "Other"){
            axios({
                method: "put",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + sub.id + "/",
                data: {
                    "choice": e.value,
                    "group": e.group,
                    "contractorprojectsection": s.id,
                    "allow_generate": project.sections[s.id].subsections[sub.id].group === "Other" ? true : false,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);

                setSubsection(response.data);

                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[s.id].subsections[sub.id].allow_generate = project.sections[s.id].subsections[sub.id].group === "Other" ? true : false;
                    temp.sections[s.id].subsections[sub.id].choice = e.value;
                    temp.sections[s.id].subsections[sub.id].group = e.group;
                    return {...temp};
                });

                setOtherChoice(e.value);
            })
            .catch(error => {
                console.log(error);
            })
        }else {
            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-change-subsection/",
                data: {
                    "subsectionID": sub.id,
                    "roomGroup": s.group,
                    "roomChoice": s.choice,
                    "group": e.group,
                    "choice": e.value,
                    "verb": Object.keys(SOW[s.group][s.group === "Other" ? "Other" : s.choice][e.group][e.value])[0]
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);
    
                setSubsection({
                    ...response.data,
                    shapes: sub.shapes,
                });

                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[s.id].subsections[sub.id] = {
                        ...response.data,
                        shapes: sub.shapes,
                    }
                    return {...temp};
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    const handleVerbChange = (e) => {
        if(sub.group === "Other"){
            axios({
                method: "put",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + sub.id + "/",
                data: {
                    "verb": e.value,
                    "allow_generate": true,
                    "contractorprojectsection": s.id,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);

                setSubsection({
                    ...response.data,
                    shapes: sub.shapes,
                });

                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[s.id]['subsections'][sub.id].verb = e.value;
                    temp.sections[s.id]['subsections'][sub.id].allow_generate = true;
                    return {...temp};
                });
            })
            .catch(error => {
                console.log(error);
            })
        }else{
            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-change-subsection/",
                data: {
                    "subsectionID": sub.id,
                    "roomGroup": s.group,
                    "roomChoice": s.choice,
                    "group": sub.group,
                    "choice": sub.choice,
                    "verb": e.value
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);
    
                setSubsection({
                    ...response.data,
                    shapes: sub.shapes,
                });

                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[s.id].subsections[sub.id] = {
                        ...response.data,
                        shapes: sub.shapes,
                    }
                    return {...temp};
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    const handleGenerate = () => {
        setShowDetails(prev => ({...prev, [sub.id]: true}));

        console.log(s.choice);
        console.log(sub.choice);
        console.log(sub.verb);

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractor-project-generate-sow/",
            data: {
                "subsectionID": sub.id,
                "roomChoice": s.choice,
                "choice": sub.choice,
                "verb": sub.verb,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setSubsection(prev => {
                let temp = {...prev};
                temp.allow_generate = false;
                temp.items = response.data.items;
                temp.owner_materials = response.data.owner_materials;
                temp.contractor_materials = response.data.contractor_materials;
                return {...temp};
            })

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].subsections[sub.id].allow_generate = false;
                temp.sections[s.id].subsections[sub.id].items = response.data.items;
                temp.sections[s.id].subsections[sub.id].owner_materials = response.data.owner_materials;
                temp.sections[s.id].subsections[sub.id].contractor_materials = response.data.contractor_materials;
                return {...temp};
            })

            setGeneratingSOW(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const deleteSubsection = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsection/" + sub.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id].subsections[sub.id];
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const addItem = () => {
        if(!sub.items){
            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/",
                data: {
                    desc: "",
                    order: 0,
                    contractorprojectsubsection: sub.id
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(itemResponse => {
                console.log(itemResponse);

                setProject(prev => {
                    prev.sections[s.id]['subsections'][sub.id]['items'] = {};
                    prev.sections[s.id]['subsections'][sub.id]['items'][itemResponse.data.id] = itemResponse.data;
                    return {...prev};
                });
            })
            .catch(error => {
                console.log(error);
            })
        }else{
            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/",
                data: {
                    desc: "",
                    order: Object.keys(sub['items']) ? Object.values(sub['items']).map((item) => item.order).reduce((a, b) => Math.max(a, b), 0) + 1 : 0,
                    contractorprojectsubsection: sub.id
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(itemResponse => {
                console.log(itemResponse);

                setProject(prev => {
                    prev.sections[s.id]['subsections'][sub.id]['items'][itemResponse.data.id] = itemResponse.data;
                    return {...prev};
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    const addOwner = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectownermaterial/",
            data: {
                desc: "",
                contractorprojectsubsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                prev.sections[s.id]['subsections'][sub.id]['owner_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const addContractor = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontractormaterial/",
            data: {
                desc: "",
                contractorprojectsubsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                prev.sections[s.id]['subsections'][sub.id]['contractor_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <Draggable
            key={sub.id.toString()}
            draggableId={sub.id.toString()}
            index={index}
        >
            {(provided, snapshot) => (
                <div className="contractorbid-subsection-container"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >            
                    <div 
                        className="contractorsow-subsection-header" 
                        onClick={() => {
                            if (showComment) {
                                setShowComment(false);
                            }
                            setShowDetails(prev => ({...prev, [sub.id]:!showDetails[sub.id]}))
                        }} 
                        id={`nav-subsection-${sub.id}`}
                    >
                        <SubComments
                            sub={sub}
                            s={s}
                            setProject={setProject}
                            showComment={showComment}
                            setShowComment={setShowComment}
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                        />

                        <div className="contractorsow-subsection-header-left">
                            <div className="contractorsow-subsection-move-container">
                                <div>
                                    {showDetails[sub.id] 
                                    ?   <i class="fa-solid fa-caret-down"></i>
                                    :   <i class="fa-solid fa-caret-right"></i>
                                    }
                                </div>

                                <div
                                    className="contractorsow-subsection-move"
                                    {...provided.dragHandleProps}
                                >
                                    <InfoHover3
                                        message={`Drag and drop to move ${sub.choice} (${sub.verb})`}
                                    >
                                        <i class="fa-solid fa-bars"></i>
                                    </InfoHover3>
                                </div>

                                {/*!published && (sub.order !== Object.values(s.subsections).map((sub) => sub.order).reduce((a, b) => Math.min(a, b), 999) || sub.order !== Object.values(s.subsections).map((sub) => sub.order).reduce((a, b) => Math.max(a, b), -1)) && 
                                    <div className="contractorsow-subsection-updown-container">
                                        {sub.order !== Object.values(s.subsections).map((sub) => sub.order).reduce((a, b) => Math.min(a, b), 999) &&
                                            <div className="editprojectdetail-sectionup-container">
                                                {showUpHint &&
                                                    <div className="editprojectdetail-updown-hint">
                                                        Move up
                                                    </div>
                                                }
                                                <button 
                                                    className="editprojectdetail-sectionup-button" 
                                                    onClick={(e) => {moveUp(); e.stopPropagation()}}
                                                    onMouseEnter={() => setShowUpHint(true)}
                                                    onMouseLeave={() => setShowUpHint(false)}
                                                >
                                                    &and;
                                                </button>
                                            </div>
                                        }
                                        {sub.order !== Object.values(s.subsections).map((sub) => sub.order).reduce((a, b) => Math.max(a, b), -1) &&
                                            <div className="editprojectdetail-sectionup-container">
                                                {showDownHint &&
                                                    <div className="editprojectdetail-updown-hint">
                                                        Move down
                                                    </div>
                                                }
                                                <button 
                                                    className="editprojectdetail-sectionup-button" 
                                                    onClick={(e) => {moveDown(); e.stopPropagation()}}
                                                    onMouseEnter={() => setShowDownHint(true)}
                                                    onMouseLeave={() => setShowDownHint(false)}
                                                >
                                                    &or;
                                                </button>
                                            </div>
                                        }
                                    </div>
                                */}
                            </div>
                        
                            <DropDownMenu
                                value={{
                                    label: sub.group === "Other" ? "Other" : sub.group + ' - ' + sub.choice,
                                    value: sub.choice,
                                    group: sub.group
                                }}
                                options={[
                                    ...Object.keys(SOW[s.group][s.group === 'Other' ? 'Other' : s.choice]).map(g => ( 
                                        {
                                            label: g,
                                            options: [...Object.keys(SOW[s.group][s.group === 'Other' ? 'Other' : s.choice][g]).map(i => ({label: i, value: i, group: g}))],
                                        }
                                    ))
                                ]}
                                onChange={handleChoiceChange}
                                published={published}
                                type="small"
                            />

                            {(sub.group !== "Other" && SOW?.[s?.group][s.group === 'Other' ? 'Other' : s.choice][sub.group][sub.choice]) && 
                                <DropDownMenu
                                    value={{
                                        label: sub?.verb,
                                        value: sub?.verb,
                                        group: sub?.group,
                                    }}
                                    options={[
                                        {   
                                            label: "Actions",
                                            options: [...Object.keys(SOW?.[s?.group][s.group === 'Other' ? 'Other' : s.choice][sub.group][sub.choice]).map(i => ({label: i, value: i}))]
                                        }
                                    ]}
                                    onChange={handleVerbChange} 
                                    published={published}
                                    type="verb"
                                />
                            }

                            {sub.group === "Other" && 
                                <>
                                    <div>
                                        <input
                                            onClick={(e) => {e.stopPropagation();}}
                                            type="text"
                                            className="editprojectdetail-subsection-other"
                                            value={otherChoice}
                                            onChange={e => {setOtherChoice(e.target.value); setOtherChoiceEdit(true);}}
                                            onBlur={() => {
                                                if(otherChoiceEdit){
                                                    handleChoiceChange({value: otherChoice, group: "Other"});
                                                    setOtherChoiceEdit(false);
                                                }
                                            }}
                                            placeholder="Please specify here..."
                                            {...(published && {disabled:true})}
                                        />
                                    </div>
                                    {/*otherChoiceEdit &&
                                        <div>
                                            <button 
                                                className="active-button-mid"
                                                onClick={(e) => {handleChoiceChange({value: otherChoice, group: "Other"}); setOtherChoiceEdit(false); e.stopPropagation();}}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    */}
                                    <div>
                                        <DropDownMenu
                                            value={{
                                                label: sub?.verb,
                                                value: sub?.verb,
                                                group: "Actions",
                                            }}
                                            options={[
                                                {   
                                                    label: "Actions",
                                                    options: [{label: "Replace", value: "Replace"}, {label: "Add", value: "Add"}, {label: "Remove", value: "Remove"}]
                                                }
                                            ]}
                                            onChange={handleVerbChange}
                                            published={published}
                                            type="verb"
                                        />
                                    </div>
                                    {(!published && sub.allow_generate) &&
                                        <div>
                                            <button 
                                                className="active-button-mid"
                                                onClick={(e) => {handleGenerate(); setGeneratingSOW(true); e.stopPropagation();}}    
                                            >
                                                Generate
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                        </div>

                        
                        <div className="contractorsow-subsection-buttons-container">
                            <InfoHover3
                                message={'Delete this subsection'}
                            >
                                <button 
                                    className="delete-button contractorsow-subsection-delete"
                                    onClick={(e) => {
                                        deleteSubsection();
                                        e.stopPropagation();
                                    }}
                                >
                                    <i class="fa-solid fa-trash"></i>
                                </button>
                            </InfoHover3>
                        </div>
                        
                        {/*!showDetails[sub.id] && 
                            <div className="contractorsow-subsection-buttons-container">
                                <div>Total:  ${+bid?.materials + +bid?.installation_materials + +bid?.installation_price}</div>
                            </div>
                        }
                        
                        {(showDetails[sub.id] && !published) &&
                            <div className="contractorsow-subsection-buttons-container">
                                <button 
                                    className="delete-button projectsubsection-delete-button"
                                    onClick={(e) => {
                                        deleteSubsection();
                                        e.stopPropagation();
                                    }}
                                >
                                    <i class="fa-solid fa-trash"></i>
                                </button>
                            </div>
                        */}
                    </div>

                    {showDetails[sub.id] && 
                        <div className="contractorsow-subsection-body-container">
                            {+project?.items + +project?.owner_mats + +project?.contractor_mats === 0 &&
                                <div>
                                    <div>Description</div>
                                    <TextareaAutosize
                                        minRows={2}
                                        maxRows={3}
                                        className="contractorsow-subsection-desc"
                                        placeholder="Ex: 20 sqft, yellow paint, etc..."
                                        value={bid?.quantity}
                                        onChange={e => (setBid(prev => ({...prev, quantity:e.target.value})), setEdit(true))}
                                        onBlur={() => {
                                            if(edit){
                                                handleSave();
                                            }
                                        }}
                                        {...(published && {disabled:true})}
                                    />
                                </div>
                            }

                            {+project?.items + +project?.owner_mats + +project?.contractor_mats === 1 && 
                                <div className="contractorbid-subsection-top-container">
                                    <div>
                                        <div>Description</div>
                                        <TextareaAutosize
                                            minRows={2}
                                            maxRows={3}
                                            className="contractorsow-subsection-desc"
                                            placeholder="Ex: 20 sqft, yellow paint, etc..."
                                            value={bid?.quantity}
                                            onChange={e => (setBid(prev => ({...prev, quantity:e.target.value})), setEdit(true))}
                                            onBlur={() => {
                                                if(edit){
                                                    handleSave();
                                                }
                                            }}
                                            {...(published && {disabled:true})}
                                        />
                                    </div>
                                    
                                    {generatingSOW 
                                    ?   <div className="editproject-sowgeneration-spinner-container">
                                            <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                            Loading...
                                        </div>
                                    :   <>
                                            {project?.items && 
                                                <div>
                                                    <div>Details</div>
                                                    <div className="projectsubsection-items-conatiner">
                                                        {sub?.items
                                                            && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                                <SOWItem
                                                                    key={i.id}
                                                                    item={i}
                                                                    sub={sub}
                                                                    s={s}
                                                                    published={published}
                                                                    setProject={setProject}
                                                                />
                                                            )
                                                        }
                                                    </div>   
                                                    {!published && <div className="projectsubsection-additem">
                                                        <button
                                                            className="default-button-small"
                                                            onClick={addItem}
                                                        >
                                                            <b>+</b> Add item
                                                        </button>
                                                    </div>}
                                                </div>
                                            }

                                            {project?.owner_mats &&
                                                <div>
                                                    Owner materials  (exclusions)
                                                    
                                                    <div className="projectsubsection-items-conatiner">
                                                        {sub?.owner_materials && Object.values(sub?.owner_materials).length
                                                        ?   Object.values(sub?.owner_materials)?.map(m =>
                                                                <OnwerMaterial
                                                                    key={m.id}
                                                                    material={m}
                                                                    published={published}
                                                                    sectionId={s.id}
                                                                    subId={sub.id}
                                                                    setSubsection={setSubsection}
                                                                    setProject={setProject}
                                                                    showSwitch={project?.contractor_mats}
                                                                />
                                                            )
                                                        :   <div>No materials</div>}
                                                    </div>
                                    
                                                    {!published && 
                                                        <div className="projectsubsection-additem">
                                                            <button
                                                                className="default-button-small"
                                                                onClick={addOwner}
                                                            >
                                                                <b>+</b> Add material
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {project?.contractor_mats &&
                                                <div>
                                                    Contractor materials
                                    
                                                    <div className="projectsubsection-items-conatiner">
                                                        {sub?.contractor_materials && Object.values(sub?.contractor_materials).length 
                                                        ?   Object.values(sub?.contractor_materials)?.map(m =>
                                                                <ContractorMaterial
                                                                    key={m.id}
                                                                    material={m}
                                                                    published={published}
                                                                    subsection={sub}
                                                                    setSubsection={setSubsection}
                                                                    sectionId={s.id}
                                                                    subId={sub.id}
                                                                    setProject={setProject}
                                                                    showSwitch={project?.owner_mats}
                                                                />
                                                            )
                                                        :   <div>No materials</div>
                                                        }
                                                    </div>
                                    
                                                    {!published && 
                                                        <div className="projectsubsection-additem">
                                                            <button
                                                                className="default-button-small"
                                                                onClick={addContractor}
                                                            >
                                                                <b>+</b> Add material
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </>                                
                                    }
                                </div>
                            }

                            {+project?.items + +project?.owner_mats + +project?.contractor_mats === 2 &&
                                <>
                                    <div>
                                        <div>Description</div>
                                        <TextareaAutosize
                                            minRows={2}
                                            maxRows={3}
                                            className="contractorsow-subsection-desc"
                                            placeholder="Ex: 20 sqft, yellow paint, etc..."
                                            value={bid?.quantity}
                                            onChange={e => (setBid(prev => ({...prev, quantity:e.target.value})), setEdit(true))}
                                            onBlur={() => {
                                                if(edit){
                                                    handleSave();
                                                }
                                            }}
                                            {...(published && {disabled:true})}
                                        />
                                    </div>

                                    {generatingSOW 
                                    ?   <div className="editproject-sowgeneration-spinner-container">
                                            <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                            Loading...
                                        </div>
                                    :   <div className="contractorbid-subsection-top-container">
                                            {project?.items && 
                                                <div>
                                                    <div>Details</div>
                                                    <div className="projectsubsection-items-conatiner">
                                                        {sub?.items
                                                            && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                                <SOWItem
                                                                    key={i.id}
                                                                    item={i}
                                                                    sub={sub}
                                                                    s={s}
                                                                    published={published}
                                                                    setProject={setProject}
                                                                />
                                                            )
                                                        }
                                                    </div>   
                                                    {!published && <div className="projectsubsection-additem">
                                                        <button
                                                            className="default-button-small"
                                                            onClick={addItem}
                                                        >
                                                            <b>+</b> Add item
                                                        </button>
                                                    </div>}
                                                </div>
                                            }

                                            {project?.owner_mats &&
                                                <div>
                                                    Owner materials (exclusions)
                                                    
                                                    <div className="projectsubsection-items-conatiner">
                                                        {sub?.owner_materials && Object.values(sub?.owner_materials).length
                                                        ?   Object.values(sub?.owner_materials)?.map(m =>
                                                                <OnwerMaterial
                                                                    key={m.id}
                                                                    material={m}
                                                                    published={published}
                                                                    sectionId={s.id}
                                                                    subId={sub.id}
                                                                    setSubsection={setSubsection}
                                                                    setProject={setProject}
                                                                    showSwitch={project?.contractor_mats}
                                                                />
                                                            )
                                                        :   <div>No materials</div>}
                                                    </div>
                                    
                                                    {!published && 
                                                        <div className="projectsubsection-additem">
                                                            <button
                                                                className="default-button-small"
                                                                onClick={addOwner}
                                                            >
                                                                <b>+</b> Add material
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {project?.contractor_mats &&
                                                <div>
                                                    Contractor materials
                                    
                                                    <div className="projectsubsection-items-conatiner">
                                                        {sub?.contractor_materials && Object.values(sub?.contractor_materials).length 
                                                        ?   Object.values(sub?.contractor_materials)?.map(m =>
                                                                <ContractorMaterial
                                                                    key={m.id}
                                                                    material={m}
                                                                    published={published}
                                                                    subsection={sub}
                                                                    setSubsection={setSubsection}
                                                                    sectionId={s.id}
                                                                    subId={sub.id}
                                                                    setProject={setProject}
                                                                    showSwitch={project?.owner_mats}
                                                                />
                                                            )
                                                        :   <div>No materials</div>
                                                        }
                                                    </div>
                                    
                                                    {!published && 
                                                        <div className="projectsubsection-additem">
                                                            <button
                                                                className="default-button-small"
                                                                onClick={addContractor}
                                                            >
                                                                <b>+</b> Add material
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }

                            {+project?.items + +project?.owner_mats + +project?.contractor_mats === 3 &&
                                <>
                                    <div className="contractorbid-subsection-top-container">
                                        <div>
                                            <div>Description</div>
                                            <TextareaAutosize
                                                minRows={2}
                                                maxRows={3}
                                                className="contractorsow-subsection-desc"
                                                placeholder="Ex: 20 sqft, yellow paint, etc..."
                                                value={bid?.quantity}
                                                onChange={e => (setBid(prev => ({...prev, quantity:e.target.value})), setEdit(true))}
                                                onBlur={() => {
                                                    if(edit){
                                                        handleSave();
                                                    }
                                                }}
                                                {...(published && {disabled:true})}
                                            />
                                        </div>
                                        
                                        {generatingSOW 
                                        ?   <div className="editproject-sowgeneration-spinner-container">
                                                <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                                Loading...
                                            </div>
                                        :   <div>
                                                <div>Details</div>
                                                <div className="projectsubsection-items-conatiner">
                                                    {sub?.items
                                                        && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                            <SOWItem
                                                                key={i.id}
                                                                item={i}
                                                                sub={sub}
                                                                s={s}
                                                                published={published}
                                                                setProject={setProject}
                                                            />
                                                        )
                                                    }
                                                </div>   
                                                {!published && <div className="projectsubsection-additem">
                                                    <button
                                                        className="default-button-small"
                                                        onClick={addItem}
                                                    >
                                                        <b>+</b> Add item
                                                    </button>
                                                </div>}
                                            </div>
                                        }
                                    </div>

                                    {generatingSOW 
                                    ?   <div className="editproject-sowgeneration-spinner-container">
                                            <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                            Loading...
                                        </div>
                                    :   <div className="contractorsow-subsection-materials-container">
                                            <div>
                                                Owner materials (exclusions)
                                                
                                                <div className="projectsubsection-items-conatiner">
                                                    {sub?.owner_materials && Object.values(sub?.owner_materials).length
                                                    ?   Object.values(sub?.owner_materials)?.map(m =>
                                                            <OnwerMaterial
                                                                key={m.id}
                                                                material={m}
                                                                published={published}
                                                                sectionId={s.id}
                                                                subId={sub.id}
                                                                setSubsection={setSubsection}
                                                                setProject={setProject}
                                                                showSwitch={project?.contractor_mats}
                                                            />
                                                        )
                                                    :   <div>No materials</div>}
                                                </div>
                                
                                                {!published && 
                                                    <div className="projectsubsection-additem">
                                                        <button
                                                            className="default-button-small"
                                                            onClick={addOwner}
                                                        >
                                                            <b>+</b> Add material
                                                        </button>
                                                    </div>
                                                }
                                            </div>

                                            <div>
                                                Contractor materials
                                
                                                <div className="projectsubsection-items-conatiner">
                                                    {sub?.contractor_materials && Object.values(sub?.contractor_materials).length 
                                                    ?   Object.values(sub?.contractor_materials)?.map(m =>
                                                            <ContractorMaterial
                                                                key={m.id}
                                                                material={m}
                                                                published={published}
                                                                subsection={sub}
                                                                setSubsection={setSubsection}
                                                                sectionId={s.id}
                                                                subId={sub.id}
                                                                setProject={setProject}
                                                                showSwitch={project?.owner_mats}
                                                            />
                                                        )
                                                    :   <div>No materials</div>
                                                    }
                                                </div>
                                
                                                {!published && 
                                                    <div className="projectsubsection-additem">
                                                        <button
                                                            className="default-button-small"
                                                            onClick={addContractor}
                                                        >
                                                            <b>+</b> Add material
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            }   

                            {/*
                            ?   <div className="contractorbid-subsection-top-container">
                                    <div>
                                        <div>Description</div>
                                        <TextareaAutosize
                                            minRows={2}
                                            maxRows={3}
                                            className="contractorsow-subsection-desc"
                                            placeholder="Ex: 20 sqft, yellow paint, etc..."
                                            value={bid?.quantity}
                                            onChange={e => (setBid(prev => ({...prev, quantity:e.target.value})), setEdit(true))}
                                            onBlur={() => {
                                                if(edit){
                                                    handleSave();
                                                }
                                            }}
                                            {...(published && {disabled:true})}
                                        />
                                    </div>
                                    
                                    {generatingSOW 
                                    ?   <div className="editproject-sowgeneration-spinner-container">
                                            <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                            Loading...
                                        </div>
                                    :   <div>
                                            <div>Details</div>
                                            <div className="projectsubsection-items-conatiner">
                                                {sub?.items
                                                    && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                        <SOWItem
                                                            key={i.id}
                                                            item={i}
                                                            sub={sub}
                                                            s={s}
                                                            published={published}
                                                            setProject={setProject}
                                                        />
                                                    )
                                                }
                                            </div>   
                                            {!published && <div className="projectsubsection-additem">
                                                <button
                                                    className="default-button-small"
                                                    onClick={addItem}
                                                >
                                                    <b>+</b> Add item
                                                </button>
                                            </div>}
                                        </div>
                                    }
                                </div>
                            :   <div>
                                    <div>Description</div>
                                    <TextareaAutosize
                                        minRows={2}
                                        maxRows={3}
                                        className="contractorsow-subsection-desc"
                                        placeholder="Ex: 20 sqft, yellow paint, etc..."
                                        value={bid?.quantity}
                                        onChange={e => (setBid(prev => ({...prev, quantity:e.target.value})), setEdit(true))}
                                        onBlur={() => {
                                            if(edit){
                                                handleSave();
                                            }
                                        }}
                                        {...(published && {disabled:true})}
                                    />
                                </div>
                            }*/}
                        
                            {/*generatingSOW 
                            ?   <div className="editproject-sowgeneration-spinner-container">
                                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                    Loading...
                                </div>
                            :   <SubsectionMaterials
                                    setProject={setProject}
                                    sectionId={s.id}
                                    s={s}
                                    subId={sub.id}
                                    sub={subsection}
                                    published={published}
                                />
                            */}
                        </div>}
                        {/*<div className="contractorbid-item-bids">
                            <div className="contractorsow-item-detail-container">
                                <div><small>Materials</small></div>
                                <div>$</div>
                                <input
                                    type={'number'}
                                    value={bid?.materials}
                                    min='0'
                                    onChange={e => (setBid(prev => ({...prev, materials:e.target.value})), setEdit(true))}
                                    onBlur={() => {
                                        if(edit){
                                            handleSave();
                                        }
                                    }}
                                    placeholder='Materials'
                                    className="contractorsow-item-bid"
                                    {...(published && {disabled:true})}
                                />
                            </div>
                            <div className="contractorsow-item-detail-container">
                                <div><small>Instl material</small></div>
                                <div>$</div>
                                <input
                                    type={'number'}
                                    value={bid?.installation_materials}
                                    min='0'
                                    onChange={e => (setBid(prev => ({...prev, installation_materials:e.target.value})), setEdit(true))}
                                    onBlur={() => {
                                        if(edit){
                                            handleSave();
                                        }
                                    }}
                                    placeholder='Instl materials'
                                    className="contractorsow-item-bid"
                                    {...(published && {disabled:true})}
                                />
                            </div>
                            <div className="contractorsow-item-detail-container">
                                <div><small>Labor</small></div>
                                <div>$</div>
                                <input
                                    type={'number'}
                                    value={bid?.installation_price}
                                    min='0'
                                    onChange={e => (setBid(prev => ({...prev, installation_price:e.target.value})), setEdit(true))}
                                    onBlur={() => {
                                        if(edit){
                                            handleSave();
                                        }
                                    }}
                                    placeholder='Instl labor'
                                    className="contractorsow-item-bid"
                                    {...(published && {disabled:true})}
                                />
                            </div>
                            <div className="contractorbid-item-detail-container">
                                <div><b>Total</b></div>
                                <b><div>$</div></b>
                                <b><div>{+bid?.materials + +bid?.installation_materials + +bid?.installation_price}</div></b>
                            </div>
                        </div>*/}
                    
                    {/*<div>
                        <div>Condition SOW</div>
                        {conditionalSOW?.[s.id] && Object.entries(conditionalSOW[s.id]).map(([type, locations]) =>
                            <div key={type}>
                                <div>{type}</div>
                                {Object.values(locations).map(location =>
                                    <div key={location.id}>
                                        <div>{location.location}</div>
                                        {Object.values(location.severities).map(severity =>
                                            <div key={severity.id}>
                                                <div>{severity.desc}</div>
                                                <div>
                                                    Conditions
                                                    {Object.values(severity.conditions).map(cond =>
                                                        <div key={cond.id}>
                                                            <div>{cond.desc}</div>
                                                        </div>    
                                                    )}
                                                </div>
                                                <div>
                                                    SOW
                                                    {Object.values(severity.items).map(item =>
                                                        <div key={item.id}>
                                                            <div>{item.desc}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>    
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>*/}
                </div>
            )}
        </Draggable>
    );
}

function SubComments({ sub, s, setProject, showComment, setShowComment, showDetails, setShowDetails }){
    const [changed, setChanged] = useState(false);

    const [newComment, setNewComment] = useState('');

    const handleAddComment = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsectioncomment/",
            data: {
                contractorprojectsubsection: sub.id,
                comment: newComment,
                author: "Contractor"
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].subsections[sub.id].comments[response.data.id] = response.data;
                return temp;
            })

            setNewComment('');
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (!showComment) {
        return (
            <div 
                className="contractorsow-subsection-comment-icon"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowDetails(prev => ({...prev, [sub.id]: true}));
                    setShowComment(true);
                }}
            >
                {sub?.comments && Object.values(sub?.comments)?.length 
                ?   <>{Object.values(sub?.comments)?.length} <i class="fa-solid fa-comment"></i></>
                :   <>
                        <InfoHover3
                            message={`Add comment for ${sub?.group} - ${sub?.choice} (${sub?.verb})`}
                        >
                            + <i class="fa-regular fa-comment"></i>
                        </InfoHover3>
                    </>
                }
            </div>
        )
    }

    return (
        <div 
            className="contractorsow-subsection-comment-container"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="contractorsow-section-comment-header">
                <b>Subsection comments</b> <div className="delete-x" onClick={() => setShowComment(false)}>X</div>
            </div>
            <div>
                {sub?.group} - {sub?.choice} ({sub?.verb})
            </div>

            {Object.values(sub?.comments)?.length === 0 &&
                <div>
                    No comments yet.
                </div>
            }

            <div>
                {Object.values(sub?.comments)?.sort((a, b) => a.id - b.id).map(comment =>
                    <SubtradeComment
                        key={comment.id}
                        comment={comment}
                        sub={sub}
                        s={s}
                        setProject={setProject}
                    />
                )}
            </div>

            <div className="contractorbidtrade-trade-comment-add">
                Add a comment
            </div>

            <TextareaAutosize
                minRows={1}
                maxRows={5}
                value={newComment}
                onChange={(e) => {
                    setNewComment(e.target.value);

                    setChanged(true);
                }}
                onBlur={() => {
                    if (changed && newComment) {
                        handleAddComment();
                    }
                }}
                className="contractorbidtrade-trade-comment-input"
                placeholder="Comment for this trade..."
            />
        </div>
    )
}

function SubtradeComment({ comment, sub, s, setProject }){
    const [changed, setChanged] = useState(false);

    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsectioncomment/" + comment.id + "/",
            data: {
                comment: comment.comment,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].subsections[sub.id].comments[comment.id] = response.data;
                return temp;
            })

            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsectioncomment/" + comment.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id].subsections[sub.id].comments[comment.id];
                return temp;
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (comment.author === "Contractor"){
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Contractor</b></div>

                    <div className="contractorbidtrade-trade-comment-header">
                        <div><small>{comment?.date_edited?.split('T')[0]}</small></div>

                        &nbsp;

                        <div className="delete-x"><small>
                            <i 
                                class="fa-solid fa-trash"
                                onClick={() => handleDelete()}
                            ></i>
                        </small></div>
                    </div>
                </div>

                <div>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={comment.comment}
                        onChange={(e) => {
                            setProject(prev => {
                                let temp = {...prev};
                                temp.sections[s.id].subsections[sub.id].comments[comment.id].comment = e.target.value;
                                return temp;
                            })

                            setChanged(true);
                        }}
                        onBlur={() => {
                            if (changed){
                                handleSave();
                            }
                        }}
                        className="contractorbidtrade-trade-comment-input"
                        placeholder="Leave a comment for this subsection"
                    />
                </div>
            </div>
        )
    }else {
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Homeowner</b></div>
                    <div><small>{comment?.date_edited?.split('T')[0]}</small></div>
                </div>

                <div>{comment.comment}</div>
            </div>
        )
    }
}

function SubsectionMaterials({ setProject, sectionId, subId, published, s, sub }){
    const [subsection, setSubsection] = useState(sub);

    useEffect(() => {
        setSubsection(sub);
    }, [sub])

    const addOwner = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectownermaterial/",
            data: {
                desc: "",
                contractorprojectsubsection: subId
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                prev.sections[sectionId]['subsections'][subId]['owner_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const addContractor = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontractormaterial/",
            data: {
                desc: "",
                contractorprojectsubsection: subId
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                prev.sections[sectionId]['subsections'][subId]['contractor_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="contractorsow-subsection-materials-container">
            <div>
                Owner materials  (exclusions)
                
                <div className="projectsubsection-items-conatiner">
                    {subsection?.owner_materials && Object.values(subsection?.owner_materials).length
                    ?   Object.values(subsection?.owner_materials)?.map(m =>
                            <OnwerMaterial
                                key={m.id}
                                material={m}
                                published={published}
                                setSubsection={setSubsection}
                                sectionId={sectionId}
                                subId={subId}
                                setProject={setProject}
                            />
                        )
                    :   <div>No materials</div>}
                </div>

                {!published && 
                    <div className="projectsubsection-additem">
                        <button
                            className="default-button-small"
                            onClick={addOwner}
                        >
                            <b>+</b> Add material
                        </button>
                    </div>
                }
            </div>
            <div>
                Contractor materials

                <div className="projectsubsection-items-conatiner">
                    {subsection?.contractor_materials && Object.values(subsection?.contractor_materials).length 
                    ?   Object.values(subsection?.contractor_materials)?.map(m =>
                            <ContractorMaterial
                                key={m.id}
                                material={m}
                                published={published}
                                subsection={subsection}
                                setSubsection={setSubsection}
                                sectionId={sectionId}
                                subId={subId}
                                setProject={setProject}
                            />
                        )
                    :   <div>No materials</div>
                    }
                </div>

                {!published && 
                    <div className="projectsubsection-additem">
                        <button
                            className="default-button-small"
                            onClick={addContractor}
                        >
                            <b>+</b> Add material
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}


function ContractorMaterial({ material, published, subsection, setSubsection, sectionId, subId, setProject, showSwitch }){
    const [changed, setChanged] = useState(false);
    const [itemDesc, setItemDesc] = useState(material.desc || "");

    const handleChange = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontractormaterial/" + material.id + "/",
            data: {
                desc: itemDesc,
                contractorprojectsubsection: material.contractorprojectsubsection
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => { 
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[sectionId]['subsections'][subId]['contractor_materials'][material.id]['desc'] = itemDesc;
                return {...temp};
            })

            setChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontractormaterial/" + material.id + "/",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[sectionId]['subsections'][subId]['contractor_materials'][material.id];
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveToContractor = () => {
        //axios delete owner material
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontractormaterial/" + material.id + "/",
            headers: {
                "Content-Type": "application/json"
            }            
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[sectionId]['subsections'][subId]['contractor_materials'][material.id];
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })

        //axios post contractor material
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectownermaterial/",
            data: {
                desc: itemDesc,
                contractorprojectsubsection: subId
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                prev.sections[sectionId]['subsections'][subId]['owner_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
    }

    return (
        <div className="editprojectdetail-item">
            {!published && showSwitch && 
                <button
                    className="editprojectdetail-itemup-button"
                    onClick={(e) => {moveToContractor(); e.stopPropagation()}}
                >
                    &#x3c;
                </button>
            }

            <input
                value={itemDesc}
                onChange={e => {
                    setItemDesc(e.target.value);
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleChange();
                    }
                }}
                className="editprojectdetail-item-desc"
                {...(published && {disabled:true})}
            >
            </input>

            {/*changed && <button onClick={handleChange} className="editprojectdetail-item-save">Save</button>*/}                        
            
            {!published && 
                <button 
                    className="delete-x editprojectdetail-item-delete"
                    onClick={handleDelete}
                    style={{fontSize: '12px'}}
                >
                    X
                </button>
            }
        </div>
    )
}

function OnwerMaterial({ material, published, setSubsection, sectionId, subId, setProject, showSwitch }){
    const [changed, setChanged] = useState(false);
    const [itemDesc, setItemDesc] = useState(material.desc || "");

    const handleChange = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectownermaterial/" + material.id + "/",
            data: {
                desc: itemDesc,
                contractorprojectsubsection: material.contractorprojectsubsection
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => { 
            console.log(response);

            /*setSections(prev => {
                prev[sectionId]['subsections'][subId]['owner_materials'][material.id]['desc'] = itemDesc;
                return {...prev};
            })*/

            setProject(prev => {
                let temp = {...prev};
                temp.sections[sectionId]['subsections'][subId]['owner_materials'][material.id]['desc'] = itemDesc;
                return {...temp};
            })

            setChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectownermaterial/" + material.id + "/",
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[sectionId]['subsections'][subId]['owner_materials'][material.id];
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveToContractor = () => {
        //axios delete owner material
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectownermaterial/" + material.id + "/",
            headers: {
                "Content-Type": "application/json"
            }            
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[sectionId]['subsections'][subId]['owner_materials'][material.id];
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })

        //axios post contractor material
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontractormaterial/",
            data: {
                desc: itemDesc,
                contractorprojectsubsection: subId
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            console.log(response);

            setProject(prev => {
                prev.sections[sectionId]['subsections'][subId]['contractor_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
    }

    return (
        <div className="editprojectdetail-item">
            {!published && showSwitch &&
                <button
                    className="editprojectdetail-itemup-button"
                    onClick={(e) => {moveToContractor(); e.stopPropagation()}}
                >
                    &#x3e;
                </button>
            }

            <input
                value={itemDesc}
                onChange={e => {
                    setItemDesc(e.target.value);
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleChange();
                    }
                }}
                className="editprojectdetail-item-desc"
                {...(published && {disabled:true})}
            >
            </input>

            {/*changed && <button onClick={handleChange} className="editprojectdetail-item-save">Save</button>*/}                        
            
            {!published && 
                <button 
                    className="delete-x editprojectdetail-item-delete"
                    onClick={handleDelete}
                    style={{fontSize: '12px'}}
                >
                    X
                </button>
            }
        </div>
    )
}

const SOWItem = ({ item, sub, s, setProject, published }) => {
    const [itemDesc, setItemDesc] = useState(item.desc);
    const [changed, setChanged] = useState(false);

    const handleSave = () => {
        //axios put new desc
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/" + item.id + "/",
            data: {
                desc: itemDesc,
                contractorprojectsubsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setChanged(false);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].subsections[sub.id].items[item.id].desc = itemDesc;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/" + item.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id].subsections[sub.id].items[item.id];
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveUp = () => {
        let items = Object.values(sub.items).sort((a, b) => a.order - b.order);
        let index = items.findIndex(i => i.id === item.id);

        let curItemId = item.id;
        let curOrder = items[index].order;
        let otheritemId = items[index - 1].id;
        let otherOrder = items[index - 1].order;

        setProject(prev => {
            let temp = {...prev};
            temp.sections[s.id].subsections[sub.id].items[curItemId].order = otherOrder;
            temp.sections[s.id].subsections[sub.id].items[otheritemId].order = curOrder;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/" + curItemId + "/",
            data: {
                order: otherOrder,
                contractorprojectsubsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/" + otheritemId + "/",
            data: {
                order: curOrder,
                contractorprojectsubsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveDown = () => {
        let items = Object.values(sub.items).sort((a, b) => a.order - b.order);
        let index = items.findIndex(i => i.id === item.id);

        let curItemId = item.id;
        let curOrder = items[index].order;
        let otheritemId = items[index + 1].id;
        let otherOrder = items[index + 1].order;

        setProject(prev => {
            let temp = {...prev};
            temp.sections[s.id].subsections[sub.id].items[curItemId].order = otherOrder;
            temp.sections[s.id].subsections[sub.id].items[otheritemId].order = curOrder;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/" + curItemId + "/",
            data: {
                order: otherOrder,
                contractorprojectsubsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectitem/" + otheritemId + "/",
            data: {
                order: curOrder,
                contractorprojectsubsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="editprojectdetail-item">
            {!published && 
                <div className="editprojectdetail-updown-container">
                    {item.order !== Object.values(sub.items).map((item) => {if(item.deleted) return null; return item.order}).reduce((a, b) => Math.min(a, b), 999) &&
                    <button className="editprojectdetail-itemup-button" onClick={() => moveUp()}>&and;</button>}
                    {item.order !== Object.values(sub.items).map((item) => {if(item.deleted) return null; return item.order}).reduce((a, b) => Math.max(a, b), -1) &&
                    <button className="editprojectdetail-itemdown-button" onClick={() => moveDown()}>&or;</button>}
                </div>
            }

            <input
                value={itemDesc}
                onChange={e => {
                    setItemDesc(e.target.value);
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleSave();
                    }
                }}
                disabled={published}
                className="editprojectdetail-item-desc"
            >
            </input>
            
            {/*changed && <button onClick={() => handleSave()} className="editprojectdetail-item-save">Save</button>*/}
            
            {!published && <button 
                className="delete-x editprojectdetail-item-delete"
                onClick={() => handleDelete()}
                style={{fontSize: '12px'}}
            >
                X
            </button>}
        </div>
    )
}

const SideNav = ({ sections }) => {
    const [activeItem, setActiveItem] = useState(null);
    const observer = useRef(null);
  
    //not working!!! the sections are too short lol
    const findActiveSection = () => {
        const sections = document.querySelectorAll('[id^="section-"]');
        let activeSection = null;
        let viewportTop = 100;
        let viewportBottom = viewportTop + 300;
    
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            const top = rect.top;
            const bottom = rect.bottom;
    
            if (top <= viewportBottom && bottom >= viewportBottom || top <= viewportTop && bottom >= viewportTop) {
                console.log(section.id)
                activeSection = section;
                return;
            }
        });
    
        return activeSection;
    };

    useEffect(() => {
        if(sections){
            observer.current = () => {
                const activeSection = findActiveSection();
                setActiveItem(activeSection ? activeSection.id : null);
            };

            window.addEventListener('scroll', observer.current);
            return () => window.removeEventListener('scroll', observer.current);
        }
    }, [sections]);
    
    return (
        <div className="sidenav-container">
            <ul>      
                {Object.values(sections)?.map(s => 
                    <li key={s.id}><div
                        onClick={(e) => {
                            e.preventDefault();
                            const element = document.querySelector(`#nav-section-${s.id}`);
                            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                            const yOffset = -200;
                            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
                        }}
                        className={activeItem === `nav-section-${s.id}` ? 'nav-item-active' : 'nav-item'}
                    >
                        {s.choice}
                    </div></li>
                )}
            </ul>
        </div>
    )
}