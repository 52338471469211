import { useEffect, useRef, useState } from "react";
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import "../styles/contractordashboard.css";
import NotFoundPage from "../components/NotFoundPage";
import InfoHover3 from "../components/InfoHover3";

const stripe = require('stripe')('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');

export default function ContractorDashboard({ user }){
    const [addedProjects, setAddedProjects] = useState([]);
    const [newProjects, setNewProjects] = useState([]);
    const [createdProjects, setCreatedProjects] = useState([]);

    const [customer, setCustomer] = useState(null);

    const [notFoundError, setNotFoundError] = useState(false);

    useEffect(() => {
        if(user?.groups.includes(1)){
            setNotFoundError(true);        
        }

        stripe.customers.list({
            email: user.email,
            expand: ['data.subscriptions']
        })
        .then((response) => {
            console.log(response);
            setCustomer(response.data[0]);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [user])

    useEffect(() => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/get-contractor-dashboard-projects/',
            data: {
                userID: user.id,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);
            setAddedProjects(response.data.data.addedProjects);

            let newProjectsTemp = [];
            response.data.data.newProjects.map(proj => {
                if(!proj.bid_chosen){
                    if(!user.groups.includes(4)){
                        if(!proj.test){
                            newProjectsTemp.push(proj);
                        }
                    }else{
                        newProjectsTemp.push(proj);
                    }
                }
            })
            setNewProjects(newProjectsTemp);

            setCreatedProjects(response.data.data.createdProjects);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [user]);

    if(notFoundError){
        return (
            <NotFoundPage />
        )
    }

    return (
        <div className="contractordashboard-container">
            {/*<SideNav/>*/}

            <div>
                <div className="contractordashboard-createdprojects-header">
                    <div className="contractordashboard-title">Contractor Dashboard</div>
                </div>

                {/*<div className="editprojectdetail-options">
                    <div className="editprojectdetail-options-template">
                        See all published projects in the SF Bay Area.
                        <a className="dashboard-project-actionlink" href={'/contractor-map'}>
                            <button className="default-button editprojectdetail-viewtemplates-button"><nobr>Project map</nobr></button>
                        </a>
                    </div>
                    <div className="editprojectdetail-options-template">
                        Build out your profile to get more projects.
                        <a className="dashboard-project-actionlink" href={'/contractor-profile'}>
                            <button className="default-button editprojectdetail-viewtemplates-button"><nobr>View Profile</nobr></button>
                        </a>
                    </div>
                </div>*/}
            </div>
            <br></br>

            <CreatedProjects user={user} createdProjects={createdProjects} customer={customer}/>
            
            {/*<AddedProjects addedProjects={addedProjects} user={user}/>
            <SearchProjects newProjects={newProjects}/>*/}
        </div>
    );
}

function SideNav(){
    const [activeItem, setActiveItem] = useState(null);
    const observer = useRef(null);
  
    //not working!!! the sections are too short lol
    const findActiveSection = () => {
        const sections = document.querySelectorAll('[id$="-projects"]');
        
        let activeSection = null;
        let viewportTop = 100;
        let viewportBottom = viewportTop + 300;
    
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            const top = rect.top;
            const bottom = rect.bottom;
    
            if (top <= viewportBottom && bottom >= viewportBottom || top <= viewportTop && bottom >= viewportTop) {
                activeSection = section;
                return;
            }
        });
    
        return activeSection;
    };

    useEffect(() => {
        observer.current = () => {
            const activeSection = findActiveSection();
            setActiveItem(activeSection ? activeSection.id : null);
        };

        window.addEventListener('scroll', observer.current);
        return () => window.removeEventListener('scroll', observer.current);
    }, []);
    
    return (
        <div className="sidenav-container">
            <ul>      
                <li>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            const element = document.querySelector(`#created-projects`);
                            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                            const yOffset = -100;
                            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
                        }}
                        className={activeItem === `nav-extra` ? 'nav-item-active' : 'nav-item'}
                    >
                        Created projects
                    </div>
                </li>
                <li>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            const element = document.querySelector(`#added-projects`);
                            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                            const yOffset = -100;
                            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
                        }}
                        className={activeItem === `nav-extra` ? 'nav-item-active' : 'nav-item'}
                    >
                        Added projects
                    </div>
                </li>
                <li>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            const element = document.querySelector(`#search-projects`);
                            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                            const yOffset = -100;
                            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
                        }}
                        className={activeItem === `nav-extra` ? 'nav-item-active' : 'nav-item'}
                    >
                        Looking for contractors
                    </div>
                </li>
            </ul>
        </div>
    )
}

function CreatedProjects({ user, createdProjects, customer }){
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

    return (
        <div className="contractordashboard-addedprojects">
            {!customer?.subscriptions?.data[0] && !user.groups.includes(4) &&
                <div className="contractordashboard-createdprojects-upgrade-container">
                    <div className="contractordashboard-createdprojects-upgrade">
                        <div className="contractordashboard-createdprojects-upgrade-desc">
                            You are currently on the free plan, which allows you to have 5 projects. Upgrade to a paid plan to get unlimited projects.
                        </div>  
                        
                        <div>
                            <a href="/contractor-account">
                                <button className="active-button">
                                    See plans
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="contractordashboard-createdprojects-upgrade">
                        <div className="contractordashboard-createdprojects-upgrade-desc">
                            If you are a commercial contractor, this product is geared for residential projects. Grab a time to learn more about our tailored enterprise offering.
                        </div>  
                        
                        <div>
                            <a href="/demo">
                                <button className="active-button">
                                    Book demo
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            }

            <div className="contractordashboard-createdprojects-header" id="created-projects">
                {/*<div className="contractordashboard-createdprojects-title">Projects you created</div>*/}

                <div>
                    {(customer?.subscriptions?.data[0] || user.groups.includes(4))
                    ?   <>
                            <button className="active-button" onClick={() => setShowCreateProjectModal(true)}>Create Project</button>
                        </>
                    :   createdProjects.length < 5
                        ?   <button className="active-button" onClick={() => setShowCreateProjectModal(true)}>Create Project</button>
                        :   <InfoHover3
                                message={'The free plan allows you to have 5 projects. Upgrade to a paid plan to get unlimited projects.'}
                            >
                                <button className="default-button" disabled>Create Project</button>
                            </InfoHover3>
                    }
                </div>


                <div>
                    {customer?.subscriptions?.data[0]
                    ?   <b>
                            On the {customer.subscriptions.data[0].plan.interval === "month" ? "monthly" : "yearly"} plan
                        </b>
                    :   user.groups.includes(4)
                        ?   <>
                                Test user
                            </>
                        :   <></>
                    }
                </div>

                <CreateProjectModal 
                    user={user} 
                    showCreateProjectModal={showCreateProjectModal} 
                    setShowCreateProjectModal={setShowCreateProjectModal}
                />
            </div>

            <div className="contractordashboard-addedprojects-container">
                {(createdProjects && createdProjects.length > 0) && createdProjects?.sort((a, b) => (a.id < b.id) ? 1 : -1).map(proj =>
                    <a key={proj.id} href={'/contractor-createdproject/' + proj.uuid} className='contractordashboard-link'>
                        <div className="contractordashboard-searchproject">
                            <b>{proj.address}</b>
                            {proj.desc 
                            ?   <div className="contractordashboard-addedproject-desc">
                                    {proj.desc}
                                </div>
                            :   <div>No description...</div>
                            }
                        </div>
                    </a>
                )}
            </div>
        </div>            
    )
}

function CreateProjectModal({ user, showCreateProjectModal, setShowCreateProjectModal }){
    const [address, setAddress] = useState('');

    const handleCreate = (address, zipcode, owner) => {
        axios.get('https://bob-backendapi.herokuapp.com/api/generalcontractor/', {
            params: { user: user.id },
        })
        .then((response) => {
            console.log(response);

            let contractor = response.data[0].id;

            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/contractorproject/',
                data: {
                    "address": address,
                    "desc": "",
                    "published": false,
                    "generalcontractor": contractor
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
                window.location.href = "/contractor-createdproject/" + response.data.uuid;
            })
            .catch((error) => {
                console.log(error);
            })
        })
        .catch((error) => {
            console.log(error);
        });                
    }

    return (
        <Modal show={showCreateProjectModal} onHide={() => setShowCreateProjectModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create new project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Create your own project to build comprehensive SOWs, bids, and contracts for homeowners not on Bobyard.
                <PlacesAutocomplete
                    value={address}
                    onChange={(a) => setAddress(a)}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <div className="dashboard-createproject-address">
                            <div className="dashboard-createproject-details">Address</div>
                            <input
                                {...getInputProps({
                                    type:"text",
                                    id:"dashboard-createproject-address",
                                    className:"dashboard-createproject-input",
                                    name:"address",
                                    placeholder:"Project address..."
                                })}
                            />
                        </div>
                        <div className="dashboard-address-autocomplete-dropdown-container">
                        {loading && <div></div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active ? 'dashboard-address-suggestion-item-active' : 'dashboard-address-suggestion-item';
                            return (
                            <div {...getSuggestionItemProps(suggestion, {className})} key={suggestion.description}>
                                <span className="dashboard-address-autocomplete-item">{suggestion.description}</span>
                            </div>
                            );
                        })}
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
            </Modal.Body>
            <Modal.Footer>
                {address && <button className="active-button" onClick={e => handleCreate(address, user)}>Create</button>}
                <button className="default-button dashboard-createproject-closebutton" onClick={() => setShowCreateProjectModal(false)}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

function SearchProjects({ newProjects }){
    return (
        <div className="contractordashboard-search-projects">
            <div className="contractordashboard-addedprojects-title" id="search-projects">Projects looking for contractors</div>
            
            <div className="contractordashboard-addedprojects-container">
                {newProjects?.sort((a, b) => (a.id < b.id) ? 1 : -1).map(proj =>
                    <a key={proj.id} href={'/contractor-view-project/' + proj.uuid} className='contractordashboard-link'>
                        <div className="contractordashboard-searchproject">
                            <div><b>{proj?.zip_code}</b></div>
                            <div>{proj?.fire_station_name}</div>
                            {proj.desc 
                            ?   <div className="contractordashboard-addedproject-desc">
                                    {proj.desc}
                                </div>
                            :   <div>No description...</div>
                            }
                        </div>
                    </a>
                )}
            </div>
        </div>
    ); 
}

function AddedProjects({ addedProjects, user }){
    return (
        <div className="contractordashboard-addedprojects">
            <div className="contractordashboard-addedprojects-title" id="added-projects">Added projects</div>
        
            <div className="contractordashboard-addedprojects-container">
                {addedProjects.length 
                ?   addedProjects?.sort((a, b) => (a.id < b.id) ? 1 : -1).map(p => <AddedProject key={p.id} project={p} user={user}/>) 
                :   <div className="contractordashboard-addedprojects-desc">Add projects to sign up for their bid walks and place bid</div>
                }
            </div>
        </div>
    );
}

function AddedProject({ project, user }){
    return (
        <a href={"/contractor-project/" + project?.uuid} className='contractordashboard-link'>
            <div className="contractordashboard-addedproject">
                <div><b>{project?.zip_code}</b></div>
                <div>{project?.fire_station_name}</div>
                {project?.homeowner?.user?.email}

                <div className="contractordashboard-addedproject-desc">{project?.desc ? <>{project.desc}</> : <>No description...</>}</div>                

                <div><b>Project status</b></div>
                {project?.bid_chosen 
                ?   <>
                        {project?.bid_choice?.general_contractor?.user?.id === user.id 
                        ?   <div className="contractordashboard-addedproject-blue">Owner chose your bid!</div> 
                        :   <div className="contractordashboard-addedproject-gray">Bid chosen</div>
                        }
                    </>
                :   <div className="contractordashboard-addedproject-blue">Accepting bids</div>}
            </div>
        </a>
    );
}

