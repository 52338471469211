import { useState } from "react";
import "../styles/Demo2.css"
import LandingNavbar from "./LandingNavbar";
import Landing5Navbar from "./Landing5Navbar";

export default function Demo2() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <div className='demo2-container'>
            <div className='demo2-navbar'>
                <Landing5Navbar />
            </div>

            <div className='demo2-content'>
                <iframe
                    src="https://calendly.com/bobyard/30min"
                    className="demo2-iframe"
                ></iframe>
            </div>
        </div>
    )
}