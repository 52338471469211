import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        if(tokenString === 'undefined') return null;
        const userToken = JSON.parse(tokenString);
        console.log('local storage get', userToken);
        return userToken ? userToken : null;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        console.log('local storage save',userToken);
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}