import { useState } from 'react';

export default function useUser() {
    const getUser = () => {
        const userString = localStorage.getItem('user');
        if(userString === 'undefined') return null;
        const user = JSON.parse(userString);
        console.log('local storage get', user);
        return user ? user : null;
    };

    const [user, setUser] = useState(getUser());

    const saveUser = user => {
        console.log('local storage save',user);
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
    };

    return {
        setUser: saveUser,
        user
    }
}