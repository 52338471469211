import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import emailjs from '@emailjs/browser';
import axios from 'axios';

import { validateEmail } from "../helper/validateEmail";
import usePasswordValidator from "../helper/usePasswordValidator";

const stripe = require('stripe')('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');

export default function ContractorRegister({ user }) {
    const params = useParams();
    const sessionID = params.sessionID;

    const [loading, setLoading] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [password, setPassword, passwordError] = usePasswordValidator({
        min: 8,
        max: 20
    });

    const [orgName, setOrgName] = useState("");
    const [location, setLocation] = useState("");
    const [license, setLicense] = useState("");

    let navigate = useNavigate();

    const routeChange = () =>{
        let path = '/login';
        navigate(path);
    }

    useEffect(() => {
        console.log(sessionID);

        stripe.checkout.sessions.retrieve(sessionID)
        .then((response) => {
            console.log(response);

            setLoading(false);

            console.log(response.customer_details.email);
            setEmail(response.customer_details.email);
            setPaymentSuccess(true);
        })
        .catch((error) => {
            console.log(error);

            setLoading(false);
            setPaymentSuccess(false);
        })
    }, [sessionID]);

    useEffect(() => {
        if (!email) {
            setEmailError("");
        } else {
            if (validateEmail(email)) {
                setEmailError("");
            } else {
                setEmailError("Please enter a valid email.");
            }
        }
    }, [email]);

    useEffect(
        () => {
        if (!confirmPassword || !password) {
            setConfirmPasswordError("");
        } else {
            if (password !== confirmPassword) {
                setConfirmPasswordError("The passwords must match.");
            } else {
                setConfirmPasswordError("");
            }
        }
        },
        [password, confirmPassword]
    );

    useEffect(() => {
        if(user){
            if(user.groups.includes(2)){
            navigate('/contractor-dashboard');
            }else {
            navigate('/dashboard');
            }
        }
    }, [user])

    function buttonDisabled() {
        if(emailError !== "" || passwordError !== "" || confirmPasswordError !== "" || email === "" || password === "" || confirmPassword === "") {
            return true;
        }
        return false;
    }

    function handleSubmit(e){
        e.preventDefault();
        let groups = [2,];

        const form_data = new FormData();
        form_data.append('email', email);
        form_data.append('username', email);
        form_data.append('password', password);
        form_data.append('password2', confirmPassword);
        form_data.append('groups', groups);
        form_data.append('license', license);
        //form_data.append('is_superuser', false);
        //form_data.append('is_staff', false);
        //form_data.append('is_active', true);
        //form_data.append('date_joined', new Date().toJSON());

        console.log([...form_data]);

        let data = JSON.stringify({
            email: email,
            username: email,
            password: password,
            password2: confirmPassword,
            groups: groups
        });

        //console.log(data);
    
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/users/",
            data: data,
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            console.log(response);

            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/generalcontractor/",
                data: {
                    user: response.data.id,
                    org_name: orgName,
                    license: license,
                    location: location,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(response => {
                console.log(response);

                emailjs.send('service_p6pe8ud', 'template_yynx8io', {
                    subject: 'Bobyard new contractor registered',
                    message: "New user has made an contractor account! (" + email + ")",
                    to_email: 'info@bobyard.com',
                    from_name: 'Bobyard',
                    cc: "mding26@stanford.edu",
                }, 'UPyEH6AdQ86GCk5j6')
                .then((result) => {
                    console.log(result);
                }, (error) => {
                    console.log(error); 
                });

                routeChange();
            })
            .catch(error => {
                console.log(error);
            })
        })
        .catch((error) => {
            console.log(error.response.data);

            if(error.response.data.email){
                setEmailError("This email is already registered.");
            }
        })
    }

    return (
        <div className="regsiterchoice-background">
        <div className="registercontainer">
            {loading 
            ?   <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...
                </div>       
            :   paymentSuccess
                ?   <form>
                        <h3>Welcome to Bobyard</h3>
                        <div className="register-info">Create <b>contractor</b> account</div>
                        <div>Congratulations, your payment was successful! <br></br>Please register with the same email you used to pay.</div>

                        {/*<input
                            value={orgName}
                            className="inputregister"
                            onChange={e => setOrgName(e.target.value)}
                            type="text"
                            placeholder="Organization name..."
                        />*/}
                        {/*<input
                            value={location}
                            className="inputregister"
                            onChange={e => setLocation(e.target.value)}
                            type="text"
                            placeholder="Location..."
                        />*/}
                        {/*<input
                            value={license}
                            className="inputregister"
                            onChange={e => setLicense(e.target.value)}
                            type="text"
                            placeholder="License id..."
                        />*/}

                        <input
                            value={email}
                            className="inputregister"
                            onChange={e => setEmail(e.target.value)}
                            type="text"
                            placeholder="Email"
                            disabled
                        />
                        <div className="error">{emailError}</div>

                        <input
                            value={password}
                            className="inputregister"
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            placeholder="Password"
                        />
                        <div className="error">{passwordError}</div>

                        <input
                            value={confirmPassword}
                            className="inputregister"
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password"
                            placeholder="Confirm Password"
                        />
                        <div className="error">{confirmPasswordError}</div>

                        <div><small>
                            By clicking "Submit", you agree <br></br>to Bobyard's <a href="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Bobyard+-+Contractor+Terms+of+Use.pdf" target="_blank">Terms of Service</a>.
                        </small></div>

                        <button 
                            type="submit"
                            className ="default-button"
                            onClick={e => handleSubmit(e)}
                            disabled = {buttonDisabled()}
                        >
                            Submit
                        </button>
                    </form>
                :   <div>
                        <h3>Payment failed</h3>
                        <div className="register-info">Please try again.</div>
                    </div>
            }           

            <div className='support-footer-container'>
                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/logo.png"} className='homepage-footer-logoimg' />
                <div>
                    <div>Venture backed</div>
                    <div>Bobyard, Inc 2023</div>
                </div>  
            </div>
        </div>
        </div>
    );
}