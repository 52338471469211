import { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import emailjs from '@emailjs/browser';
import '../styles/Contact.css';
import LandingNavbar from './LandingNavbar';
import Landing5Navbar from './Landing5Navbar';

export default function Contact({ }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [message, setMessage] = useState("");

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const [sent, setSent] = useState(false);

    useEffect(() => {
        if (!email) {
            setEmailError("");
        } else {
            if (validateEmail(email)) {
                setEmailError("");
            } else {
                setEmailError("Please enter a valid email.");
            }
        }
    }, [email]);

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const send = () => {
        const temp = `

        Message from ${name} (${email}):
        
        ${message}
        `;

        emailjs.send('service_p6pe8ud', 'template_yynx8io', {
            subject: 'Bobyard message from ' + name,
            message: temp,
            to_email: 'info@bobyard.com',
            from_name: name,
            cc: email,
            bcc: "mding26@stanford.edu",
        }, 'UPyEH6AdQ86GCk5j6')
            .then((result) => {
                console.log(result);

                setName("");
                setEmail("");
                setMessage("");
                setSent(true);
            }, (error) => {
                console.log(error);
            });
    }

    return (
        <div className='contact-container'>
            <Landing5Navbar />

            <div className='contact-content'>
                <div className='contact-body'>
                    <div className='contact-body-title'>
                        Connect
                    </div>

                    <div className='contact-body-desc'>
                        <div>
                            Whether it's about a bug, a feature request, or a question, we are always happy to hear from you!
                        </div>
                        <div>
                            You can also reach us directly at info@bobyard.com
                        </div>
                    </div>

                    <div>
                        <div className="connect-message-input-container">
                            <div>
                                <input
                                    className="connect-message-input"
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    className="connect-message-input"
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <TextareaAutosize
                            minRows={5}
                            maxRows={7}
                            className="connect-message-message"
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />

                        {emailError && <div className="error support-message-error">{emailError}</div>}


                        {email && !emailError && name && message && !sent &&
                            <div>
                                <button
                                    className="active-button"
                                    onClick={() => send()}
                                >
                                    Send
                                </button>
                            </div>
                        }

                        {sent &&
                            <div className="support-message-sent">
                                Message sent! We will get back to you as soon as possible.
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}