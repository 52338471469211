import { useState, useEffect } from 'react';
import "../styles/Landing4.css";
import axios from 'axios';

import { IconBrandLinkedin } from '@tabler/icons-react';
import LandingNavbar from './LandingNavbar';

export default function Landing4() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <div className='landing4-container'>
      <div className='landing4-background'>
        <img
          className='landing4-background-image'
          src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Landingpage-render-final.gif'
        />
      </div>

      <div className='landing4-content'>
        <LandingNavbar />

        <div className='landing4-body'>
          <div className='landing4-mobile-render'>
            <img
              className='landing4-mobile-render-image'
              src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Landingpage-render-final.gif'
            />
          </div>

          <div className='landing4-body-main'>
            <div className='landing4-body-title'>
              Instant AI takeoffs
            </div>
            <div className='landing4-body-subtitle'>
              Automatically quantify, measure, and estimate projects. Takeoff 10 times faster to win more jobs and grow your business.
            </div>
            <div className='landing4-body-connect-button'>
              <a href='/demo'>
                <button className='landing4-header-menu-button'>
                  Book demo
                </button>
              </a>
            </div>
          </div>

          <div className='landing4-body-backedby'>
            <div className='landing4-body-backedby-title'>
              Backed by
            </div>
            <div className='landing4-body-backedby-logos'>
              <a href='https://www.primary.vc/' target='_blank'>
                <div>
                  <img
                    className='landing4-body-backedby-primary-logo-image'
                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-primary-logo.png'
                  />
                </div>
              </a>

              <a href='https://pear.vc/' target='_blank'>
                <div>
                  <img
                    className='landing4-body-backedby-pear-logo-image'
                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-pear-logo.png'
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
