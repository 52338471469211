import { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';

import "../styles/ContractorDocument.css";
import { SOW } from '../data/SOW';
import NotFoundPage from "../components/NotFoundPage";
import DropDownMenu from "../HomeOwnerPages/EditProjectDetailPages/DropDownMenu";
import ContractorProjectRenderPDF from "./ContractorCreateProjectPages/ContractorProjectRenderPDF";
import ContractorProjectPDFTemplate from "./ContractorCreateProjectPages/ContractorProjectPDFTemplate";
import { shapes } from "konva/lib/Shape";
import ContractorCreatedProjectSideNav from "./ContractorCreateProjectPages/ContractorCreatedProjectSideNav";
import InfoHover2 from "../components/InfoHover2";
import InfoHover3 from "../components/InfoHover3";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Parchment = Quill.import('parchment');
let ClassAttr = new Parchment.Attributor.Attribute('class', 'class', { scope: Parchment.Scope.INLINE });
let EditAttr = new Parchment.Attributor.Attribute('contenteditable', 'contenteditable', { scope: Parchment.Scope.INLINE });
let IdAttr = new Parchment.Attributor.Attribute('commentid','commentid', { scope: Parchment.Scope.INLINE});
Parchment.register(IdAttr);
// Parchment.register(ClassAttr);
Parchment.register(EditAttr);

export default function ContractorDocumentOwnerView({ }){
    const params = useParams();
    const projectUUID = params.projectUUID;
    const navigate = useNavigate();
    
    const location = useLocation();
    const [sID, ] = useState(location.state?.sID);

    const [project, setProject] = useState();
    const [contractor, setContractor] = useState();

    const [projectTotals, setProjectTotals] = useState([]);
    const [projectTotal, setProjectTotal] = useState(0);

    const [showSectionDetail, setShowSectionDetail] = useState({});

    const [descEdit, setDescEdit] = useState(false);
    const [desc, setDesc] = useState('');
    const [extraDesc, setExtraDesc] = useState('');
    const [extra, setExtra] = useState(0);

    const [published, setPublished] = useState(false);
    const [addingSection, setAddingSection] = useState(false);
    const [notFoundError, setNotFoundError] = useState(false);

    useEffect(() => {
        if(!location.state?.sID){
            navigate("/contractor-createproject-owner-sow/" + projectUUID);
        }
    }, [location])

    useEffect(() => {
        setProjectTotal(Object.values(projectTotals).reduce((a, b) => a + b, 0));
    }, [projectTotals])

    useEffect(() => {
        //axios get contract
        axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectcontract/', {
            params: { project_uuid: projectUUID },
        })
        .then((response) => {
            console.log(response);

            if(response.data.length && response.data[0].contractor_signed_contract){
                setPublished(true);
            }
        })
        .catch((error) => {
            console.log(error);
        });

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/get-contractor-project/",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                "project_uuid": projectUUID,
            }
        })
        .then(response => {
            console.log(response);
            setProject(response.data);
            setDesc(response.data.desc);
            setExtra(response.data.extra);
            setExtraDesc(response.data.extra_desc);

            let sectionIds = {};
            Object.keys(response.data.sections).map(sID => {
                sectionIds[String(sID)] = false;
            })
            // sectionIds[String(Object.values(response.data.sections).sort((a, b) => a.order - b.order)[0].id)] = true;
            setShowSectionDetail(sectionIds);
        })
        .catch(error => {
            console.log(error);
        })
    }, [projectUUID]);

    useEffect(() => {
        setProjectTotals(prevTotals => ({...prevTotals, ['extra']: +extra}));
    }, [extra])

    const handleSaveDesc = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorproject/" + project.id + '/',
            data: {
                generalcontractor: contractor.id,
                address: project.address,
                published: project.published,
                desc: desc,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setDescEdit(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleAddSection = () => {
        setAddingSection(true);

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsection/",
            data: {
                choice: "Living room",
                group: "Living spaces",
                verb: "Remodel",
                desc: "",
                order: Object.keys(project.sections) ? Object.values(project.sections).map((section) => section.order).reduce((a, b) => Math.max(a, b), 0) + 1 : 0,
                contractorproject: project.id 
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            console.log(response);

            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractor-project-section-change/",
                data: {
                    "sectionID": response.data.id,
                    "roomGroup": 'Living spaces',
                    "roomChoice": 'Living room',
                    "roomVerb": "Remodel",
                },
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(updateResponse => {
                console.log(updateResponse);
                setProject(prev => {
                    let temp = {...prev};
                    temp.sections[updateResponse.data.id] = updateResponse.data;
                    return {...temp};
                })
    
                setShowSectionDetail(prev => ({...prev, [updateResponse.data.id]: true}));
                setAddingSection(false);
            })
            .catch(error => {
                console.log(error);
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if(notFoundError){
        return (
            <NotFoundPage />
        )
    }

    return (
        <div className="contractorbid-container">
            <div className="contractorbid-header">
                <div>
                    <div className="contractorbid-title">Section Document</div>
                </div>
                <div>
                    <div>
                        <button className="default-button contractorbid-back-button">
                            <a className="contractorbid-back-link" href={"/contractor-createproject-owner-view/" + projectUUID}>Back</a>
                        </button>
                    </div>
                </div>
            </div>

            {(project && !Object.values(project.sections).find(s => s.id === sID)) && <Navigate to={"/contractor-createproject-owner-sow/" + projectUUID} />} 
            <div className="editprojectdetail-sections">
            {project?.sections && Object.values(project?.sections)?.filter(s => s.id === sID).map(s => 
                    <ProjectSection 
                        key={s.id} 
                        s={s}
                        contractor={contractor}
                        setProjectTotals={setProjectTotals} 
                        published={published}
                        project={project}
                        setProject={setProject}
                        showSectionDetail={showSectionDetail}
                        setShowSectionDetail={setShowSectionDetail}
                    />
                )}
            </div>

            <br/>
            <br/>

            <TextEditor s={sID}/>

        </div>
    )
}

const TextEditor = ({s}) => {
    const [editorHtml, setEditorHtml] = useState('');
    const quillRef = useRef(null);
    const [prevSave, setPrevSave] = useState('');

    //document id
    const [id, setId] = useState();
  
    const [comments, setComments] = useState([]);
    const [commentIdList, setCommentIdList] = useState([0]);

    //array of comments that are shown
    const [showComments, setShowComments] = useState([]);

    useEffect(() => {
        const editor = quillRef.current.getEditor();
        let list = editor.root.querySelectorAll('[commentid]');

        for(let i = 0; i < list.length; i++){
            const listener = () => {
                if(comments.filter(comment => comment.commentId === +list[i].getAttribute('commentid'))[0]){
                    setShowComments(prevShowComments => ({...prevShowComments, [list[i].getAttribute('commentid')]: comments.filter(comment => comment.commentId === +list[i].getAttribute('commentid'))[0]}));
                }
                
        }
        list[i].onclick = listener;
        }
    }, [comments, showComments])
            
    //fetch all comments
    useEffect(() => {
        axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcomment/', {
            params: { contractorprojectsectiondocument: id },
        })
        .then((response) => {
            console.log(response);
            setComments(response.data);
            setCommentIdList([0, ...response.data.map(comment => comment.commentId)]);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [id])

    useEffect(() => {
        axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocument/', {
            params: { contractorprojectsection: s},
        })
        .then((response) => {
            if(response.data.length === 0){
                axios({
                    method: "post",
                    url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocument/",
                    data: {
                        "htmlContent": "",
                        "contractorprojectsection": s,
                    },
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then(response => {
                    console.log("created new doc", response);
                    setEditorHtml(response.data.htmlContent);
                    setPrevSave(response.data.htmlContent);
                    setId(response.data.id);

                })
                .catch(error => {
                    console.log(error);
                })
            }
            setId(response.data[0].id);
            const editor = quillRef.current.getEditor();
            editor.clipboard.dangerouslyPasteHTML(response.data[0].htmlContent);
            
            setPrevSave(response.data[0].htmlContent);
            
        })
        .catch((error) => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
          if(prevSave !== editorHtml)
            handleSaveHtml(id, editorHtml);
        }, 5000); // Auto-save every 5 seconds after writing nothing
    
        return () => {
          clearInterval(intervalId);
        };
      }, [id, editorHtml, prevSave]);

      //save when adding a comment
      useEffect(() => {      
        handleSaveHtml(id, editorHtml);
      }, [comments]);

    const handleSaveHtml = (id, editorHtml) => {
        if(id){
            axios({
                method: "put",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocument/" + id + "/",
                data: {
                    "htmlContent": editorHtml,
                    "contractorprojectsection": s,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => {
                console.log(response);
                setPrevSave(editorHtml);
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const Inline = Quill.import('blots/inline');
    class CommentTextBlot extends Inline {
        static blotName = 'comment-blot';
        static tagName = 'span';    
        //classname prevents quill from applying the format to all spans on load in 
        static className = 'comment-blot-text'; 

        static create(value) {
            if (!value) return super.create(false);
            if(!value.uniqueId) return super.create(false);
            const node = super.create(value);
            IdAttr.add(node, value.uniqueId)
            EditAttr.add(node, 'false')
            return node;
        }
        
        formats() {
            let formats = super.formats();
    
            formats['comment-blot'] = CommentTextBlot.formats(this.domNode);

            return formats;
        }
    }
    Quill.register(CommentTextBlot, true);

    // const Tooltip = Quill.import('ui/tooltip');

 
    const insertComment = () => {
        const editor = quillRef.current.getEditor();
        const unpriveligedEditor = quillRef.current.makeUnprivilegedEditor(editor);

        console.log(unpriveligedEditor.getSelection());
        if(unpriveligedEditor.getSelection() && unpriveligedEditor.getSelection().length > 0){
            const uniqueId = Math.max(...commentIdList) + 1;
            console.log(commentIdList)
            console.log(uniqueId);
            editor.formatText(unpriveligedEditor.getSelection(), 'comment-blot', {uniqueId: uniqueId});
            
            setCommentIdList(prevCommentIdList => [...prevCommentIdList, uniqueId]);
            handleAddComment(uniqueId);
        }
    }

    const handleAddComment = (uniqueId) => {
        if(uniqueId){
            axios({
                method: "post",
                url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcomment/",
                data: {
                    "commentId": uniqueId,
                    "contractorprojectsectiondocument": id,
                },
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => {
                console.log(response);
                //update comments so that it adds the new comment
                setComments(prevComments => [...prevComments, response.data]);
                //update showComments so that it adds the new comment
                setShowComments(prevShowComments => ({...prevShowComments, [response.data.commentId]: response.data}));
            }
            ).catch(error => {
                console.log(error);
            })
        }
    }

    const handleDeleteComment = (cid, uniqueId) => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcomment/" + cid + "/",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(response => {
            console.log(response);
            setComments(prevComments => prevComments.filter(comment => comment.id !== cid));
            setCommentIdList(prevCommentIdList => prevCommentIdList.filter(commentId => commentId !== uniqueId));

            //queryselect the element with the unqiueId and remove all attributes
            const editor = quillRef.current.getEditor();
            const unpriveligedEditor = quillRef.current.makeUnprivilegedEditor(editor);
            let list = editor.root.querySelectorAll('[commentid="' + uniqueId + '"]');
            console.log(list);
            for(let i = 0; i < list.length; i++){
                console.log(list[i]);
                list[i].removeAttribute('commentid');
                list[i].removeAttribute('contenteditable');
                list[i].removeAttribute('class');
            }
            //update showComments so that it removes the old comment
            setShowComments(prevShowComments => {
                let temp = {...prevShowComments};
                delete temp[uniqueId];
                return {...temp};
            }
            )
        }
        ).catch(error => {
            console.log(error);
        })
    }
    
    //add comments to showComments that are not currently in showComments or remove all comments from showComments
    const toggleAllComments = () => {
        if(Object.keys(showComments).length === 0){
            //add all comments to showComments
            let temp = {};
            comments.map(comment => {
                temp[comment.commentId] = comment;
            })
            setShowComments(temp);
        }else{
            //remove all comments from showComments
            setShowComments({});
        }
    }
    
    return (
        <div>
            {/* {comments.length > 0 && 
                <div>
                    <h2>Comment list</h2>
                    {comments.map(comment =>
                        <div key={comment.commentId}>
                            <DocumentComments
                            handleDeleteComment={handleDeleteComment}
                            setShowComments={setShowComments}
                            c={comment}
                            />
                        </div>)
                </div>
            } */}
            {prevSave !== editorHtml ? <div className="quill-save-hint"><i class="fa-solid fa-arrows-rotate fa-rotate-90"></i> Saving...</div> : <div className="quill-save-hint"><i class="fa-regular fa-circle-check"></i></div>}

            <div 
            onClick={toggleAllComments}
            className="contractordocument-toggle-comment-button"
            >
                <InfoHover3
                    message={`Toggle on and off all comments`}
                >
                    <i class="fa-regular fa-eye"></i>
                </InfoHover3>
            </div>

            <button 
            onClick={() => insertComment()}
            className="contractordocument-insert-comment-button"
            >
                <InfoHover3
                    message={`Add a comment to the currently selected text`}
                >
                    + <i class="fa-regular fa-comment"></i>
                </InfoHover3>
            </button>
            {/* <button className="contractordocument-insert-comment-button" onClick={() => insertComment()}>Insert Comment</button>  */}
            {Object.keys(showComments)?.length > 0 &&
            <div className="contractordocument-comment-list">
                {Object.values(showComments)?.map(comment =>
                    <div className="contractordocument-comment" key={comment.commentId}>
                        <DocumentComments
                        handleDeleteComment={handleDeleteComment}
                        setShowComments={setShowComments}
                        c={comment}
                        />
                    </div>)}
            </div>}

            <div className="quill-container">
                <ReactQuill
                ref={quillRef}
                value={editorHtml}
                onChange={setEditorHtml}
                onBlur={() => handleSaveHtml(id, editorHtml)}
                placeholder="write something..."
                modules={{
                    toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }], 
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { indent: '-1' },
                    { indent: '+1' }],
                    ['bold', 'italic', 'underline'],
                    ['image'],
                    [{ 'align': [] }],
                    ['clean'],
                    ],
                }}
                />
            </div>

      </div>
    );
};

function DocumentComments({ c, handleDeleteComment, setShowComments }){
    const [changed, setChanged] = useState(false);

    const [newComment, setNewComment] = useState('');

    const [commentsComments, setCommentsComments] = useState([]);

    //fetch all commentscomment from backend
    useEffect(() => {
        if(c){
            axios.get('https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/', {
                params: { contractorprojectsectiondocumentcomment: c.id },
            })
            .then((response) => {
                console.log(response);
                setCommentsComments(response.data);
            })
            .catch((error) => {
                console.log(error);
            }
            );
        }
    }, [c])

    const handleAddComment = () => {
        axios({
            method: "post",
            url: " https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/",
            data: {
                contractorprojectsectiondocumentcomment: c.id,
                comment: newComment,
                author: "Contractor"
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setCommentsComments(prevComments => [...prevComments, response.data]);

            setNewComment('');
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div 
            className="contractordocument-section-document-comment-container"
        >
            <div className="contractorsow-section-comment-header">
                <b>Text Comments</b> 
                {/*set show comments to remove this comment from the list*/}
                <div className="delete-x"><small>
                    <i 
                        class="fa-solid fa-trash"
                        onClick={() => handleDeleteComment(c.id, c.commentId)}
                    ></i>
                </small></div>
            
                <div className="delete-x" onClick={() => {
                    //remove the key with this commentId from showComments
                    setShowComments(prevShowComments => {
                        let temp = {...prevShowComments};
                        delete temp[c.commentId];
                        return {...temp};
                    }
                    )
                }}>X</div>
            </div>

            {Object.values(commentsComments)?.length === 0 &&
                <div>
                    No comments yet.
                </div>
            }

            <div> 
                {Object.values(commentsComments)?.sort((a, b) => a.id - b.id).map(comment =>
                    <DocumentCommentsComment
                        key={comment.id}
                        comment={comment}
                        setCommentsComments={setCommentsComments}
                    />
                    // <div>
                    // <p key={comment.id}>hello, my id is {comment.id}</p>
                    // {/* <p>{comment.comment}</p>
                    // <span>my c.id is {c.id}</span> */}
                    // </div>
                )}
            </div>

            <div className="contractorbidtrade-trade-comment-add">
                Add a comment
            </div>

            <TextareaAutosize
                minRows={1}
                maxRows={5}
                value={newComment}
                onChange={(e) => {
                    setNewComment(e.target.value);

                    setChanged(true);
                }}
                onBlur={() => {
                    if (changed && newComment) {
                        handleAddComment();
                    }
                }}
                className="contractorbidtrade-trade-comment-input"
                placeholder="Comment for this text..."
            />
        </div>
    )
}

function DocumentCommentsComment({ comment, setCommentsComments }){
    const [editing, setEditing] = useState(false);
    const [changed, setChanged] = useState(false);
    const [newComment, setNewComment] = useState(comment.comment);
    
    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/" + comment.id + "/",
            data: {
                comment: newComment,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setNewComment(response.data.comment);

            setEditing(false);
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectiondocumentcommentscomment/" + comment.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);
            const cid = comment.id;
            setCommentsComments(prevComments => prevComments.filter(comment => comment.id !== cid));
            
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (comment.author === "Contractor"){
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Contractor</b></div>

                    <div className="contractorbidtrade-trade-comment-header">
                        <div><small>{comment?.date_edited?.split('T')[0]}</small></div>

                        &nbsp;

                        {/* <InfoHover3
                            message={"Delete comment"}
                        > */}
                        <div className="delete-x"><small>
                            <i 
                                class="fa-solid fa-trash"
                                onClick={() => handleDelete()}
                            ></i>
                        </small></div>
                        {/* </InfoHover3> */}
                    </div>
                </div>
                
                <div>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={newComment}
                        onChange={(e) => {
                            setNewComment(e.target.value);

                            setChanged(true);
                        }}
                        onBlur={() => {
                            handleSave();
                        }}
                        className="contractorbidtrade-trade-comment-input"
                        placeholder="Leave a comment for this section"
                    />
                </div>
            </div>
        )
    }else {
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Homeowner</b></div>
                    <div><small>{comment?.date_edited?.split('T')[0]}</small></div>
                </div>

                <div>{comment.comment}</div>
            </div>
        )
    }    
}

function ProjectSection({ s, setProjectTotals, published, project, setProject, showSectionDetail, setShowSectionDetail }){
    const [sectionTotal, setSectionTotal] = useState(0);
    const [subTotals, setSubTotals] = useState({});
    const [showDetails, setShowDetails] = useState({});

    const [showComment, setShowComment] = useState(false);

    const [sectionDesc, setSectionDesc] = useState(s.desc);
    const [descEdit, setDescEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const [toDoc, setToDoc] = useState(false);

    useEffect(() => {
        if(s?.subsections){
            let tempShowDetails = {};
            Object.values(s.subsections)?.map(sub => {
                tempShowDetails[sub.id] = false;
            })
            setShowDetails(tempShowDetails);
        }
    }, [s])

    useEffect(() => {
        setProjectTotals(prevTotals => ({...prevTotals, [s.id]: sectionTotal}));
    }, [sectionTotal])

    useEffect(() => {
        setSectionTotal(Object.values(subTotals).reduce((a, b) => a + b, 0));
    }, [subTotals])

    return (
        <div className="editprojectdetail-section">
            <div 
                className="contractorsow-section-header"
                id={'nav-section-' + s.id}
                onClick={() => {
                    if(showSectionDetail[s.id]){
                        setShowComment(false);
                    }
                    setShowSectionDetail(prev => ({...prev, [s.id]: !prev[s.id]}));
                }}
            >
                {/* <div
                className="contractorsow-section-document-icon"
                onClick={() => {
                    setToDoc(true)
                }}>
                    <InfoHover3
                        message={`View document for ${s.choice} (${s.sectionID})`}
                    >
                        <i class="fa-regular fa-file-lines"></i>
                    </InfoHover3>       
                </div>

                {toDoc && <Navigate to={"/contractor-document/" + project.uuid} 
                state={{
                    sID: s.id,
                }}/>} */}

                <div className="contractorownersow-subsection-expand">
                    <div>
                        {showSectionDetail[s.id]
                        ?   <i className="fa-solid fa-caret-down"></i>
                        :   <i className="fa-solid fa-caret-right"></i>
                        }
                    </div>
                    <div>
                        {s.group} - {s.choice} ({s.sectionID}) - {s.verb}
                    </div>
                </div>



                <SectionComments
                    s={s}
                    setProject={setProject}
                    showSectionDetail={showSectionDetail}
                    setShowSectionDetail={setShowSectionDetail}
                    showComment={showComment}
                    setShowComment={setShowComment}
                />
            </div>
            
            {showSectionDetail[s?.id] && 
            <div className="editprojectdetail-section-body-container">
                <div>
                    <div>
                        <div><b>Description</b></div>
                        {/*descEdit && <button onClick={() => handleSaveDesc()} className="active-button-small">Save</button>*/}
                    </div>
                    
                    <TextareaAutosize
                        maxRows={3}
                        value={sectionDesc} 
                        className="editprojectdetail-section-desc"
                        placeholder={"Example: kitchen for main unit, needs an eletrical rework and a new island."}
                        disabled
                    />
                </div>

                <div className="contractorsow-subsections-header">
                    <div className="editprojectdetail-subsections-title" id={`nav-sow-${s.id}`}>
                    </div>
                    <div className="contractorbid-section-collapseall">
                        {Object.values(showDetails).includes(false)  && <div 
                            onClick={() => {
                                let tempShowDetails = {};
                                Object.values(s.subsections)?.map(sub => {
                                    tempShowDetails[sub.id] = true;
                                })
                                setShowDetails(tempShowDetails);
                            }}
                            className="contractorbid-section-collapseall-expand"
                        >
                            +
                        </div>}
                        
                        {Object.values(showDetails).includes(true) && <div 
                            onClick={() => {
                                let tempShowDetails = {};
                                Object.values(s.subsections)?.map(sub => {
                                    tempShowDetails[sub.id] = false;
                                })
                                setShowDetails(tempShowDetails);
                            }}
                            className="contractorbid-section-collapseall-collapse"
                        >
                            -
                        </div>}
                    </div>
                </div>

                {loading
                ?   <div className="editproject-sowgeneration-spinner-container">
                        <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                        Loading...
                    </div>
                :   (s?.subsections && Object.values(s?.subsections).length > 0) 
                    ?   <div className="contractorsow-subsections">
                            {Object.values(s?.subsections)?.sort((a, b) => a.order - b.order).map(sub => 
                                <Subsection 
                                    key={sub.id} 
                                    sub={sub} 
                                    s={s}
                                    published={published}
                                    setSubTotals={setSubTotals}
                                    showDetails={showDetails}
                                    setShowDetails={setShowDetails}
                                    project={project}
                                    setProject={setProject}
                                />
                            )}
                        </div>
                    :   <div style={{ color: "gray"}}>No subsections added.</div>
                }
            </div>}
        </div>
    );
}

function SectionComments({ s, setProject, showSectionDetail, setShowSectionDetail, showComment, setShowComment }){
    const [changed, setChanged] = useState(false);

    const [newComment, setNewComment] = useState('');

    const handleAddComment = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectioncomment/",
            data: {
                contractorprojectsection: s.id,
                comment: newComment,
                author: "Homeowner"
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].comments[response.data.id] = response.data;
                return temp;
            })

            setNewComment('');
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (!showComment) {
        return (
            <div 
                className="contractorsow-section-comment-icon"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowSectionDetail(prev => ({...prev, [s.id]: true}));
                    setShowComment(true);
                }}
            >   
                {Object.values(s.comments)?.length > 0 
                ?   <>{Object.values(s.comments)?.length} <i class="fa-solid fa-comment"></i></>
                :   <>+ <i class="fa-regular fa-comment"></i></>
                }
            </div>
        )
    }

    return (
        <div 
            className="contractorbidtrade-section-comment-container"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="contractorsow-section-comment-header">
                <b>Section comments</b> <div className="delete-x" onClick={() => setShowComment(false)}>X</div>
            </div>
            <div>
                {s.choice} ({s.sectionID})
            </div>

            {Object.values(s.comments)?.length === 0 &&
                <div>
                    No comments yet.
                </div>
            }

            <div>
                {Object.values(s.comments)?.sort((a, b) => a.id - b.id).map(comment =>
                    <SectionComment
                        key={comment.id}
                        comment={comment}
                        s={s}
                        setProject={setProject}
                    />
                )}
            </div>

            <div className="contractorbidtrade-trade-comment-add">
                Add a comment
            </div>

            <TextareaAutosize
                minRows={1}
                maxRows={5}
                value={newComment}
                onChange={(e) => {
                    setNewComment(e.target.value);

                    setChanged(true);
                }}
                onBlur={() => {
                    if (changed && newComment) {
                        handleAddComment();
                    }
                }}
                className="contractorbidtrade-trade-comment-input"
                placeholder="Comment for this section..."
            />
        </div>
    )
}

function SectionComment({ comment, s, setProject }){
    const [editing, setEditing] = useState(false);
    const [changed, setChanged] = useState(false);
    const [newComment, setNewComment] = useState(comment.comment);
    
    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectioncomment/" + comment.id + "/",
            data: {
                comment: newComment,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].comments[comment.id] = response.data;
                return temp;
            })

            setEditing(false);
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsectioncomment/" + comment.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id].comments[comment.id];
                return temp;
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (comment.author === "Homeowner"){
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Homeowner</b></div>

                    <div className="contractorbidtrade-trade-comment-header">
                        <div><small>{comment?.date_edited?.split('T')[0]}</small></div>

                        &nbsp;

                        <div className="delete-x"><small>
                            <i 
                                class="fa-solid fa-trash"
                                onClick={() => handleDelete()}
                            ></i>
                        </small></div>
                    </div>
                </div>
                
                <div>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={newComment}
                        onChange={(e) => {
                            setNewComment(e.target.value);

                            setChanged(true);
                        }}
                        onBlur={() => {
                            handleSave();
                        }}
                        className="contractorbidtrade-trade-comment-input"
                        placeholder="Leave a comment for this section"
                    />
                </div>
            </div>
        )
    }else {
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Contractor</b></div>
                    <div><small>{comment?.date_edited?.split('T')[0]}</small></div>
                </div>

                <div>{comment.comment}</div>
            </div>
        )
    }    
}

function Subsection({ sub, s, setSubTotals, published, setShowDetails, showDetails, project, setProject }){
    const [subsection, setSubsection] = useState(sub);
    const [bid, setBid] = useState(sub);
    const [edit, setEdit] = useState(false);
    const [otherChoice, setOtherChoice] = useState(sub.choice);
    const [otherChoiceEdit, setOtherChoiceEdit] = useState(false);
    const [generatingSOW, setGeneratingSOW] = useState(false);

    const [showComment, setShowComment] = useState(false);

    useEffect(() => {
        setSubTotals(prev => ({...prev, [sub.id]:(+bid.materials + +bid.installation_materials + +bid.installation_price)}));
    }, [bid])

    return (
        <div className="contractorbid-subsection-container">            
            <div 
                className="contractorsow-subsection-header" 
                onClick={() => {
                    if(showDetails[sub.id]){
                        setShowComment(false);
                    }
                    setShowDetails(prev => ({...prev, [sub.id]:!showDetails[sub.id]}))
                }} 
                id={`nav-subsection-${sub.id}`}
            >
                <div className="contractorownersow-subsection-expand">
                    <div>
                        {showDetails[sub.id]
                        ?   <i className="fa-solid fa-caret-down"></i>
                        :   <i className="fa-solid fa-caret-right"></i>
                        }
                    </div>
                    <div>
                        {sub.group} - {sub.choice} - {sub.verb}
                    </div>
                </div>

                <SubComments
                    sub={sub}
                    s={s}
                    setProject={setProject}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                />
            </div>

            {showDetails[sub.id] && 
                <div className="contractorsow-subsection-body-container">
                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 0 &&
                        <div>
                            <div>Description</div>
                            <TextareaAutosize
                                minRows={2}
                                maxRows={3}
                                className="contractorsow-subsection-desc"
                                placeholder="Ex: 20 sqft, yellow paint, etc..."
                                value={bid?.quantity}
                                disabled
                            />
                        </div>
                    }

                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 1 && 
                        <div className="contractorbid-subsection-top-container">
                            <div>
                                <div>Description</div>
                                <TextareaAutosize
                                    minRows={2}
                                    maxRows={3}
                                    className="contractorsow-subsection-desc"
                                    placeholder="Ex: 20 sqft, yellow paint, etc..."
                                    value={bid?.quantity}
                                    disabled
                                />
                            </div>
                            
                            {generatingSOW 
                            ?   <div className="editproject-sowgeneration-spinner-container">
                                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                    Loading...
                                </div>
                            :   <>
                                    {project?.items && 
                                        <div>
                                            <div>Details</div>
                                            <div className="projectsubsection-items-conatiner">
                                                {sub?.items
                                                    && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                        <SOWItem
                                                            key={i.id}
                                                            item={i}
                                                            sub={sub}
                                                            s={s}
                                                            published={published}
                                                            setProject={setProject}
                                                        />
                                                    )
                                                }
                                            </div>   
                                        </div>
                                    }

                                    {project?.owner_mats &&
                                        <div>
                                            Owner materials  (exclusions)
                                            
                                            <div className="projectsubsection-items-conatiner">
                                                {sub?.owner_materials && Object.values(sub?.owner_materials).length
                                                ?   Object.values(sub?.owner_materials)?.map(m =>
                                                        <OnwerMaterial
                                                            key={m.id}
                                                            material={m}
                                                            published={published}
                                                            sectionId={s.id}
                                                            subId={sub.id}
                                                            setSubsection={setSubsection}
                                                            setProject={setProject}
                                                            showSwitch={project.contractor_mats}
                                                        />
                                                    )
                                                :   <div>No materials</div>}
                                            </div>
                                        </div>
                                    }

                                    {project?.contractor_mats &&
                                        <div>
                                            Contractor materials
                            
                                            <div className="projectsubsection-items-conatiner">
                                                {sub?.contractor_materials && Object.values(sub?.contractor_materials).length 
                                                ?   Object.values(sub?.contractor_materials)?.map(m =>
                                                        <ContractorMaterial
                                                            key={m.id}
                                                            material={m}
                                                            published={published}
                                                            subsection={sub}
                                                            setSubsection={setSubsection}
                                                            sectionId={s.id}
                                                            subId={sub.id}
                                                            setProject={setProject}
                                                            showSwitch={project.owner_mats}
                                                        />
                                                    )
                                                :   <div>No materials</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>                                
                            }
                        </div>
                    }

                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 2 &&
                        <>
                            <div>
                                <div>Description</div>
                                <TextareaAutosize
                                    minRows={2}
                                    maxRows={3}
                                    className="contractorsow-subsection-desc"
                                    placeholder="Ex: 20 sqft, yellow paint, etc..."
                                    value={bid?.quantity}
                                    disabled
                                />
                            </div>
                            <div className="contractorbid-subsection-top-container">
                                {project?.items && 
                                    <div>
                                        <div>Details</div>
                                        <div className="projectsubsection-items-conatiner">
                                            {sub?.items
                                                && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                    <SOWItem
                                                        key={i.id}
                                                        item={i}
                                                        sub={sub}
                                                        s={s}
                                                        published={published}
                                                        setProject={setProject}
                                                    />
                                                )
                                            }
                                        </div>   
                                    </div>
                                }

                                {project?.owner_mats &&
                                    <div>
                                        Owner materials (exclusions)
                                        
                                        <div className="projectsubsection-items-conatiner">
                                            {sub?.owner_materials && Object.values(sub?.owner_materials).length
                                            ?   Object.values(sub?.owner_materials)?.map(m =>
                                                    <OnwerMaterial
                                                        key={m.id}
                                                        material={m}
                                                        published={published}
                                                        sectionId={s.id}
                                                        subId={sub.id}
                                                        setSubsection={setSubsection}
                                                        setProject={setProject}
                                                        showSwitch={project.contractor_mats}
                                                    />
                                                )
                                            :   <div>No materials</div>}
                                        </div>
                                    </div>
                                }

                                {project?.contractor_mats &&
                                    <div>
                                        Contractor materials
                        
                                        <div className="projectsubsection-items-conatiner">
                                            {sub?.contractor_materials && Object.values(sub?.contractor_materials).length 
                                            ?   Object.values(sub?.contractor_materials)?.map(m =>
                                                    <ContractorMaterial
                                                        key={m.id}
                                                        material={m}
                                                        published={published}
                                                        subsection={sub}
                                                        setSubsection={setSubsection}
                                                        sectionId={s.id}
                                                        subId={sub.id}
                                                        setProject={setProject}
                                                        showSwitch={project.owner_mats}
                                                    />
                                                )
                                            :   <div>No materials</div>
                                            }
                                        </div>                        
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 3 &&
                        <>
                            <div className="contractorbid-subsection-top-container">
                                <div>
                                    <div>Description</div>
                                    <TextareaAutosize
                                        minRows={2}
                                        maxRows={3}
                                        className="contractorsow-subsection-desc"
                                        placeholder="Ex: 20 sqft, yellow paint, etc..."
                                        value={bid?.quantity}
                                        disabled
                                    />
                                </div>
                                
                                <div>
                                    <div>Details</div>
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.items
                                            && Object.values(sub?.items)?.sort((a, b) => a.order - b.order).map(i =>
                                                <SOWItem
                                                    key={i.id}
                                                    item={i}
                                                    sub={sub}
                                                    s={s}
                                                    published={published}
                                                    setProject={setProject}
                                                />
                                            )
                                        }
                                    </div>   
                                </div>
                            </div>

                            <div className="contractorsow-subsection-materials-container">
                                <div>
                                    Owner materials (exclusions)
                                    
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.owner_materials && Object.values(sub?.owner_materials).length
                                        ?   Object.values(sub?.owner_materials)?.map(m =>
                                                <OnwerMaterial
                                                    key={m.id}
                                                    material={m}
                                                    published={published}
                                                    sectionId={s.id}
                                                    subId={sub.id}
                                                    setSubsection={setSubsection}
                                                    setProject={setProject}
                                                    showSwitch={project.contractor_mats}
                                                />
                                            )
                                        :   <div>No materials</div>}
                                    </div>
                                </div>

                                <div>
                                    Contractor materials
                    
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.contractor_materials && Object.values(sub?.contractor_materials).length 
                                        ?   Object.values(sub?.contractor_materials)?.map(m =>
                                                <ContractorMaterial
                                                    key={m.id}
                                                    material={m}
                                                    published={published}
                                                    subsection={sub}
                                                    setSubsection={setSubsection}
                                                    sectionId={s.id}
                                                    subId={sub.id}
                                                    setProject={setProject}
                                                    showSwitch={project.owner_mats}
                                                />
                                            )
                                        :   <div>No materials</div>
                                        }
                                    </div>                    
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    );
}

function SubComments({ sub, s, setProject, showComment, setShowComment, showDetails, setShowDetails }){
    const [changed, setChanged] = useState(false);

    const [newComment, setNewComment] = useState('');

    const handleAddComment = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsectioncomment/",
            data: {
                contractorprojectsubsection: sub.id,
                comment: newComment,
                author: "Homeowner"
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].subsections[sub.id].comments[response.data.id] = response.data;
                return temp;
            })

            setNewComment('');
            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (!showComment) {
        return (
            <div 
                className="contractorsow-subsection-comment-icon"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowDetails(prev => ({...prev, [sub.id]: true}));
                    setShowComment(true);
                }}
            >
                {Object.values(sub?.comments)?.length 
                ?   <>{Object.values(sub?.comments)?.length} <i class="fa-solid fa-comment"></i></>
                :   <>+ <i class="fa-regular fa-comment"></i></>
                }
            </div>
        )
    }

    return (
        <div 
            className="contractorsow-subsection-comment-container"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="contractorsow-section-comment-header">
                <b>Subsection comments</b> <div className="delete-x" onClick={() => setShowComment(false)}>X</div>
            </div>
            <div>
                {sub?.group} - {sub?.choice} ({sub?.verb})
            </div>

            {Object.values(sub?.comments)?.length === 0 &&
                <div>
                    No comments yet.
                </div>
            }

            <div>
                {Object.values(sub?.comments)?.sort((a, b) => a.id - b.id).map(comment =>
                    <SubtradeComment
                        key={comment.id}
                        comment={comment}
                        sub={sub}
                        s={s}
                        setProject={setProject}
                    />
                )}
            </div>

            <div className="contractorbidtrade-trade-comment-add">
                Add a comment
            </div>

            <TextareaAutosize
                minRows={1}
                maxRows={5}
                value={newComment}
                onChange={(e) => {
                    setNewComment(e.target.value);

                    setChanged(true);
                }}
                onBlur={() => {
                    if (changed && newComment) {
                        handleAddComment();
                    }
                }}
                className="contractorbidtrade-trade-comment-input"
                placeholder="Comment for this trade..."
            />
        </div>
    )
}

function SubtradeComment({ comment, sub, s, setProject }){
    const [changed, setChanged] = useState(false);

    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsectioncomment/" + comment.id + "/",
            data: {
                comment: comment.comment,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                temp.sections[s.id].subsections[sub.id].comments[comment.id] = response.data;
                return temp;
            })

            setChanged(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "delete",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectsubsectioncomment/" + comment.id + "/",
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) =>  {
            console.log(response);

            setProject(prev => {
                let temp = {...prev};
                delete temp.sections[s.id].subsections[sub.id].comments[comment.id];
                return temp;
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    if (comment.author === "Homeowner"){
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Homeowner</b></div>

                    <div className="contractorbidtrade-trade-comment-header">
                        <div><small>{comment?.date_edited?.split('T')[0]}</small></div>

                        &nbsp;

                        <div className="delete-x"><small>
                            <i 
                                class="fa-solid fa-trash"
                                onClick={() => handleDelete()}
                            ></i>
                        </small></div>
                    </div>
                </div>

                <div>
                    <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={comment.comment}
                        onChange={(e) => {
                            setProject(prev => {
                                let temp = {...prev};
                                temp.sections[s.id].subsections[sub.id].comments[comment.id].comment = e.target.value;
                                return temp;
                            })

                            setChanged(true);
                        }}
                        onBlur={() => {
                            if (changed){
                                handleSave();
                            }
                        }}
                        className="contractorbidtrade-trade-comment-input"
                        placeholder="Leave a comment for this subsection"
                    />
                </div>
            </div>
        )
    }else {
        return (
            <div className="contractorbidtrade-trade-comment">
                <div className="contractorbidtrade-trade-comment-header">
                    <div><b>Contractor</b></div>
                    <div><small>{comment?.date_edited?.split('T')[0]}</small></div>
                </div>

                <div>{comment.comment}</div>
            </div>
        )
    }
}

function ContractorMaterial({ material, published, subsection, setSubsection, sectionId, subId, setProject }){
    const [itemDesc, setItemDesc] = useState(material.desc || "");

    return (
        <div className="editprojectdetail-item">
            <input
                value={itemDesc}
                className="editprojectdetail-item-desc"
                disabled
            >
            </input>
        </div>
    )
}

function OnwerMaterial({ material, published, setSubsection, sectionId, subId, setProject }){
    const [changed, setChanged] = useState(false);
    const [itemDesc, setItemDesc] = useState(material.desc || "");

    return (
        <div className="editprojectdetail-item">
            <input
                value={itemDesc}
                className="editprojectdetail-item-desc"
                disabled
            >
            </input>
        </div>
    )
}

const SOWItem = ({ item, sub, s, setProject, published }) => {
    const [itemDesc, setItemDesc] = useState(item.desc);

    return (
        <div className="editprojectdetail-item">
            <input
                value={item.desc}
                disabled
                className="editprojectdetail-item-desc"
            >
            </input>
        </div>
    )
}

const SideNav = ({ sections }) => {
    const [activeItem, setActiveItem] = useState(null);
    const observer = useRef(null);
  
    //not working!!! the sections are too short lol
    const findActiveSection = () => {
        const sections = document.querySelectorAll('[id^="section-"]');
        let activeSection = null;
        let viewportTop = 100;
        let viewportBottom = viewportTop + 300;
    
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            const top = rect.top;
            const bottom = rect.bottom;
    
            if (top <= viewportBottom && bottom >= viewportBottom || top <= viewportTop && bottom >= viewportTop) {
                console.log(section.id)
                activeSection = section;
                return;
            }
        });
    
        return activeSection;
    };

    useEffect(() => {
        if(sections){
            observer.current = () => {
                const activeSection = findActiveSection();
                setActiveItem(activeSection ? activeSection.id : null);
            };

            window.addEventListener('scroll', observer.current);
            return () => window.removeEventListener('scroll', observer.current);
        }
    }, [sections]);
    
    return (
        <div className="sidenav-container">
            <ul>      
                {Object.values(sections)?.map(s => 
                    <li key={s.id}><div>
                        <li><div
                            onClick={(e) => {
                                e.preventDefault();
                                const element = document.querySelector(`#nav-section-${s.id}`);
                                const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                                const yOffset = -200;
                                window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
                            }}
                            className={activeItem === `nav-section-${s.id}` ? 'nav-item-active' : 'nav-item'}
                        >
                            {s.choice}
                        </div></li>
                    </div></li>
                )}
            </ul>
        </div>
    )
}