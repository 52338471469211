import axios from 'axios';
import { pdfjs } from "react-pdf"
import emailjs from '@emailjs/browser';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewContract({ showContract, setShowContract, contractPreview, contract, user, loading, project, preSigFile }){
    const handleSendRequest = async () => {
        const pdf = await pdfjs.getDocument({ url: contractPreview }).promise;
        const numPages = pdf.numPages;
        let signaturePageNumber = null;

        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map((item) => item.str).join(" ");
            if (pageText.includes("IN WITNESS WHEREOF")) {
                signaturePageNumber = pageNumber;
                break;
            }
        }

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/contractorprojectcontract/" + contract.id + "/",
            data: {
                status: true,
                project: contract.project,
                pre_signature_contract: preSigFile,
                sign_page: signaturePageNumber,
            },
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
        .then(response => {
            console.log(response);

            window.location.href = '/contractor-createdproject-sign/' + project.uuid;
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <Modal show={showContract} onHide={() => setShowContract(false)} className="viewcontract-modal">
            <Modal.Header closeButton className='viewcontract-header'>
                <Modal.Title className='viewcontract-title'>Preview contract</Modal.Title>
                {(!user?.groups?.includes(3) && contract?.contractor_name && contract?.client_name && contract?.contractor_email && contract?.client_email) && 
                    <button onClick={() => handleSendRequest()} className='default-button'><nobr>Sign contract</nobr></button>
                }
            </Modal.Header>

            <Modal.Body>
                {loading 
                ?   <div className="editproject-sowgeneration-spinner-container">
                        <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                        Loading...
                    </div>
                :   <iframe src={contractPreview} type="application/pdf" className='viewcontract-file'/>
                }
            </Modal.Body>
        </Modal>
    )
}