import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import emailjs from '@emailjs/browser';
import axios from 'axios';

import "../../styles/ContractorProjectChangeOrderBid.css";

import NotFoundPage from "../../components/NotFoundPage";
import InfoHover from "../../components/InfoHover";
import InfoHover2 from "../../components/InfoHover2";
import { DivisionsOrder } from "../../data/DivisionsOrder";
import { SOW } from "../../data/SOW";
import InfoHover3 from "../../components/InfoHover3";

export default function ContractorChangeOrderBid({ user }){
    const params = useParams();
    const projectUUID = params.projectUUID;
    const versionID = params.versionID;

    const [project, setProject] = useState();
    const [trades, setTrades] = useState({});
    const [current, setCurrent] = useState();
    const [payments, setPayments] = useState({});

    const [filter, setFilter] = useState(false);
    const [final, setFinal] = useState(false);

    const [extraDesc, setExtraDesc] = useState('');
    const [extra, setExtra] = useState(0.0);
    const [extraChanged, setExtraChanged] = useState(false);
    
    const [loading, setLoading] = useState(true);

    const [showTrades, setShowTrades] = useState({});

    const [notFoundError, setNotFoundError] = useState(false);

    useEffect(() => {
        if(user?.groups.includes(1)){
            setNotFoundError(true);        
        }
    }, [user])

    useEffect(() => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/get-project-contractor-version-bid/",
            data: {
                projectUUID: projectUUID,
                versionID: versionID,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            console.log(response);

            setProject(response.data.project);
            setCurrent(response.data.current);
            setExtraDesc(response.data.current.extra_desc);
            setExtra(response.data.current.extra);
            setTrades(response.data.current.trades);
            setPayments(response.data.payments);

            let tempShowTrades = {};
            Object.values(DivisionsOrder)?.map(k => {
                tempShowTrades[k] = false;
            })

            tempShowTrades[Object.keys(response.data.current.trades).sort((a, b) => DivisionsOrder[a] - DivisionsOrder[b])[0]] = true;
            setShowTrades(tempShowTrades);

            setLoading(false);

            if(response.data.current.request){
                setFinal(true);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }, [projectUUID]);

    useEffect(() => {
        if(project && trades && current){
            let total = (+Object.values(trades)?.reduce((acc, trade) => acc + 
                +(trade?.by_trade 
                ?   +(trade?.price 
                    ?   trade?.price
                    :   (trade?.cost
                        ?   trade?.cost * (1 + trade?.markup / 100)
                        :   0)
                    )
                :   +Object.values(trade?.sections)?.filter(section => !section.deleted)?.reduce((acc, section) => acc +
                    +(Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.reduce((acc, sub) => acc +
                        +(sub?.total 
                        ? sub?.total 
                        :   ((sub?.unit || sub?.measured) && sub?.cost_per
                            ?   (sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100)
                            :   0)
                        ), 0)
                    ), 0)
                ), 0) + +extra).toFixed(2);

            if(parseFloat(total) !== parseFloat(current.total)){
                axios({
                    method: "put",
                    url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversion/" + current.id + '/',
                    data: {
                        total: parseFloat(total),
                        project: current.project,
                        contractor: current.contractor,
                    },
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                .then(response => {
                    console.log(response);

                    setCurrent(prev => {
                        prev.total = parseFloat(total);
                        return {...prev};
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            }
        }
    }, [trades, extra, project, current])

    const saveExtra = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversion/" + current.id + '/',
            data: {
                extra_desc: extraDesc,
                extra: extra ? extra : 0,
                project: current.project,
                contractor: current.contractor,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
            setExtraChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleAddPayment = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionpayment/",
            data: {
                project_version: current.id,
                desc: '',
                amount: 0,
                added: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setPayments(prev => {
                prev[response.data.id] = response.data;
                return {...prev};
            })
        })
    }
    
    if(notFoundError){
        return (
            <NotFoundPage />
        )
    }

    return (
        <div className="contractorbid-container">
            <SideNav trades={trades} setShowTrades={setShowTrades} showTrades={showTrades}/>
            <InfoHover message={"The owner is responsible for everything explicitly listend in the owner materials column and the contractor is responsible for everything else, including items not listed in either column."} />
            <div className="contractorbid-header">
                <div>
                    <div className="contractorbid-title">Price change order</div>
                    <span className="contractorbid-address">{project?.address}</span>
                </div>
                <button className="default-button contractorbid-back-button">
                    <a className="contractorbid-back-link" href={"/contractor-createdproject-change-orders/" + projectUUID}>Back</a>
                </button>
            </div>

            <div className="contractorprojectchangeorder-options-container">
                {current?.request
                ?   <div>
                        This page is view only because you have requested approval for this change order.
                    </div>
                :   <>
                        <InfoHover3
                            message={final ? `You are viewing the final bid with all your changes. Switch to edit mode to make more changes.` : `View the final SOW with all your changes enacated.`}
                        >
                            <div>
                                <button
                                    className="default-button"
                                    onClick={() => setFinal(!final)}
                                >
                                    {final ? "Edit change order" : "View final"}
                                </button>                        
                            </div>
                        </InfoHover3>

                        {!final && 
                            <InfoHover3
                                message={filter ? `You are viewing only the trades and sections that have been changed. Switch to view all items.` : `View only the trades and sections that have been changed.`}
                            >
                                <div>
                                    <button
                                        className="default-button"
                                        onClick={() => setFilter(!filter)}
                                    >
                                        {filter ? "Filter changes: On" : "Filter changes: Off"}
                                    </button>
                                </div>
                            </InfoHover3>
                        }

                    </>
                }

                <InfoHover3
                    message={"Make changes to the SOW."}
                >
                    <div>
                        <a href={`/contractor-createdproject-change-order/${projectUUID}/version/${versionID}`}> 
                        <button
                            className="default-button"
                        >
                            {current?.request ? "View SOW" : "Change SOW"}
                        </button>
                        </a>
                    </div>
                </InfoHover3>
            </div>

            {loading &&
                <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...This may take a few seconds if there are many items and this is your first time viewing this page.
                </div>  
            }

            <div>
                {final 
                ?   <>
                        {trades && Object.values(trades)?.filter(trade =>
                            Object.values(trade?.sections)?.filter(section =>
                                Object.values(section?.subsections)?.filter(sub =>
                                    !sub.deleted
                                ).length > 0
                            ).length > 0
                        ).sort((a, b) => DivisionsOrder[a.trade] - DivisionsOrder[b.trade]).map(trade =>
                            <Trade
                                key={trade.trade}
                                trade={trade.trade}
                                setTrades={setTrades}
                                trades={trades}
                                setShowTrades={setShowTrades}
                                showTrades={showTrades}
                                project={project}
                                filter={filter}
                                final={final}
                            />
                        )}
                    </> 
                :   (filter
                    ?   <>
                            {trades && Object.values(trades)?.filter(trade => 
                                trade.changed || trade.added || trade.deleted || trade.by_trade || 
                                Object.values(trade?.sections)?.filter(section => 
                                    section.changed || section.added || section.deleted || 
                                    Object.values(section?.subsections)?.filter(sub => 
                                        sub.changed || sub.deleted || sub.added || 
                                        Object.values(sub?.items)?.filter(i => 
                                            i.deleted || i.added || i.changed).length > 0 || 
                                        Object.values(sub?.owner_materials)?.filter(o => 
                                            o.deleted || o.added || o.changed).length > 0 || 
                                        Object.values(sub?.contractor_materials)?.filter(c => 
                                            c.deleted || c.added || c.changed).length > 0).length > 0).length > 0).sort((a, b) => DivisionsOrder[a.trade] - DivisionsOrder[b.trade]).map(trade =>
                                <Trade
                                    key={trade.trade}
                                    trade={trade.trade}
                                    setTrades={setTrades}
                                    trades={trades}
                                    setShowTrades={setShowTrades}
                                    showTrades={showTrades}
                                    project={project}
                                    filter={filter}
                                />
                            )}
                        </>
                    :   <>
                            {trades && Object.keys(trades)?.sort((a, b) => DivisionsOrder[a] - DivisionsOrder[b]).map(trade =>
                                <Trade
                                    key={trade}
                                    trade={trade}
                                    setTrades={setTrades}
                                    trades={trades}
                                    setShowTrades={setShowTrades}
                                    showTrades={showTrades}
                                    project={project}
                                    filter={filter}
                                />
                            )}
                        </>
                    )
                }
            </div>

            <div className="contractorbidtrade-extra-section-container">
                <div className="contractorbid-extra-title" id={`nav-extra`}>
                    Extra
                </div>
                <div>
                    <div><b>Description</b></div>
                    <TextareaAutosize
                        maxRows={5}
                        value={extraDesc}
                        onChange={e => {setExtraDesc(e.target.value); setExtraChanged(true);}}
                        onBlur={() => {
                            if(extraChanged){
                                saveExtra();
                            }
                        }}
                        className='contractorbid-extra-desc'
                        placeholder="Any extra costs not included in the SOW..."
                    />
                </div>
                <div>
                    <div><b>Cost</b></div>
                    $ <input
                        type={'number'}
                        value={extra}
                        min='0'
                        onChange={e => {setExtra(e.target.value); setExtraChanged(true);}}
                        onBlur={() => {
                            if(extraChanged){
                                saveExtra();
                            }
                        }}
                        className='contractorbid-extra-cost'
                    />
                </div>
            </div>

            <div className="contractorbid-projecttotal-container">
                {trades &&
                    <div className="contractorbid-projecttotal"> 
                        Project total: $ {+(Object.values(trades)?.filter(trade => !trade.deleted)?.filter(trade => {
                            if (final){
                                return true;
                            }else if (filter){
                                return (trade.changed 
                                    || trade.added 
                                    || Object.values(trade?.sections)?.filter(section => !section.deleted)?.filter(section =>
                                        section.changed 
                                        || section.added 
                                        || Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.filter(sub =>
                                            sub.changed 
                                            || sub.added 
                                            || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0 
                                            || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0 
                                            || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0
                                        ).length > 0
                                    ).length > 0 ) &&
                                    (trade.by_trade 
                                    || Object.values(trade?.sections)?.filter(section => !section.deleted)?.filter(section =>
                                        section.changed 
                                        || section.added 
                                        || Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.filter(sub =>
                                            sub.changed 
                                            || sub.added 
                                            || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0 
                                            || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0 
                                            || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0
                                        ).length > 0
                                    ).length > 0)
                            }else{
                                return true;
                            }
                        }).reduce((acc, trade) => acc +
                            +(trade?.by_trade 
                            ?   +(trade?.price 
                                ?   trade?.price
                                :   (trade?.cost
                                    ?   trade?.cost * (1 + trade?.markup / 100)
                                    :   0)
                                )
                            :   +Object.values(trade?.sections)?.filter(section => !section.deleted)?.filter(section => {
                                    if (filter){
                                        return section.changed
                                        || section.added
                                        || Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.filter(sub =>
                                            sub.changed
                                            || sub.added
                                            || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0
                                            || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0
                                            || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0
                                        ).length > 0
                                    }else{
                                        return true;
                                    }
                                }).reduce((acc, section) => acc + +(Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.filter(sub => {
                                    if (filter){
                                        return sub.changed
                                        || sub.added
                                        || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0
                                        || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0
                                        || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0
                                    }else{
                                        return true;
                                    }
                                }).reduce((acc, sub) => acc +
                                    +(sub?.total
                                    ?   sub?.total
                                    :   ((sub?.unit || sub?.measured) && sub?.cost_per
                                        ?   (sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100)
                                        :   0)
                                    ), 0)
                                ), 0)
                            ), 0) + +extra).toFixed(2)}
                    </div>
                }
            </div>

            <div className="contractorbidtrade-extra-section-container">
                <div className="contractorbid-extra-title" id={`nav-payment`}>
                    Payment schedule
                </div>

                <div>
                    {payments && Object.values(payments)?.filter(p => {
                        if (final){
                            return !p.deleted;
                        }else{
                            return true;
                        }
                    }).map(p =>
                        <PaymentItem
                            key={p.id}
                            payment={p}
                            payments={payments}
                            setPayments={setPayments}
                            final={final}
                        />
                    )}

                    <div className="contractorbidtrade-payment-total">Payment schedule total: $ {Object.values(payments)?.reduce((acc, curr) => acc + +curr?.amount, 0)}</div>

                </div>
                        
                {!final && <button className="default-button" onClick={() => handleAddPayment()}>Add item</button>}
            </div>
        </div>
    )
}

function PaymentItem({ payment, setPayments, final }){
    const [changed, setChanged] = useState(false);

    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionpayment/" + payment.id + "/",
            data: {
                project_version: payment.project_version,
                desc: payment.desc,
                amount: payment.amount ? payment.amount : 0,
                changed: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionpayment/" + payment.id + "/",
            data: {
                project_version: payment.project_version,
                desc: payment.desc,
                amount: payment.amount ? payment.amount : 0,
                deleted: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setPayments(prev => {
                prev[payment.id].deleted = true;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleBringBack = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionpayment/" + payment.id + "/",
            data: {
                project_version: payment.project_version,
                desc: payment.desc,
                amount: payment.amount ? payment.amount : 0,
                deleted: false,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setPayments(prev => {
                prev[payment.id].deleted = false;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    if (final) { 
        return (
            <div className="generatecontract-warrantyitem-container">
                <input
                    value={payment?.desc}
                    disabled={true}
                    className="generatecontract-warrantyitem-desc generatecontract-paymentitem-desc"
                />
                <div></div>
                $ <input
                    type={'number'}
                    value={payment?.amount}
                    disabled={true}
                    className="generatecontract-warrantyitem-desc generatecontract-paymentitem-amount"
                />
            </div>
        )
    }

    return (
        <div className={
            payment.deleted
            ?   "generatecontract-warrantyitem-container generatecontract-warrantyitem-container-deleted"
            :   payment.added
                ?   "generatecontract-warrantyitem-container generatecontract-warrantyitem-container-added"
                :   payment.changed
                    ?   "generatecontract-warrantyitem-container generatecontract-warrantyitem-container-changed"
                    :   "generatecontract-warrantyitem-container"
            }
        >
            <input
                value={payment?.desc}
                onChange={e => {
                    setPayments(prev => {
                        prev[payment.id].desc = e.target.value;
                        return {...prev};
                    })
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleSave();
                    }
                }}
                disabled={payment.deleted || final}
                placeholder="Description"
                className={
                    payment.deleted
                    ?   "generatecontract-warrantyitem-desc generatecontract-paymentitem-desc generatecontract-warrantyitem-desc-deleted"
                    :   payment.added   
                        ?   "generatecontract-warrantyitem-desc generatecontract-paymentitem-desc generatecontract-warrantyitem-desc-added"
                        :   payment.changed
                            ?   "generatecontract-warrantyitem-desc generatecontract-paymentitem-desc generatecontract-warrantyitem-desc-changed"
                            :   "generatecontract-warrantyitem-desc generatecontract-paymentitem-desc"
                }
            >
            </input>
            
            <div></div>

            $ <input
                type={'number'}
                value={payment?.amount}
                onChange={e => {
                    setPayments(prev => {
                        prev[payment.id].amount = e.target.value;
                        return {...prev};
                    })
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleSave();
                    }
                }}
                disabled={payment.deleted || final}
                placeholder="Amount"
                className={
                    payment.deleted
                    ?   "generatecontract-warrantyitem-desc generatecontract-paymentitem-amount generatecontract-warrantyitem-desc-deleted"
                    :   payment.added
                        ?   "generatecontract-warrantyitem-desc generatecontract-paymentitem-amount generatecontract-warrantyitem-desc-added"
                        :   payment.changed
                            ?   "generatecontract-warrantyitem-desc generatecontract-paymentitem-amount generatecontract-warrantyitem-desc-changed"
                            :   "generatecontract-warrantyitem-desc generatecontract-paymentitem-amount"
                }
            >
            </input>

            {!payment.deleted 
            ?   <button 
                    className="delete-x editprojectdetail-item-delete"
                    onClick={handleDelete}
                    style={{fontSize: '12px'}}
                >
                    X
                </button>
            :   <div
                    className="contractorchangeorder-item-bringback"
                    onClick={(e) => handleBringBack()}
                >
                    <i class="fa-solid fa-trash-arrow-up"></i>
                </div>
            }
        </div>
    )
}

function Trade({ trade, setTrades, trades, setShowTrades, showTrades, project, filter, final }){
    const [changed, setChanged] = useState(false);

    const handleSaveCost = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversiontrade/" + trades[trade].id + "/",
            data: {
                project_version: trades[trade].project_version,
                trade: trades[trade].trade,
                price: trades[trade].price ? trades[trade].price : 0,
                markup: trades[trade].markup ? trades[trade].markup : 0,
                cost: trades[trade].cost ? trades[trade].cost : 0,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div key={trade} className="contractorbidtrade-trade-container">
            {trades[trade]?.by_trade
            ?   <div
                    className="contractorprojectbid-section-header" 
                    id={'nav-trade-' + trade.replace(/\s/g, '')}
                    onClick={() => setShowTrades(prev => {
                        if(!prev[trade]){
                            let temp = {...prev};
                            Object.keys(temp).forEach(key => temp[key] = false);
                            return ({...temp, [trade]:true});
                        }
                        
                        return ({...prev, [trade]:!showTrades[trade]})
                    })}
                >
                    <div>
                        {showTrades?.[trade]
                        ?   <i className="fa-solid fa-caret-down"></i>
                        :   <i className="fa-solid fa-caret-right"></i>
                        }
                    </div>

                    <div className="contractorprojectbid-section-title">
                        <div>{DivisionsOrder[trade]}</div>
                        <div>{trade}</div>
                    </div>

                    {!final && 
                        <InfoHover3
                            message={`Currently, you are pricing the entire trade, you can swtich to \"By subtrade\" to give a price for each subsection instead.`}
                        >
                            <button 
                                className="default-button"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    axios({
                                        method: "put",
                                        url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversiontrade/" + trades[trade].id + "/",
                                        data: {
                                            project_version: trades[trade].project_version,
                                            trade: trades[trade].trade,
                                            by_trade: false,
                                            price: trades[trade].price,
                                        },
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                    })
                                    .then(response => {
                                        console.log(response);

                                        setTrades(prev => {
                                            prev[trade].by_trade = false;
                                            return {...prev};
                                        })
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                                }}
                            >   
                                By subsection
                            </button>
                        </InfoHover3>
                    }
                        
                    <div className="contractorbidtrade-trade-total-number">
                        $ {trades?.[trade]?.price 
                            ?   parseFloat(trades?.[trade]?.price)?.toFixed(2)
                            :   (trades[trade]?.cost * (1 + trades[trade]?.markup / 100) ? (trades[trade]?.cost * (1 + trades[trade]?.markup / 100)).toFixed(2) : 0)}
                    </div>
                </div>
            :   <div
                    className="contractorprojectbid-section-header" 
                    id={'nav-trade-' + trade.replace(/\s/g, '')}
                    onClick={() => setShowTrades(prev => {
                        if(!prev[trade]){
                            let temp = {...prev};
                            Object.keys(temp).forEach(key => temp[key] = false);
                            return ({...temp, [trade]:true});
                        }
                        
                        return ({...prev, [trade]:!showTrades[trade]})
                    })}
                >
                    <div>
                        {showTrades?.[trade]
                        ?   <i className="fa-solid fa-caret-down"></i>
                        :   <i className="fa-solid fa-caret-right"></i>
                        }
                    </div>

                    <div className="contractorprojectbid-section-title">
                        <div>{DivisionsOrder[trade]}</div>
                        <div>{trade}</div>
                    </div>
                    
                    {!final && 
                        <InfoHover3
                            message={`Currently, you are pricing each subsection in this trade, you can swtich to \"By trade\" to give a total price for the entire trade instead.`}
                        >
                            <button 
                                className="default-button"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    axios({
                                        method: "put",
                                        url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversiontrade/" + trades[trade].id + "/",
                                        data: {
                                            project_version: trades[trade].project_version,
                                            trade: trades[trade].trade,
                                            by_trade: true,
                                            price: trades[trade].price,
                                        },
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                    })
                                    .then(response => {
                                        console.log(response);

                                        setTrades(prev => {
                                            prev[trade].by_trade = true;
                                            return {...prev};
                                        })
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                                }}
                            >
                                By trade
                            </button>
                        </InfoHover3>
                    }
                    
                    <div className="contractorbidtrade-trade-total-number">
                        $ {Object.values(trades[trade]?.sections)?.filter(section => !section.deleted)?.filter(section => {
                                if (filter){
                                    return section.changed
                                    || section.added
                                    || Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.filter(sub =>
                                        sub.changed
                                        || sub.added
                                        || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0
                                        || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0
                                        || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0
                                    ).length > 0
                                }else{
                                    return true;
                                }
                            }).reduce((acc, section) => acc + +(Object.values(section?.subsections)?.filter(sub => !sub.deleted)?.filter(sub => {
                                if (filter){
                                    return sub.changed
                                    || sub.added
                                    || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0
                                    || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0
                                    || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0
                                }else{
                                    return true;
                                }
                            }).reduce((acc, sub) => acc +
                            acc + +(sub?.total 
                                    ? sub?.total 
                                    :   ((sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100) 
                                        ? (sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100)
                                        : 0)
                            ), 0)
                        ), 0).toFixed(2)}
                    </div>
                </div>
            }            

            {showTrades?.[trade] && trades[trade]?.by_trade &&
                <div className="contractorbidtrade-trade-cost-container">
                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Cost</div>

                        <input
                            type={'number'}
                            value={trades[trade]?.cost}
                            min='0'
                            onChange={e => {
                                setTrades(prev => {
                                    prev[trade].cost = e.target.value;
                                    return {...prev};
                                })

                                setChanged(true);
                            }}
                            onBlur={() => {
                                if(changed){
                                    handleSaveCost();
                                }
                            }}
                            placeholder="0.00"
                            onClick={e => e.stopPropagation()}
                            className='contractorbid-subsection-cost-input'
                            disabled={final}
                        />
                    </div>

                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Markup</div>

                        <input
                            type={'number'}
                            value={trades[trade]?.markup}
                            min='0'
                            onChange={e => {
                                setTrades(prev => {
                                    prev[trade].markup = e.target.value;
                                    return {...prev};
                                })

                                setChanged(true);
                            }}
                            onBlur={() => {
                                if(changed){
                                    handleSaveCost();
                                }
                            }}
                            placeholder="0.00"
                            onClick={e => e.stopPropagation()}
                            className='contractorbid-subsection-cost-input'
                            disabled={final}
                        /> %
                    </div>

                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Total</div>

                        <div>
                            $ {(trades[trade]?.cost * (1 + trades[trade]?.markup / 100) ? (trades[trade]?.cost * (1 + trades[trade]?.markup / 100)).toFixed(2) : 0)}
                        </div>

                        $ <input
                            type={'number'}
                            value={trades[trade]?.price}
                            min='0'
                            onChange={e => {
                                setTrades(prev => {
                                    prev[trade].price = e.target.value;
                                    return {...prev};
                                })

                                setChanged(true);
                            }}
                            onBlur={() => {
                                if(changed){
                                    handleSaveCost();
                                }
                            }}
                            placeholder="0.00"
                            onClick={e => e.stopPropagation()}
                            disabled={final}
                            className='contractorbid-subsection-cost-input'
                        />&nbsp;

                        <InfoHover2>
                            <div>
                                You can entre a total directly which will override the cost and markup.
                            </div>
                        </InfoHover2>
                    </div>
                </div>
            }

            {final 
            ?   <>
                    {showTrades?.[trade] && Object.values(trades[trade]?.sections)?.filter(section => 
                        Object.values(section?.subsections)?.filter(sub =>
                            !sub.deleted
                        ).length > 0
                    ).sort((a, b) => a.order - b.order).map(s =>
                        <ProjectSection
                            key={s.id}
                            s={s}
                            trade={trade}
                            setTrades={setTrades}
                            trades={trades}
                            project={project}
                            filter={filter}
                            final={final}
                        />
                    )}
                </>
            :   <>
                    {filter
                    ?   <>
                            {showTrades?.[trade] && Object.values(trades[trade]?.sections)?.filter(section => section.changed || section.added || section.deleted || Object.values(section?.subsections)?.filter(sub => sub.changed || sub.deleted || sub.added || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0 || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0 || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0).length > 0).sort((a, b) => a.order - b.order).map(s =>
                                <ProjectSection
                                    key={s.id}
                                    s={s}
                                    trade={trade}
                                    setTrades={setTrades}
                                    trades={trades}
                                    project={project}
                                    filter={filter}
                                />
                            )}
                        </>
                    :   <>
                            {showTrades?.[trade] && Object.values(trades[trade]?.sections)?.sort((a, b) => a.order - b.order).map(s =>
                                <ProjectSection
                                    key={s.id}
                                    s={s}
                                    trade={trade}
                                    setTrades={setTrades}
                                    trades={trades}
                                    project={project}
                                />
                            )}
                        </>
                    }    
                </>
            }                    
        </div>
    )
}

function ProjectSection({ s, setTrades, trade, trades, project, filter, final }){
    const [showDetails, setShowDetails] = useState({});

    useEffect(() => {
        if(s){
            let tempShowDetails = {};
            Object.values(s.subsections)?.map(sub => {
                tempShowDetails[sub.id] = false;
            })
            setShowDetails(tempShowDetails);
        }
    }, [s])

    return (
        <div>
            <div className="contractorbidtrades-section-header">
                <div className="contractorbidtrades-section-title" id={`nav-section-${s.id}`}>{s.choice} ({s.sectionID})</div>
            </div>

            <div className="contractorbidtrades-section-desc">
                {s.desc}
            </div>

            {final
            ?   <div className="homeownerbid-section-body-container">
                    {Object.values(s.subsections)?.filter(sub => !sub.deleted).sort((a, b) => a.order - b.order).map(sub =>
                        <Subsection
                            key={sub.id} 
                            sub={sub} 
                            s={s}
                            trade={trade}
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                            setTrades={setTrades}
                            trades={trades}
                            project={project}
                            filter={filter}
                            final={final}
                        />
                    )}
                </div>
            :   <>
                    {filter
                    ?   <div className="homeownerbid-section-body-container">
                            {Object.values(s.subsections)?.filter(sub => !sub.deleted).filter(sub => sub.added || sub.changed || Object.values(sub?.items)?.filter(i => i.deleted || i.added || i.changed).length > 0 || Object.values(sub?.owner_materials)?.filter(o => o.deleted || o.added || o.changed).length > 0 || Object.values(sub?.contractor_materials)?.filter(c => c.deleted || c.added || c.changed).length > 0).sort((a, b) => a.order - b.order).map(sub =>
                                <Subsection 
                                    key={sub.id} 
                                    sub={sub} 
                                    s={s}
                                    trade={trade}
                                    showDetails={showDetails}
                                    setShowDetails={setShowDetails}
                                    setTrades={setTrades}
                                    trades={trades}
                                    project={project}
                                    filter={filter}
                                />
                            )}
                        </div>
                    :   <div className="homeownerbid-section-body-container">
                            {Object.values(s.subsections)?.sort((a, b) => a.order - b.order).map(sub => 
                                <Subsection 
                                    key={sub.id} 
                                    sub={sub} 
                                    s={s}
                                    trade={trade}
                                    showDetails={showDetails}
                                    setShowDetails={setShowDetails}
                                    setTrades={setTrades}
                                    trades={trades}
                                    project={project}
                                />
                            )}
                        </div>
                    }
                </>
            }
        </div>
    );
}

function Subsection({ sub, s, setShowDetails, showDetails, setTrades, trade, trades, project, final }){
    const [edit, setEdit] = useState(false);

    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionsubsection/" + sub.id + "/",
            data: {
                project_version_section: sub.project_version_section,
                quantity: sub.quantity,
                extra_desc: sub.extra_desc,
                extra: sub.extra,
                total: sub.total ? sub.total : 0,
                cost_per: sub.cost_per ? sub.cost_per : 0,
                markup: sub.markup ? sub.markup : 0,
                unit: sub.unit ? sub.unit : 0,
                changed: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id].changed = true;
                return {...prev};
            })
            setEdit(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const addOwnerMaterial = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionownermaterial/",
            data: {
                desc: "",
                project_version_subsection: sub.id,
                added: true,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            console.log(response);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id]['owner_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
    }

    const addContractorMaterial = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversioncontractormaterial/",
            data: {
                desc: "",
                project_version_subsection: sub.id,
                added: true,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            console.log(response);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id]['contractor_materials'][response.data.id] = response.data;
                return {...prev};
            })
        })
    }

    const handleAddItem = () => {
        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/",
            data: {
                "desc": "",
                "order": Math.max(...Object.values(sub.items)?.map(i => i.order)) + 1 || 0,
                "project_version_subsection": sub.id,
                "added": true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => {
            console.log(response);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id]['items'][response.data.id] = response.data;
                return {...prev};
            })
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return (
        <div 
            className={
                final
                ?   'contractorbid-subsection-container'   
                :   sub.deleted
                    ?   "contractorbid-subsection-container contractorchangeorder-subsection-deleted"
                    :   sub.added
                        ?   "contractorbid-subsection-container contractorchangeorder-subsection-added"
                        :   sub.changed
                            ?   "contractorbid-subsection-container contractorchangeorder-subsection-changed"
                            :   "contractorbid-subsection-container"
            }
        >            
            <div 
                className="contractorchangeorderbid-subsection-header" 
                onClick={() => setShowDetails(prev => {
                    if(!prev[sub.id]){
                        let temp = {...prev};
                        Object.keys(temp).forEach(key => temp[key] = false);
                        return ({...temp, [sub.id]:true});
                    }

                    return ({...prev, [sub.id]:!showDetails[sub.id]})
                })} 
                id={`nav-subsection-${sub.id}`}
            >
                <div>
                    {showDetails[sub.id]
                    ?   <i className="fa-solid fa-caret-down"></i>
                    :   <i className="fa-solid fa-caret-right"></i>
                    }
                </div>

                <div className="contractorbid-subsection-title">{sub.group} - {sub.choice}, {sub.verb}</div>
                
                <div>
                    {sub.measured ? 
                        <div>
                            {sub.measured_unit === 'volume' && <>Volume</>}
                            {sub.measured_unit === 'area' && <>Area</>}
                            {sub.measured_unit === 'length' && <>Length</>}
                            {sub.measured_unit === 'item' && <>Items</>}: {sub.measured > 1000 ? sub.measured.toFixed(0) : sub.measured.toFixed(2)}
                            {sub.measured_unit === 'volume' && <> ft<sup>3</sup></>}
                            {sub.measured_unit === 'area' && <> ft<sup>2</sup></>}
                            {sub.measured_unit === 'length' && <> ft</>}
                        </div>
                    : null}
                </div>

                {!trades?.[trade]?.by_trade &&
                    <div>
                        $ {sub?.total ? sub.total : (((sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100)) ? ((sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100)).toFixed(2) : 0)}
                    </div>
                }
            </div>

            {showDetails[sub.id] && !trades?.[trade]?.by_trade &&
                <div className="contractorbid-subsection-cost-container">
                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Quantity</div>
                        
                        <div>
                            {sub.measured && 
                                <>
                                    {sub.measured_unit === 'volume' && <>Volume</>}
                                    {sub.measured_unit === 'area' && <>Area</>}
                                    {sub.measured_unit === 'length' && <>Length</>}
                                    {sub.measured_unit === 'item' && <>Items</>}: {sub.measured > 1000 ? sub.measured.toFixed(0) : sub.measured.toFixed(2)}
                                    {sub.measured_unit === 'volume' && <> ft<sup>3</sup></>}
                                    {sub.measured_unit === 'area' && <> ft<sup>2</sup></>}
                                    {sub.measured_unit === 'length' && <> ft</>}
                                </>
                            }
                        </div>

                        <div>
                            <input
                                type={'number'}
                                value={sub?.unit}
                                min='0'
                                onChange={e => {
                                    setTrades(prev => {
                                        prev[trade].sections[s.id]['subsections'][sub.id]['unit'] = e.target.value;
                                        return {...prev};
                                    })

                                    setEdit(true);
                                }}
                                onBlur={() => {
                                    if(edit){
                                        handleSave();
                                    }
                                }}
                                placeholder="0.00"
                                className='contractorbid-subsection-cost-input'
                                disabled={sub.deleted || final}
                            /> &nbsp;

                            <InfoHover2>
                                <div>
                                    You can set the quantity manually, which will override any calculated quantities from the takeoff.
                                </div>
                            </InfoHover2>
                        </div>
                    </div>

                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Cost per unit</div>
                            
                        <div>
                            $ <input
                                type={'number'}
                                value={sub?.cost_per}
                                min='0'
                                onChange={e => {
                                    setTrades(prev => {
                                        prev[trade].sections[s.id]['subsections'][sub.id]['cost_per'] = e.target.value;
                                        return {...prev};
                                    })

                                    setEdit(true);
                                }}
                                onBlur={() => {
                                    if(edit){
                                        handleSave();
                                    }
                                }}
                                placeholder="0.00"
                                className='contractorbid-subsection-cost-input'
                                disabled={sub.deleted || final}
                            />
                        </div>
                    </div>

                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Cost</div>

                        <div>
                            $ {((sub?.unit ? sub.unit : sub.measured) * sub?.cost_per).toFixed(2)}
                        </div>
                    </div>

                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Markup</div>

                        <div>
                            <input
                                type={'number'}
                                value={sub?.markup}
                                min='0'
                                onChange={e => {
                                    setTrades(prev => {
                                        prev[trade].sections[s.id]['subsections'][sub.id]['markup'] = e.target.value;
                                        return {...prev};
                                    })

                                    setEdit(true);
                                }}
                                onBlur={() => {
                                    if(edit){
                                        handleSave();
                                    }
                                }}
                                placeholder="0.00"
                                className='contractorbid-subsection-cost-input'
                                disabled={sub.deleted || final}
                            /> %
                        </div>
                    </div>

                    <div className="contractorbid-subsection-cost">
                        <div className="contractorbid-subsection-cost-title">Total</div>

                        <div>
                            $ {((sub?.unit ? sub.unit : sub.measured) * sub?.cost_per * (1 + sub?.markup / 100)).toFixed(2)}

                            <div>
                                $ <input
                                    type={'number'}
                                    value={sub?.total}
                                    min='0'
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => {
                                        setTrades(prev => {
                                            prev[trade].sections[s.id]['subsections'][sub.id]['total'] = e.target.value;
                                            return {...prev};
                                        })

                                        setEdit(true);
                                    }}
                                    onBlur={() => {
                                        if (edit){
                                            handleSave();
                                        }
                                    }}
                                    placeholder="0.00"
                                    className='contractorbid-subsection-cost-input'
                                    disabled={sub.deleted || final}
                                />&nbsp;
                                
                                <InfoHover2>
                                    <div>
                                        You can entire the total directly, which will override the cost per unit and markup.
                                    </div>
                                </InfoHover2>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {showDetails[sub.id] && 
                <div className="contractorbidsection-subsection-left-container">
                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 0 &&
                        <div>
                            <div>Description</div>
                            <TextareaAutosize
                                minRows={2}
                                maxRows={3}
                                value={sub?.quantity}
                                onChange={e => {
                                    setTrades(prev => {
                                        prev[trade].sections[s.id]['subsections'][sub.id]['quantity'] = e.target.value;
                                        return {...prev};
                                    });

                                    setEdit(true);
                                }}
                                onBlur={() => {
                                    if(edit){
                                        handleSave();
                                    }
                                }}
                                className="contractorbid-subsection-desc"
                                placeholder="Ex: 20 sqft, yellow paint, etc..."
                                disabled={sub.deleted || final}
                            />
                        </div>
                    }

                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 1 && 
                        <div className="contractorbid-subsection-top-container">
                            <div>
                                <div>Description</div>
                                <TextareaAutosize
                                    minRows={2}
                                    maxRows={3}
                                    value={sub?.quantity}
                                    onChange={e => {
                                        setTrades(prev => {
                                            prev[trade].sections[s.id]['subsections'][sub.id]['quantity'] = e.target.value;
                                            return {...prev};
                                        });

                                        setEdit(true);
                                    }}
                                    onBlur={() => {
                                        if(edit){
                                            handleSave();
                                        }
                                    }}
                                    className="contractorbid-subsection-desc"
                                    placeholder="Ex: 20 sqft, yellow paint, etc..."
                                    disabled={sub.deleted || final}
                                />
                            </div>
                            
                            {project?.items && 
                                <div>
                                    <div>Details</div>
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.items && Object.values(sub?.items)?.filter(i => {
                                            if (final){
                                                return !i.deleted;
                                            }else{
                                                return true;
                                            }
                                        }).sort((a, b) => a.order - b.order).map(i =>
                                            <SOWItem
                                                key={i.id}
                                                item={i}
                                                sub={sub}
                                                s={s}
                                                trade={trade}
                                                setTrades={setTrades}
                                                final={final}
                                            />
                                        )}
                                    </div>   

                                    {!sub.deleted && !final &&
                                        <div className="contractorbid-subsection-additembutton-container">
                                            <button 
                                                className="default-button-small contractorbid-subsection-additembutton"
                                                onClick={() => handleAddItem()}
                                            >
                                                Add item
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                            {project?.owner_mats &&
                                <div>
                                    Owner materials (exclusions)
                                    
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.owner_materials && Object.keys(sub?.owner_materials).length
                                        ?   Object.values(sub?.owner_materials)?.filter(i => {
                                                if (final){
                                                    return !i.deleted;
                                                }else{
                                                    return true;
                                                }
                                            }).map(i =>
                                                <OwnerMaterial
                                                    key={i.id}
                                                    material={i}
                                                    sub={sub}
                                                    s={s}
                                                    trade={trade}
                                                    setTrades={setTrades}
                                                    project={project}
                                                    showSwitch={project?.contractor_mats}
                                                    final={final}
                                                />
                                            )
                                        :   <small>No materials</small>
                                        }
                                    </div>
                    
                                    {!sub.deleted && !final &&
                                        <div className="contractorbid-subsection-additembutton-container">
                                            <button 
                                                className="default-button-small contractorbid-subsection-additembutton"
                                                onClick={() => addOwnerMaterial()}
                                            >
                                                Add material
                                            </button>
                                        </div>
                                    }   
                                </div>
                            }

                            {project?.contractor_mats &&
                                <div>
                                    Contractor materials
                    
                                    <div>
                                        {sub?.contractor_materials &&  Object.values(sub?.contractor_materials).length
                                        ?   Object.values(sub?.contractor_materials)?.filter(i => {
                                                if (final){
                                                    return !i.deleted;
                                                }else{
                                                    return true;
                                                }
                                            }).map(i =>
                                                <ContractorMaterial
                                                    key={i.id}
                                                    material={i}
                                                    sub={sub}
                                                    s={s}
                                                    trade={trade}
                                                    setTrades={setTrades}
                                                    project={project}
                                                    showSwitch={project?.owner_mats}
                                                    final={final}
                                                />
                                            )
                                        :   <small>No materials</small>
                                        }
                                    </div>       

                                    {!sub.deleted && !final &&
                                        <div className="contractorbid-subsection-additembutton-container">
                                            <button 
                                                className="default-button-small contractorbid-subsection-additembutton"
                                                onClick={() => addContractorMaterial()}
                                            >
                                                Add material
                                            </button>
                                        </div> 
                                    }
                                </div>
                            }
                        </div>
                    }

                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 2 &&
                        <>
                            <div>
                                <div>Description</div>
                                <TextareaAutosize
                                    minRows={2}
                                    maxRows={3}
                                    value={sub?.quantity}
                                    onChange={e => {
                                        setTrades(prev => {
                                            prev[trade].sections[s.id]['subsections'][sub.id]['quantity'] = e.target.value;
                                            return {...prev};
                                        });

                                        setEdit(true);
                                    }}
                                    onBlur={() => {
                                        if(edit){
                                            handleSave();
                                        }
                                    }}
                                    className="contractorbid-subsection-desc"
                                    placeholder="Ex: 20 sqft, yellow paint, etc..."
                                    disabled={sub.deleted || final}
                                />
                            </div>

                            <div className="contractorbid-subsection-top-container">
                                {project?.items && 
                                    <div>
                                        <div>Details</div>
                                        <div className="projectsubsection-items-conatiner">
                                            {sub?.items && Object.values(sub?.items)?.filter(i => {
                                                if (final){
                                                    return !i.deleted;
                                                }else{
                                                    return true;
                                                }
                                            }).sort((a, b) => a.order - b.order).map(i =>
                                                <SOWItem
                                                    key={i.id}
                                                    item={i}
                                                    sub={sub}
                                                    s={s}
                                                    trade={trade}
                                                    setTrades={setTrades}
                                                    final={final}
                                                />
                                            )}
                                        </div>   

                                        {!sub.deleted && !final &&
                                            <div className="contractorbid-subsection-additembutton-container">
                                                <button 
                                                    className="default-button-small contractorbid-subsection-additembutton"
                                                    onClick={() => handleAddItem()}
                                                >
                                                    Add item
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }

                                {project?.owner_mats &&
                                    <div>
                                        Owner materials (exclusions)
                                        
                                        <div className="projectsubsection-items-conatiner">
                                            {sub?.owner_materials && Object.keys(sub?.owner_materials).length
                                            ?   Object.values(sub?.owner_materials)?.filter(i => {
                                                    if (final){
                                                        return !i.deleted;
                                                    }else{
                                                        return true;
                                                    }
                                                }).map(i =>
                                                    <OwnerMaterial
                                                        key={i.id}
                                                        material={i}
                                                        sub={sub}
                                                        s={s}
                                                        trade={trade}
                                                        setTrades={setTrades}
                                                        project={project}
                                                        showSwitch={project?.contractor_mats}
                                                        final={final}
                                                    />
                                                )
                                            :   <small>No materials</small>
                                            }
                                        </div>
                        
                                        {!sub.deleted && !final &&
                                            <div className="contractorbid-subsection-additembutton-container">
                                                <button 
                                                    className="default-button-small contractorbid-subsection-additembutton"
                                                    onClick={() => addOwnerMaterial()}
                                                >
                                                    Add material
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }

                                {project?.contractor_mats &&
                                    <div>
                                        Contractor materials
                        
                                        <div>
                                            {sub?.contractor_materials &&  Object.values(sub?.contractor_materials).length
                                            ?   Object.values(sub?.contractor_materials)?.filter(i => {
                                                    if (final){
                                                        return !i.deleted;
                                                    }else{
                                                        return true;
                                                    }
                                                }).map(i =>
                                                    <ContractorMaterial
                                                        key={i.id}
                                                        material={i}
                                                        sub={sub}
                                                        s={s}
                                                        trade={trade}
                                                        setTrades={setTrades}
                                                        project={project}
                                                        showSwitch={project?.owner_mats}
                                                        final={final}
                                                    />
                                                )
                                            :   <small>No materials</small>
                                            }
                                        </div>       

                                        {!sub.deleted && !final &&
                                            <div className="contractorbid-subsection-additembutton-container">
                                                <button 
                                                    className="default-button-small contractorbid-subsection-additembutton"
                                                    onClick={() => addContractorMaterial()}
                                                >
                                                    Add material
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {+project?.items + +project?.owner_mats + +project?.contractor_mats === 3 &&
                        <>
                            <div className="contractorbid-subsection-top-container">
                                <div>
                                    <div>Description</div>
                                    <TextareaAutosize
                                        minRows={2}
                                        maxRows={3}
                                        value={sub?.quantity}
                                        onChange={e => {
                                            setTrades(prev => {
                                                prev[trade].sections[s.id]['subsections'][sub.id]['quantity'] = e.target.value;
                                                return {...prev};
                                            });

                                            setEdit(true);
                                        }}
                                        onBlur={() => {
                                            if(edit){
                                                handleSave();
                                            }
                                        }}
                                        className="contractorbid-subsection-desc"
                                        placeholder="Ex: 20 sqft, yellow paint, etc..."
                                        disabled={sub.deleted || final}
                                    />
                                </div>
                                
                                <div>
                                    <div>Details</div>
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.items && Object.values(sub?.items)?.filter(i => {
                                            if (final){
                                                return !i.deleted;
                                            }else{
                                                return true;
                                            }
                                        }).sort((a, b) => a.order - b.order).map(i =>
                                            <SOWItem
                                                key={i.id}
                                                item={i}
                                                sub={sub}
                                                s={s}
                                                trade={trade}
                                                setTrades={setTrades}
                                                final={final}
                                            />
                                        )}
                                    </div>   

                                    {!sub.deleted && !final &&
                                        <div className="contractorbid-subsection-additembutton-container">
                                            <button 
                                                className="default-button-small contractorbid-subsection-additembutton"
                                                onClick={() => handleAddItem()}
                                            >
                                                Add item
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="contractorsow-subsection-materials-container">
                                <div>
                                    Owner materials (exclusions)
                                    
                                    <div className="projectsubsection-items-conatiner">
                                        {sub?.owner_materials && Object.keys(sub?.owner_materials).length
                                        ?   Object.values(sub?.owner_materials)?.filter(i => {
                                                if (final){
                                                    return !i.deleted;
                                                }else{
                                                    return true;
                                                }
                                            }).map(i =>
                                                <OwnerMaterial
                                                    key={i.id}
                                                    material={i}
                                                    sub={sub}
                                                    s={s}
                                                    trade={trade}
                                                    setTrades={setTrades}
                                                    project={project}
                                                    showSwitch={project?.contractor_mats}
                                                    final={final}
                                                />
                                            )
                                        :   <small>No materials</small>
                                        }
                                    </div>
                    
                                    {!sub.deleted && !final &&
                                        <div className="contractorbid-subsection-additembutton-container">
                                            <button 
                                                className="default-button-small contractorbid-subsection-additembutton"
                                                onClick={() => addOwnerMaterial()}
                                            >
                                                Add material
                                            </button>
                                        </div>
                                    }
                                </div>

                                <div>
                                    Contractor materials
                    
                                    <div>
                                        {sub?.contractor_materials &&  Object.values(sub?.contractor_materials).length
                                        ?   Object.values(sub?.contractor_materials)?.filter(i => {
                                                if (final){
                                                    return !i.deleted;
                                                }else{
                                                    return true;
                                                }
                                            }).map(i =>
                                                <ContractorMaterial
                                                    key={i.id}
                                                    material={i}
                                                    sub={sub}
                                                    s={s}
                                                    trade={trade}
                                                    setTrades={setTrades}
                                                    project={project}
                                                    showSwitch={project?.owner_mats}
                                                    final={final}
                                                />
                                            )
                                        :   <small>No materials</small>
                                        }
                                    </div>       

                                    {!sub.deleted && !final &&
                                        <div className="contractorbid-subsection-additembutton-container">
                                            <button 
                                                className="default-button-small contractorbid-subsection-additembutton"
                                                onClick={() => addContractorMaterial()}
                                            >
                                                Add material
                                            </button>
                                        </div> 
                                    }
                                </div>
                            </div>
                        </>
                    }   
                </div>
            }
        </div>
    );
}

const ContractorMaterial = ({ material, sub, s, setTrades, trade, project, showSwitch, final }) => {
    const [changed, setChanged] = useState(false);
    const [itemDesc, setItemDesc] = useState(material.desc || "");

    const handleChange = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversioncontractormaterial/" + material.id + "/",
            data: {
                desc: itemDesc,
                project_version_subsection: sub.id,
                changed: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => { 
            console.log(response);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id]['contractor_materials'][material.id]['desc'] = itemDesc;
                prev[trade].sections[s.id]['subsections'][sub.id]['contractor_materials'][material.id]['changed'] = true;
                return {...prev};
            })

            setChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversioncontractormaterial/" + material.id + "/",
            data: {
                deleted: true,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].contractor_materials[material.id]['deleted'] = true;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveToContractor = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversioncontractormaterial/" + material.id + "/",
            data: {
                deleted: true,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].contractor_materials[material.id]['deleted'] = true;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionownermaterial/",
            data: {
                desc: material.desc,
                project_version_subsection: sub.id,
                changed: false,
                added: true,
                deleted: false,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(ownerResponse => {
            console.log(ownerResponse);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id]['owner_materials'][ownerResponse.data.id] = ownerResponse.data;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleBringBack = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversioncontractormaterial/" + material.id + "/",
            data: {
                deleted: false,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].contractor_materials[material.id].deleted = false;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    if (final){
        return (
            <div className="contractorchangeorder-item">
                <input
                    value={itemDesc}
                    disabled
                    className="editprojectdetail-item-desc"
                />
            </div>   
        )
    }

    if (sub.deleted){
        return (
            <div className="contractorchangeorder-item contractorchangeorder-item-deleted">
                <input
                    value={itemDesc}
                    disabled
                    className="editprojectdetail-item-desc contractorchangeorder-item-desc-deleted"
                />
            </div>   
        )
    }

    return (
        <div 
            className={
                material.deleted
                ?   "contractorchangeorder-item contractorchangeorder-item-deleted"
                :   material.added
                    ?   "contractorchangeorder-item contractorchangeorder-item-added"
                    :   material.changed
                        ?   "contractorchangeorder-item contractorchangeorder-item-changed"
                        :   "contractorchangeorder-item"
            }
        >
            {showSwitch &&
                <InfoHover3
                    message="Move to owner materials"
                >
                    <button
                        className="editprojectdetail-itemup-button"
                        onClick={(e) => {moveToContractor(); e.stopPropagation()}}
                    >
                        &#x3c;
                    </button>
                </InfoHover3>
            }

            <input
                value={itemDesc}
                onChange={e => {
                    setItemDesc(e.target.value);
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleChange();
                    }
                }}
                disabled={material.deleted}
                className={
                    material.deleted
                    ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-deleted"
                    :   material.added
                        ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-added"
                        :   material.changed
                            ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-changed"
                            :   "editprojectdetail-item-desc"
                }
            >
            </input>
            
            {!material.deleted 
            ?   <button 
                    className="delete-x editprojectdetail-item-delete"
                    onClick={handleDelete}
                    style={{fontSize: '12px'}}
                >
                    X
                </button>
            :   <div
                    className="contractorchangeorder-item-bringback"
                    onClick={(e) => handleBringBack()}
                >
                    <i class="fa-solid fa-trash-arrow-up"></i>
                </div>
            }
        </div>
    )
}

const OwnerMaterial = ({ material, sub, s, setTrades, trade, project, showSwitch, final }) => {
    const [changed, setChanged] = useState(false);
    const [itemDesc, setItemDesc] = useState(material.desc || "");

    const handleChange = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionownermaterial/" + material.id + "/",
            data: {
                desc: itemDesc,
                project_version_subsection: sub.id,
                changed: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => { 
            console.log(response);

            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id]['owner_materials'][material.id]['desc'] = itemDesc;
                temp[trade].sections[s.id]['subsections'][sub.id]['owner_materials'][material.id]['changed'] = true;
                return {...temp};
            })

            setChanged(false);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionownermaterial/" + material.id + "/",
            data: {
                deleted: true,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].owner_materials[material.id].deleted = true;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveToContractor = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionownermaterial/" + material.id + "/",
            data: {
                deleted: true,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].owner_materials[material.id].deleted = true;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "post",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversioncontractormaterial/",
            data: {
                desc: material.desc,
                project_version_subsection: sub.id,
                changed: false,
                added: true,
                deleted: false,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(contractorResponse => {
            console.log(contractorResponse);

            setTrades(prev => {
                prev[trade].sections[s.id]['subsections'][sub.id]['contractor_materials'][contractorResponse.data.id] = contractorResponse.data;
                return {...prev};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleBringBack = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionownermaterial/" + material.id + "/",
            data: {
                deleted: false,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].owner_materials[material.id].deleted = false;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    if (final){
        return (
            <div className="contractorchangeorder-item">
                <input
                    value={itemDesc}
                    disabled
                    className="editprojectdetail-item-desc"
                />
            </div>   
        )
    }

    if (sub.deleted){
        return (
            <div className="contractorchangeorder-item contractorchangeorder-item-deleted">
                <input
                    value={itemDesc}
                    disabled
                    className="editprojectdetail-item-desc contractorchangeorder-item-desc-deleted"
                />
            </div>   
        )
    }

    return (
        <div 
            className={
                material.deleted
                ?   "contractorchangeorder-item contractorchangeorder-item-deleted"
                :   material.added
                    ?   "contractorchangeorder-item contractorchangeorder-item-added"
                    :   material.changed
                        ?   "contractorchangeorder-item contractorchangeorder-item-changed"
                        :   "contractorchangeorder-item"
            }   
        >
            {showSwitch && 
                <InfoHover3
                    message="Move to contractor"
                >
                    <button
                        className="editprojectdetail-itemup-button"
                        onClick={(e) => {moveToContractor(); e.stopPropagation()}}
                    >
                        &#x3e;
                    </button>
                </InfoHover3>
            }

            <input
                value={itemDesc}
                onChange={e => {
                    setItemDesc(e.target.value);
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleChange();
                    }
                }}
                disabled={material.deleted}
                className={
                    material.deleted
                    ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-deleted"
                    :   material.added
                        ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-added"
                        :   material.changed
                            ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-changed"
                            :   "editprojectdetail-item-desc"
                }
            >
            </input>
            
            {!material.deleted
            ?   <button 
                    className="delete-x editprojectdetail-item-delete"
                    onClick={handleDelete}
                    style={{fontSize: '12px'}}
                >
                    X
                </button>
            :   <div
                    className="contractorchangeorder-item-bringback"
                    onClick={(e) => handleBringBack()}
                >
                    <i class="fa-solid fa-trash-arrow-up"></i>
                </div>
            }
        </div>
    )
}

const SOWItem = ({ item, sub, s, setTrades, trade, final }) => {
    const [itemDesc, setItemDesc] = useState(item.desc);
    const [changed, setChanged] = useState(false);

    const handleSave = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + item.id + "/",
            data: {
                desc: itemDesc,
                order: item.order,
                project_version_subsection: sub.id,
                changed: true,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);

            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id]['items'][item.id].desc = itemDesc;
                temp[trade].sections[s.id]['subsections'][sub.id]['items'][item.id].changed = true;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleDelete = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + item.id + "/",
            data: {
                deleted: true,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id]['subsections'][sub.id].items[item.id].deleted = true;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveUp = () => {
        let items = Object.values(sub?.items).sort((a, b) => a.order - b.order);
        let index = items.findIndex(i => i.id === item.id);

        let curItemId = item.id;
        let curOrder = items[index].order;
        let otheritemId = items[index - 1].id;
        let otherOrder = items[index - 1].order;

        setTrades(prev => {
            let temp = {...prev};
            temp[trade].sections[s.id].subsections[sub.id].items[curItemId].order = otherOrder;
            temp[trade].sections[s.id].subsections[sub.id].items[curItemId].changed = true;
            temp[trade].sections[s.id].subsections[sub.id].items[otheritemId].order = curOrder;
            temp[trade].sections[s.id].subsections[sub.id].items[otheritemId].changed = true;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + curItemId + "/",
            data: {
                order: otherOrder,
                project_version_subsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + otheritemId + "/",
            data: {
                order: curOrder,
                project_version_subsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const moveDown = () => {
        let items = Object.values(sub?.items).sort((a, b) => a.order - b.order);
        let index = items.findIndex(i => i.id === item.id);

        let curItemId = item.id;
        let curOrder = items[index].order;
        let otheritemId = items[index + 1].id;
        let otherOrder = items[index + 1].order;

        setTrades(prev => {
            let temp = {...prev};
            temp[trade].sections[s.id].subsections[sub.id].items[curItemId].order = otherOrder;
            temp[trade].sections[s.id].subsections[sub.id].items[curItemId].changed = true;
            temp[trade].sections[s.id].subsections[sub.id].items[otheritemId].order = curOrder;
            temp[trade].sections[s.id].subsections[sub.id].items[otheritemId].changed = true;
            return {...temp};
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + curItemId + "/",
            data: {
                order: otherOrder,
                project_version_subsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })

        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + otheritemId + "/",
            data: {
                order: curOrder,
                project_version_subsection: sub.id
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleBringBack = () => {
        axios({
            method: "put",
            url: "https://bob-backendapi.herokuapp.com/api/projectcontractorversionitem/" + item.id + "/",
            data: {
                deleted: false,
                project_version_subsection: sub.id,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => {
            console.log(res);
            setTrades(prev => {
                let temp = {...prev};
                temp[trade].sections[s.id].subsections[sub.id].items[item.id].deleted = false;
                return {...temp};
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    if (final){
        return (
            <div className="contractorchangeorder-item">
                <input
                    value={itemDesc}
                    disabled
                    className="editprojectdetail-item-desc"
                />
            </div>   
        )
    }

    if (sub.deleted){
        return (
            <div className="contractorchangeorder-item contractorchangeorder-item-deleted">
                <input
                    value={itemDesc}
                    disabled
                    className="editprojectdetail-item-desc contractorchangeorder-item-desc-deleted"
                />
            </div>   
        )
    }

    return (
        <div 
            className={
                item.deleted
                ?   "contractorchangeorder-item contractorchangeorder-item-deleted"
                :   item.added 
                    ?   "contractorchangeorder-item contractorchangeorder-item-added"
                    :   item.changed
                        ?   "contractorchangeorder-item contractorchangeorder-item-changed"
                        :   "contractorchangeorder-item"
            }
        >
            <div className="editprojectdetail-updown-container">
                {item.order !== Object.values(sub.items).map((item) => item.order).reduce((a, b) => Math.min(a, b), 999) &&
                <button className="editprojectdetail-itemup-button" onClick={() => moveUp()}>&and;</button>}
                {item.order !== Object.values(sub.items).map((item) => item.order).reduce((a, b) => Math.max(a, b), -1) &&
                <button className="editprojectdetail-itemdown-button" onClick={() => moveDown()}>&or;</button>}
            </div>

            <input
                value={itemDesc}
                onChange={e => {
                    setItemDesc(e.target.value);
                    setChanged(true);
                }}
                onBlur={() => {
                    if(changed){
                        handleSave();
                    }
                }}
                disabled={item.deleted}
                className={
                    item.deleted
                    ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-deleted"
                    :   item.added
                        ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-added"
                        :   item.changed
                            ?   "editprojectdetail-item-desc contractorchangeorder-item-desc-changed"
                            :   "editprojectdetail-item-desc"
                }
            >
            </input>
                        
            {!item.deleted 
            ?   <button 
                    className="delete-x editprojectdetail-item-delete"
                    onClick={() => handleDelete()}
                    style={{fontSize: '12px'}}
                >
                    X
                </button>
            :   <div
                    className="contractorchangeorder-item-bringback"
                    onClick={(e) => handleBringBack()}
                >
                    <i class="fa-solid fa-trash-arrow-up"></i>
                </div>
            }
        </div>
    )
}

const SideNav = ({ trades, setShowTrades, showTrades }) => {
    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        if(trades && Object.keys(showTrades)){
            let temp = '';
            Object.keys(trades).forEach(key => {
                if (showTrades[key]){
                    temp = key;
                }
            })
            setActiveItem(`nav-trade-${temp.replace(/\s/g, '')}`);
        }
    }, [trades, showTrades]);
    
    return (
        <div className="sidenav-container">
            <ul>      
                {Object.keys(trades)?.sort((a, b) => DivisionsOrder[a] - DivisionsOrder[b]).map(s => 
                    <li key={s}><div
                        onClick={(e) => {
                            e.preventDefault();
                            const element = document.querySelector(`#nav-trade-${s.replace(/\s/g, '')}`);
                            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                            const yOffset = -200;
                            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });

                            let temp = {};
                            Object.keys(trades).forEach(key => temp[key] = false);
                            temp[s] = true;
                            setShowTrades(temp);
                        }}
                        className={activeItem === `nav-trade-${s.replace(/\s/g, '')}` ? 'nav-item-active' : 'nav-item'}
                    >
                        {s}
                    </div></li>
                )}
                <li>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            const element = document.querySelector(`#nav-extra`);
                            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
                            const yOffset = -100;
                            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
                        }}
                        className={activeItem === `nav-extra` ? 'nav-item-active' : 'nav-item'}
                    >
                        Extra
                    </div>
                </li>
            </ul>
        </div>
    )
}