import { useState, useEffect } from 'react';
import "../styles/Career.css"

export default function Career() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const [currentRole, setCurrentRole] = useState('full')

    return (
        <div className="career-container">
            <div className='landing2-navbar' id='navbar'>
                <a href='/'>
                    <div className='landing2-navbar-logo'>
                        <img
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-09-04+at+2.03.46+PM-fotor-bg-remover-20230904213533+(1).png'
                            className='landing2-navbar-logo-img'
                        />
                    </div>
                </a>

                {showMobileMenu
                    ? <div className='landing2-navbar-container-mobile'>
                        <div className='landing2-navbar-mobile-top'>
                            <div className='landing2-navbar-logo-mobile-navbar'>
                                <img
                                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-09-04+at+2.03.46+PM-fotor-bg-remover-20230904213533+(1).png'
                                    className='landing2-navbar-logo-img-mobile-navbar'
                                />
                            </div>

                            <div className='landing2-navbar-icon-mobile' onClick={() => setShowMobileMenu(false)}>
                                <i class="fa-solid fa-times"></i>
                            </div>
                        </div>

                        <div className='landing2-navbar-items-mobile'>
                            <a href='/about-us' className='landing2-navbar-item'>
                                <div className='landing2-navbar-item'>
                                    About us
                                </div>
                            </a>

                            <a href='/demo' className='landing2-navbar-item'>
                                <div className='landing2-navbar-item'>
                                    Demo
                                </div>
                            </a>

                            <a href='/support' className='landing2-navbar-item'>
                                <div className='landing2-navbar-item'>
                                    Support
                                </div>
                            </a>

                            <a href='/careers' className='landing2-navbar-item'>
                                <div className='landing2-navbar-item'>
                                    Careers
                                </div>
                            </a>

                            <a href='/login'>
                                <button className='landing2-navbar-login'>
                                    Login
                                </button>
                            </a>

                            <a href='/demo'>
                                <button className='landing2-startbutton'>
                                    Try Bobyard free <i class="fa-solid fa-angle-right"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                    : <div className='landing2-navbar-icon-mobile' onClick={() => setShowMobileMenu(true)}>
                        <i class="fa-solid fa-bars"></i>
                    </div>
                }

                <div className='landing2-navbar-items'>
                    <a href='/about-us' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                            About us
                        </div>
                    </a>

                    <a href='/demo' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                            Demo
                        </div>
                    </a>

                    <a href='/support' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                            Support
                        </div>
                    </a>

                    <a href='/careers' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                            Careers
                        </div>
                    </a>

                    <a href='/login'>
                        <button className='landing2-navbar-login'>
                            Login
                        </button>
                    </a>

                    <a href='/demo'>
                        <button className='landing2-startbutton'>
                            Try Bobyard free <i class="fa-solid fa-angle-right"></i>
                        </button>
                    </a>
                </div>
            </div>

            <div className='career-heading'>
                <div className='career-heading-title'>
                    Join Bobyard!
                </div>

                <p>Construction is one of the largest industries in the world, but it is also one of the least technologically innovative spaces. Conducting fast and accurate cost estimates is a massive pain point because of the tedious nature of takeoffs (measuring & counting materials needed from the drawings). Bobyard automates the construction takeoff process with CV and NLP models to make cost estimates 10x faster while eliminating mistakes. </p>

                <p>We are a lean startup backed by some of the best VCs in the world: Primary and Pear. Almost all of the work lies ahead of us; we are really excited to create next-gen software for the built world! </p>

                <p>These are all full-time & in-person roles in the SF Bay Area. Learning rate and dedication are vital factors. If you can prove that you can execute on the products our customers are waiting for at the speed and quality the market demands (or if you can prove that you will acquire the ability to do so fast enough), we would love to work with you. </p>
            </div>

            <div className='career-body'>
                <div className='career-left'>
                    <div className='career-left-heading'>
                        Roles
                    </div>

                    <div>
                        <div
                            onClick={() => setCurrentRole('full')}
                            className={currentRole === 'full' ? 'career-left-role career-left-role-active' : 'career-left-role'}
                        >
                            Full-stack
                        </div>

                        {/*<div 
                            onClick={() => setCurrentRole('frontend')} 
                            className={currentRole === 'frontend' ? 'career-left-role career-left-role-active' : 'career-left-role'}
                        >
                            Frontend
                        </div>

                        <div 
                            onClick={() => setCurrentRole('backend')} 
                            className={currentRole === 'backend' ? 'career-left-role career-left-role-active' : 'career-left-role'}
                        >
                            Backend
                        </div>*/}

                        <div
                            onClick={() => setCurrentRole('cv')}
                            className={currentRole === 'cv' ? 'career-left-role career-left-role-active' : 'career-left-role'}
                        >
                            Computer Vision
                        </div>
                    </div>
                </div>

                <div className='career-right'>
                    {currentRole === 'frontend' &&
                        <div className='career-role-container'>
                            <div className='career-role-header'>
                                <div className='career-role-title'>
                                    Founding Frontend Engineer
                                </div>

                                <div className='career-role-apply'>
                                    <a href='https://wellfound.com/l/2zzQLJ' target='_blank' className='career-role-apply-link'>
                                        Apply!
                                    </a>
                                </div>
                            </div>

                            <div className='career-role-body'>
                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Position Overview</div>
                                    <p>It’s crucial for Bobyard to produce amazing products that look and feel great. Contractors and professional estimators spend long hours each day doing tedious work, it is very important that our customers find Bobyard to be the most intuitive takeoff tool on the market.</p>

                                    <p>You will have very high autonomy in designing, executing, and iterating on products. We are a startup, and we move fast. You will be the world’s leading expert on the products you build. We look for world-class engineers who have both a vision for the product but also the ability to go heads down and write exceptional code. </p>

                                    <p>You will be responsible for implementing and scaling much of the takeoff software for Bobyard with React.js. You will take the product from 0 to 1.</p>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Desired Attributes</div>
                                    <div>
                                        <ul>
                                            <li>Learning Rate: Ability to adapt and learn new technologies and frameworks swiftly</li>
                                            <li>Passion: Startups are not easy, this is not a cushy SWE job at Google</li>
                                            <li>Obsession with the customer and their experience</li>
                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Benefits</div>
                                    <div>
                                        <ul>
                                            <li>Competitive salary plus equity options (target $120k - $150k base)</li>
                                            <li>Opportunity to collaborate with a dynamic, driven team</li>
                                            <li>Build a massive company</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {currentRole === 'backend' &&
                        <div className='career-role-container'>
                            <div className='career-role-header'>
                                <div className='career-role-title'>
                                    Founding Backend Engineer
                                </div>

                                <div className='career-role-apply'>
                                    <a href='https://wellfound.com/l/2zzQRE' target='_blank' className='career-role-apply-link'>
                                        Apply!
                                    </a>
                                </div>
                            </div>

                            <div className='career-role-body'>
                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Position Overview</div>
                                    <p>Bobyard’s customers range from residential home builders to some of the largest contracting firms in the world. Our software needs to operate smoothly, consistently, and at scale. We will be handling a lot of data as contractors trust our platform with their construction drawings.</p>

                                    <p>You will have very high autonomy in designing, executing, and iterating on products. We are a startup, and we move fast. You will be the world’s leading expert on the products you build. We look for world-class engineers who have both a vision for the product but also the ability to go heads down and write exceptional code. </p>

                                    <p>You will be responsible for implementing and scaling much of the backend and infrastructure for Bobyard using Django REST, AWS, and PostgreSQL. You will take the product from 0 to 1.</p>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Desired Attributes</div>
                                    <div>
                                        <ul>
                                            <li>Learning Rate: Ability to adapt and learn new technologies and frameworks swiftly</li>
                                            <li>Passion: Startups are not easy, this is not a cushy SWE job at Google</li>
                                            <li>Incredibly proud of the code you write and advocate for high standards</li>
                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Benefits</div>
                                    <div>
                                        <ul>
                                            <li>Competitive salary plus equity options (target $120k - $150k base)</li>
                                            <li>Opportunity to collaborate with a dynamic, driven team</li>
                                            <li>Build a massive company</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {currentRole === 'full' &&
                        <div className='career-role-container'>
                            <div className='career-role-header'>
                                <div className='career-role-title'>
                                    Full-stack Intern --{'>'} Permanent Full-stack Engineer
                                </div>

                                <a href='https://wellfound.com/l/2A628Y' target='_blank' className='career-role-apply-link'>
                                    <div className='career-role-apply'>
                                        Apply!
                                    </div>
                                </a>
                            </div>

                            <div className='career-role-body'>
                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Position Overview</div>
                                    <p>Bobyard’s customers range from residential home builders to some of the largest contracting firms in the world. Our software needs to operate smoothly, consistently, and at scale. We will be handling a lot of data as contractors trust our platform with their construction drawings.</p>

                                    <p>It’s also crucial for Bobyard to produce amazing products that look and feel great. Contractors and professional estimators spend long hours each day doing tedious work, it is very important that our customers find Bobyard to be the most intuitive takeoff tool on the market.</p>

                                    <p>You will have very high autonomy in designing, executing, and iterating on products. We are a startup, and we move fast. You will be the world’s leading expert on the products you build. We look for world-class engineers who have both a vision for the product but also the ability to go heads down and write exceptional code. </p>

                                    <p>You will be responsible for implementing and scaling much of the backend and infrastructure for Bobyard using Django REST, AWS, and PostgreSQL. Likewise for the frontend (we use React.js), you will take the product from 0 to 1.</p>

                                    <p><b>It is our intention to convert this position into a permanent role after the intenrship ends.</b></p>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Desired Attributes</div>
                                    <div>
                                        <ul>
                                            <li>Learning Rate: Ability to adapt and learn new technologies and frameworks swiftly</li>
                                            <li>Passion: Startups are not easy, this is not a cushy SWE job at Google</li>
                                            <li>Incredibly proud of the code you write and advocate for high standards</li>
                                            <li>Obsession with the customer and their experience</li>
                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Benefits</div>
                                    <div>
                                        <ul>
                                            <li>Competitive salary (target $40 - $60 USD/hour base)</li>
                                            <li>Opportunity to collaborate with a dynamic, driven team</li>
                                            <li>Build a massive company</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {currentRole === 'cv' &&
                        <div className='career-role-container'>
                            <div className='career-role-header'>
                                <div className='career-role-title'>
                                    Computer Vision Intnern --{'>'} Permanent Computer Vision Engineer
                                </div>

                                <a href='https://wellfound.com/l/2A628N' target='_blank' className='career-role-apply-link'>
                                    <div className='career-role-apply'>
                                        Apply!
                                    </div>
                                </a>
                            </div>

                            <div className='career-role-body'>
                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Position Overview</div>
                                    <p>Bobyard solves non-trivial CV problems in order to automate takeoffs for contractors. We can save them dozens of hours for each project easily. This requires a complex system of models that produce better than human results. </p>

                                    <p>You will have very high autonomy in designing, executing, and iterating on products. We are a startup, and we move fast. You will be the world’s leading expert on the products you build. We look for world-class engineers who have both a vision for the product but also the ability to go heads down and write exceptional code. </p>

                                    <p>You will be responsible for developing state-of-the-art CV algorithms and models for segmentation, object detection, OCR, one-shot learning, and PDF vector reading techniques. You will take the product from 0 to 1.</p>

                                    <p><b>It is our intention to convert this position into a permanent role after the intenrship ends.</b></p>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Desired Attributes</div>
                                    <div>
                                        <ul>
                                            <li>Learning Rate: Ability to adapt and learn new technologies and frameworks swiftly</li>
                                            <li>Passion: Startups are not easy, this is not a cushy SWE job at Google</li>
                                            <li>Incredibly proud of the code you write and advocate for high standards</li>
                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <br></br>
                                    <div className='career-role-section-title'>Benefits</div>
                                    <div>
                                        <ul>
                                            <li>Competitive salary (target $40 - $60 USD/hour base)</li>
                                            <li>Opportunity to collaborate with a dynamic, driven team</li>
                                            <li>Build a massive company</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}