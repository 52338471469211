import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import '../../styles/ContractorTakeoff.css';
import NotFoundPage from '../../components/NotFoundPage';

import ShapeArea from './ContractorTakeoffTradespages/ShapeArea';
import FloorPlan from './ContractorTakeoffTradespages/FloorPlan';
import Rectangle from './ContractorTakeoffTradespages/Rectangle';
import RectangleConstructor from './ContractorTakeoffTradespages/RectangleConstructor';
import Polygon from './ContractorTakeoffTradespages/Polygon';
import PolygonConstructor from './ContractorTakeoffTradespages/PolygonConstructor';

import { Circle, Rect, Layer, Line, Stage } from "react-konva";

import { Image } from 'image-js';
import ItemConstructor from './ContractorTakeoffTradespages/ItemConstructor';
import Item from './ContractorTakeoffTradespages/Item';
import ItemAdd from './ContractorTakeoffTradespages/ItemAdd';
import LengthConstructor from './ContractorTakeoffTradespages/LengthConstructor';
import Length from './ContractorTakeoffTradespages/Length';
import LengthEdit from './ContractorTakeoffTradespages/LengthEdit';
import ScaleConstructor from './ContractorTakeoffTradespages/ScaleConstructor';

import InfoHover2 from '../../components/InfoHover2';
import { DivisionsOrder } from '../../data/DivisionsOrder';
import { subsectionChoices } from '../../data/choices';
import InfoHover3 from '../../components/InfoHover3';
import ContractorCreatedProjectSideNav from './ContractorCreatedProjectSideNav';
import LinkMenu from './ContractorTakeoffTradespages/LinkMenu';

const stripe = require('stripe')('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');

export default function ContractorProjectTakeoffTrades({ user }){
    const params = useParams();
    const projectUUID = params.projectUUID;

    const [takeOff, setTakeOff] = useState();
    const [project, setProject] = useState();
    const [projectFiles, setProjectFiles] = useState();
    const [files, setFiles] = useState();
    const [uploads, setUploads] = useState();
    const [trades, setTrades] = useState();
    const [individual, setIndividual] = useState();

    const [currentFile, setCurrentFile] = useState();
    const [position, setPosition] = useState({x: 0, y: 0});
    const [scale, setScale] = useState(1);
    const [showScaleModal, setShowScaleModal] = useState(false);
    const [scaleStart, setScaleStart] = useState(null);
    const [scaleEnd, setScaleEnd] = useState(null);
    const [curObj, setCurObj] = useState(0);

    const [measureGroup, setMeasureGroup] = useState(null);

    const [drawingScale, setDrawingScale] = useState(false);
    const [drawingRectangle, setDrawingRectangle] = useState(false);
    const [drawingPolygon, setDrawingPolygon] = useState(false);
    const [drawingItem, setDrawingItem] = useState(false);
    const [addingItem, setAddingItem] = useState(false);
    const [deletingDots, setDeletingDots] = useState(false);
    const [drawingLength, setDrawingLength] = useState(false);
    const [editingLength, setEditingLength] = useState(false);

    const [dots, setDots] = useState([]);

    const [showAreas, setShowAreas] = useState(false);
    const [loading, setLoading] = useState(true);

    const [showAddPageModal, setShowAddPageModal] = useState(false);

    const [runningInference, setRunningInference] = useState(false);
    const [showInferenceModal, setShowInferenceModal] = useState(false);

    const [customer, setCustomer] = useState();

    useEffect(() => {
        stripe.customers.list({
            email: user.email,
            expand: ['data.subscriptions']
        })
        .then((response) => {
            console.log(response);
            setCustomer(response.data[0]);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [user])

    useEffect(() => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/get-contractor-project-trades-takeoff/',
            data: {
                projectUUID: projectUUID,
                userID: user.id,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);
            
            setLoading(false);
            setProject(response.data.project);
            setProjectFiles(response.data.projectfiles);
            setFiles(response.data.files);
            setUploads(response.data.uploadedfiles);
            setTakeOff(response.data.takeoff);
            setTrades(response.data.trades);
            setIndividual(response.data.individuals);

            if(Object.keys(response.data.files).length){
                setCurrentFile(Object.keys(response.data.files)[0]);
                setPosition({x: response.data.files[Object.keys(response.data.files)[0]].position_x, y: response.data.files[Object.keys(response.data.files)[0]].position_y});
                setScale(response.data.files[Object.keys(response.data.files)[0]].scale);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }, [projectUUID]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setCurObj(null);
                setDots([]);
                setDrawingRectangle(false);
                setDrawingPolygon(false);
                setDrawingItem(false);
                setAddingItem(false);
                setDeletingDots(false);
                setDrawingLength(false);
                setEditingLength(false);
                setDrawingScale(false);
                setShowScaleModal(false);
                setScaleStart(null);
                setScaleEnd(null);                
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (drawingItem){
                if (e.key === 'Backspace' || e.key === 'Delete') {
                    setDots(prev => prev.slice(0, -1));
                }

                if (e.key === 'Enter') {
                    createItem();
                    setDrawingItem(false);
                }
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [drawingItem, dots]);

    const handleZoom = (newZoomScale) => {
        const center = {
            x: -position.x / scale + (window.innerWidth * 0.8/ 2) / scale,
            y: -position.y / scale + (window.innerHeight * 0.9 / 2) / scale
        }

        const newX = -center.x * scale * newZoomScale + (window.innerWidth * 0.8 / 2);
        const newY = -center.y * scale * newZoomScale + (window.innerHeight * 0.9 / 2);

        axios({
            method: 'put',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftradefile/' + files[currentFile].id + '/',
            data: {
                contractorprojecttakeoff: files[currentFile].contractorprojecttakeoff,
                zoom: files[currentFile].zoom * newZoomScale,
                position_x: newX,
                position_y: newY,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            setFiles(prev => {
                let temp = {...prev};
                temp[currentFile] = {
                    ...temp[currentFile], 
                    zoom: temp[currentFile].zoom * newZoomScale,
                    position_x: newX,
                    position_y: newY,
                };
                return temp;
            })

            setPosition({x: newX, y: newY});
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const calculateAreaPoly = (polygon) => {
        let area = 0;

        polygon = polygon.sort((a, b) => a.id - b.id);

        for(let i = 0; i < polygon.length - 1; i++) {
            area += polygon[i].x * polygon[i+1].y - polygon[i+1].x * polygon[i].y;
        }

        area += polygon[polygon.length - 1].x * polygon[0].y - polygon[0].x * polygon[polygon.length - 1].y;

        return Math.abs(area / 2);
    }

    const calculateLength = (points) => {
        let totalLength = 0.0;
        
        for(let i = 0; i < points.length - 1; i++) {
            totalLength += Math.sqrt(Math.pow(points[i+1].x - points[i].x, 2) + Math.pow(points[i+1].y - points[i].y, 2));
        }

        return totalLength;
    }

    const calculateAreaRect = (shape) => {
        return Math.abs(shape.width * shape.height);
    };

    const handleDelete = () => {
        if (curObj.type === 'rectangle') {
            axios({
                method: 'delete',
                url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtraderectangle/' + curObj.id + '/',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);

                if (curObj.trade === 'individual') {
                    setIndividual(prev => {
                        let temp = {...prev};
                        delete temp.files[curObj.file].rectangles[curObj.id];
                        return temp;
                    })
                }else{
                    setTrades(prev => {
                        let temp = {...prev};
                        delete temp[curObj.trade].subtrades[curObj.subtrade].files[curObj.file].rectangles[curObj.id];
                        return temp;
                    })
                }

                setCurObj(null);
            })
            .catch((error) => {
                console.log(error);
            })
        }else if (curObj.type === 'polygon') {
            axios({
                method: 'delete',
                url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtradepolygon/' + curObj.id + '/',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);

                if(curObj.trade === 'individual'){
                    setIndividual(prev => {
                        let temp = {...prev};
                        delete temp.files[curObj.file].polygons[curObj.id];
                        return temp;
                    })
                }else{
                    setTrades(prev => {
                        let temp = {...prev};
                        delete temp[curObj.trade].subtrades[curObj.subtrade].files[curObj.file].polygons[curObj.id];
                        return temp;
                    })
                }

                setCurObj(null);
            })
            .catch((error) => {
                console.log(error);
            })
        }else if (curObj.type === 'item') {
            axios({
                method: 'delete',
                url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtradeitem/' + curObj.id + '/',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);

                if(curObj.trade === 'individual'){
                    setIndividual(prev => {
                        let temp = {...prev};
                        delete temp.files[curObj.file].items[curObj.id];
                        return temp;
                    })
                }else{
                    setTrades(prev => {
                        let temp = {...prev};
                        delete temp[curObj.trade].subtrades[curObj.subtrade].files[curObj.file].items[curObj.id];
                        return temp;
                    })
                }                

                setCurObj(null);
            })
            .catch((error) => {
                console.log(error);
            })
        }else if(curObj.type === 'length'){
            axios({
                method: 'delete',
                url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtradelength/' + curObj.id + '/',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);

                if(curObj.trade === 'individual'){
                    setIndividual(prev => {
                        let temp = {...prev};
                        delete temp.files[curObj.file].lengths[curObj.id];
                        return temp;
                    })
                }else{
                    setTrades(prev => {
                        let temp = {...prev};
                        delete temp[curObj.trade].subtrades[curObj.subtrade].files[curObj.file].lengths[curObj.id];
                        return temp;
                    })
                }

                setCurObj(null);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    const createItem = () => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/create-contractor-project-trades-takeoff-item/',
            data: {
                dots: dots,
                fileID: currentFile,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            if (measureGroup){
                axios({
                    method: 'put',
                    url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtradeitem/' + response.data.id + '/',
                    data: {
                      contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                    },
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                .then((linkResponse) => {
                    console.log(linkResponse);
                
                    setTrades(prev => {
                        const newTrades = { ...prev };
                        newTrades[measureGroup.trade].subtrades[measureGroup.subtradeID].files[currentFile].items[response.data.id] = {
                            ...response.data,
                            contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                        }
                        return newTrades;
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
            }else{
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].items[response.data.id] = response.data; 
                    return temp; 
                })              
            }

            setDots([]);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const addDots = () => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/add-contractor-project-trades-takeoff-item-dots/',
            data: {
                dots: dots,
                itemID: curObj.id,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            setDots([]);

            if (curObj.trade === 'individual') {
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].items[curObj.id].dots = response.data.dots;
                    return temp;
                })
            }else{
                setTrades(prev => {
                    let temp = {...prev};
                    temp[curObj.trade].subtrades[curObj.subtrade].files[currentFile].items[curObj.id].dots = response.data.dots;
                    return temp;
                })
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const calculateDistance = (x1, y1, x2, y2) => {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    const handleSaveScale = ({ scaleFt, scalePixels }) => {
        setDrawingScale(false);

        axios({
            method: 'put',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftradefile/' + files[currentFile].id + '/',
            data: {
                contractorprojecttakeoff: files[currentFile].contractorprojecttakeoff,
                scale: scaleFt / scalePixels,
                zoom: files[currentFile].zoom,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            setFiles(prev => {
                let temp = {...prev};
                temp[currentFile].scale = scaleFt / scalePixels;
                return temp;
            })

            setScale(scaleFt / scalePixels);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const addRectangle = (rect) => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtraderectangle/',
            data: {
                color: individual.color,
                file: currentFile,
                x: rect.x,
                y: rect.y,
                width: rect.width,
                height: rect.height,
                area: calculateAreaRect(rect),
                contractorprojecttakeoffsubtrade: measureGroup ? measureGroup.subtradeID : null,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            if (measureGroup){
                setTrades(prev => {
                    const temp = { ...prev };
                    temp[measureGroup.trade].subtrades[measureGroup.subtradeID].files[currentFile].rectangles[response.data.id] = {
                        ...response.data,
                        contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                    }
                    return temp;
                })
            }else{
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].rectangles[response.data.id] = response.data;
                    return temp;
                })
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const addPolygon = (points) => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/create-contractor-project-trades-takeoff-polygon/',
            data: {
                points: points,
                fileID: currentFile,
                area: calculateAreaPoly(points),
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            if (measureGroup){
                axios({
                    method: 'put',
                    url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtradepolygon/' + response.data.id + '/',
                    data: {
                      contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                    },
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                .then((linkResponse) => {
                    console.log(linkResponse);
        
                    setTrades(prev => {
                        const temp = { ...prev };
                        temp[measureGroup.trade].subtrades[measureGroup.subtradeID].files[currentFile].polygons[response.data.id] = {
                            ...response.data,
                            contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                        }
                        return temp;
                    })
                })
                .catch((error) => {
                    console.log(error);
                })            
            }else{
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].polygons[response.data.id] = response.data;
                    return temp;
                })    
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const addLength = (points) => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/create-contractor-project-trades-takeoff-length/',
            data: {
                dots: points,
                length: calculateLength(points),
                fileID: currentFile,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            if (measureGroup){
                axios({
                    method: 'put',
                    url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtradelength/' + response.data.id + '/',
                    data: {
                      contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                    },
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                .then((linkResponse) => {
                    console.log(linkResponse);
                
                    setTrades(prev => {
                        const newTrades = { ...prev };
                        newTrades[measureGroup.trade].subtrades[measureGroup.subtradeID].files[currentFile].lengths[response.data.id] = {
                            ...response.data,
                            contractorprojecttakeoffsubtrade: measureGroup.subtradeID,
                        }
                        return newTrades;
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
            }else{
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].lengths[response.data.id] = response.data;
                    return temp;
                })    
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleInference = ( option ) => {
        setRunningInference(true);
        setShowInferenceModal(false);

        if (option === 'Perimeter') {
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/inference-perim-contractor-project-trades-takeoff/',
                data: {
                    fileID: currentFile,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
    
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].lengths = {...temp.files[currentFile].lengths, ...response.data};
                    return temp;
                })
    
                setRunningInference(false);
            })
            .catch((error) => {
                console.log(error);
            })    
        }else if (option === 'Area') {
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/inference-contractor-project-trades-takeoff/',
                data: {
                    fileID: currentFile,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
    
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].polygons = {...temp.files[currentFile].polygons, ...response.data};
                    return temp;
                })
    
                setRunningInference(false);
            })
            .catch((error) => {
                console.log(error);
            })
        }else if (option === 'Doors') {
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/inference-doors-contractor-project-trades-takeoff/',
                data: {
                    fileID: currentFile,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
    
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].items = {...temp.files[currentFile].items, ...response.data};
                    return temp;
                })
    
                setRunningInference(false);
            })
            .catch((error) => {
                console.log(error);
            })
        }else if (option === 'Windows'){
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/inference-windows-contractor-project-trades-takeoff/',
                data: {
                    fileID: currentFile,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
    
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].items = {...temp.files[currentFile].items, ...response.data};
                    return temp;
                })
    
                setRunningInference(false);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    const handleLinkMeasureGroup = (subtradeID, trade) => {
        setMeasureGroup({
            subtradeID: subtradeID,
            trade: trade,
        })
    }

    return (
        <div className='contractortakeoff-floorplan-container'>
            <div className='floorplan-toolbar'>
                <ContractorCreatedProjectSideNav projectUUID={projectUUID} current={'Takeoff'} takeoff={true}/>

                <div>
                    <a href={'/contractor-createdproject/' + projectUUID}>
                        <button className='default-button'>Back</button>
                    </a>
                </div>
                
                <div>
                    <InfoHover3
                        message={'To add a rectangle, click to start and click again to end.'}
                    >
                        <button 
                            onClick={() => {
                                setDrawingRectangle(true);
                                setCurObj(null);
                                setShowAreas(true);
                            }}
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile || drawingScale || editingLength || deletingDots || addingItem}
                            className='default-button'
                        >   
                            + <i class="fa-solid fa-vector-square"></i>
                        </button>
                    </InfoHover3>

                    <InfoHover3
                        message={'To add a polygon, click to place a point and click the anchor point or hit "Enter" to finish. To delete the most recent point, hit "Backspace" or "Delete".'}
                    >
                        <button 
                            onClick={() => {
                                setDrawingPolygon(true);
                                setCurObj(null);
                                setShowAreas(true);
                            }}
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile || drawingScale || editingLength || deletingDots || addingItem}
                            className='default-button'
                        >
                            + <i class="fa-solid fa-draw-polygon"></i>
                        </button>
                    </InfoHover3>

                    <InfoHover3
                        message={'To add a length, click to place a point and double click or hit "Enter" to finish. To delete the most recent point, hit "Backspace" or "Delete".'}
                    >
                        <button
                            className='default-button'
                            onClick={() => {
                                setDrawingLength(true);
                                setCurObj(null);
                                setShowAreas(true);
                            }}
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile || drawingScale || editingLength || deletingDots || addingItem}
                        >
                            + <i class="fa-solid fa-diagram-project"></i>
                        </button>
                    </InfoHover3>

                    <InfoHover3
                        message={'To add items, click to place points and click "Done" or hit "Enter" to finish. To delete the most recent point, hit "Backspace" or "Delete".'}
                    >
                        <button
                            className='default-button'
                            onClick={() => {
                                setDrawingItem(true);
                                setCurObj(null);
                                setShowAreas(true);
                            }}
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile || drawingScale || editingLength || deletingDots || addingItem}
                        >
                            + <i class="fa-solid fa-neuter"></i>
                        </button>
                    </InfoHover3>

                    {drawingItem &&
                        <button 
                            className='active-button'
                            onClick={(e) => {
                                createItem();
                                setDrawingItem(false);
                            }}
                        >
                            Done
                        </button>
                    }
                    {addingItem &&
                        <button
                            className='active-button'
                            onClick={(e) => {
                                addDots();
                                setAddingItem(false);
                            }}
                        >
                            Done
                        </button>
                    }
                    {deletingDots &&
                        <button
                            className='active-button'
                            onClick={(e) => {
                                setDeletingDots(false);
                            }}
                        >
                            Done
                        </button>
                    }
                    {editingLength &&
                        <button
                            className='active-button'
                            onClick={(e) => {
                                setEditingLength(false);
                            }}
                        >
                            Done
                        </button>
                    }

                    {curObj ? <button onClick={() => handleDelete()} className='delete-button'>Delete</button> : null}
                </div>

                <div>
                    {measureGroup
                    ?   <>
                            {trades && 
                                <LinkMenu
                                    trades={trades}
                                    value={{
                                        label: trades[measureGroup.trade].subtrades[measureGroup.subtradeID].choice,
                                    }}
                                    onChange={(e) => handleLinkMeasureGroup(e.value, e.group)}
                                    type={'measure'}
                                />
                            }

                            <InfoHover3
                                message={'Click to clear choice.'}
                            >
                                <button
                                    className='default-button'
                                    onClick={() => setMeasureGroup(null)}
                                >
                                    Clear
                                </button>
                            </InfoHover3>
                        </>
                    :   <>
                            {trades && 
                                <LinkMenu
                                    trades={trades}
                                    value={{
                                        label: 'Not grouped',
                                    }}
                                    onChange={(e) => handleLinkMeasureGroup(e.value, e.group)}
                                    type={'measure'}
                                />
                            }
                        </>
                    }
                </div>
                
                <div className='floorplan-toolbar-scale'>
                    <div>
                        {files?.[currentFile]?.scale.toFixed(2)} ft/px
                    </div>

                    <InfoHover3
                        message={'To set the scale, click to place the start point and click again to place the end point.'}
                    >
                        <button
                            className={files?.[currentFile]?.scale === 1 ? 'active-button' : 'default-button'}
                            onClick={() => {
                                setDrawingScale(true);
                                setCurObj(null);
                                setShowAreas(true);
                                setScaleStart(null);
                                setScaleEnd(null);
                            }}
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile || drawingScale || editingLength || deletingDots || addingItem}
                        >
                            Scale   
                        </button>
                    </InfoHover3>

                    <ScaleModal
                        showScaleModal={showScaleModal}
                        setShowScaleModal={setShowScaleModal}
                        scaleStart={scaleStart}
                        scaleEnd={scaleEnd}
                        handleCancel={() => {
                            setScaleStart(null);
                            setScaleEnd(null);
                            setShowScaleModal(false);
                            setDrawingScale(false);
                        }}
                        onComplete={({scaleFt, scalePixels}) => handleSaveScale({ scaleFt, scalePixels })}
                        scaleLength={calculateDistance(scaleStart?.x, scaleStart?.y, scaleEnd?.x, scaleEnd?.y)}
                    />
                </div>

                <div>
                    <InfoHover3
                        message={'Click to reset the position and zoom.'}
                    >
                        <button
                            className='default-button'
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile}
                            onClick={() => {
                                const newZoomScale = files[currentFile].width > 1000 ? 1000 / files[currentFile].width : 1;

                                axios({
                                    method: 'put',
                                    url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftradefile/' + files[currentFile].id + '/',
                                    data: {
                                        contractorprojecttakeoff: files[currentFile].contractorprojecttakeoff,
                                        zoom: newZoomScale,
                                        position_x: 0,
                                        position_y: 0,
                                    },
                                    headers: {
                                        'Content-Type': 'application/json',
                                    }
                                })
                                .then((response) => {
                                    console.log(response);
                        
                                    setFiles(prev => {
                                        let temp = {...prev};
                                        temp[currentFile] = {
                                            ...temp[currentFile], 
                                            zoom: newZoomScale,
                                            position_x: 0,
                                            position_y: 0,
                                        };
                                        return temp;
                                    })
                        
                                    setPosition({x: 0, y: 0});
                                })
                                .catch((error) => {
                                    console.log(error);
                                })                        
                            }}
                        >
                            <i class="fa-solid fa-arrows-to-circle"></i>
                        </button>
                    </InfoHover3>

                    <InfoHover3
                        message={'Click to zoom in. You can also scroll in and out.'}
                    >
                        <button 
                            onClick={() => handleZoom(1.2)} 
                            className='default-button'
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile}
                        ><i class="fa-solid fa-magnifying-glass-plus"></i></button>
                    </InfoHover3>

                    <InfoHover3
                        message={'Click to zoom out. You can also scroll in and out.'}
                    >
                        <button 
                            onClick={() => handleZoom(0.8)} 
                            className='default-button'
                            disabled={drawingRectangle || drawingPolygon || drawingItem || drawingLength || !currentFile}
                        ><i class="fa-solid fa-magnifying-glass-minus"></i></button>
                    </InfoHover3>
                </div>

                <div className='floorplan-toolbar-pages'>
                    {showAreas
                    ?   <>
                            <button onClick={() => setShowAreas(false)} className='default-button'>Pages</button>
                            <button 
                                className='active-button'
                                disabled={!currentFile}
                            >Measure</button>
                        </>
                    :   <>
                            <button className='active-button'>Pages</button>
                            <button 
                                onClick={() => setShowAreas(true)} 
                                className='default-button'
                                disabled={!currentFile}
                            >Measure</button>
                        </>  
                    }
                </div>               
            </div>
            <div className='floorplan-details-container'>
                {loading
                ?   <div className="editproject-sowgeneration-spinner-container">
                        <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                        Loading...
                    </div>
                :   <div className='floorplan-floorplan-container' id='floorplan-floorplan-container'>
                        {files && Object.keys(files).length === 0 &&
                            <div className='floorplan-no-files'>
                                No pages added to take off yet. View the "Pages" tab to add individual pages from files.
                            </div>
                        }
                        
                        {currentFile && trades && files && files[currentFile] && individual &&
                            <FloorPlan 
                                url={files && files[currentFile]?.file}
                                file={files && files[currentFile]}
                                setFiles={setFiles}
                                position={position}
                                setPosition={setPosition}
                                zoomScale={files && files[currentFile]?.zoom}
                                setCurObj={setCurObj}
                                height={files && files[currentFile]?.height}
                                width={files && files[currentFile]?.width}
                            >
                                {trades && Object.values(trades)?.map((trade) =>
                                    Object.values(trade.subtrades)?.map((subtrade) =>
                                        Object.values(subtrade.files)?.map((file) =>
                                            String(file.id) === String(currentFile) &&
                                                <Group
                                                    key={subtrade.id}
                                                    trade={trade}
                                                    file={file}
                                                    group={{...subtrade, color: trade.color}}
                                                    subtrade={subtrade}
                                                    zoomScale={files[currentFile]?.zoom}
                                                    position={position}
                                                    drawingPolygon={drawingPolygon}
                                                    drawingRectangle={drawingRectangle}
                                                    drawingItem={drawingItem}
                                                    addingItem={addingItem}
                                                    setDrawingPolygon={setDrawingPolygon}
                                                    setDrawingRectangle={setDrawingRectangle}
                                                    setDrawingItem={setDrawingItem}
                                                    setAddingItem={setAddingItem}
                                                    dots={dots}
                                                    setDots={setDots}
                                                    calculateAreaPoly={calculateAreaPoly}
                                                    calculateAreaRect={calculateAreaRect}
                                                    calculateLength={calculateLength}
                                                    files={files}
                                                    setFiles={setFiles}
                                                    currentFile={currentFile}
                                                    curObj={curObj}
                                                    setCurObj={setCurObj}
                                                    height={files && files[currentFile]?.height}
                                                    width={files && files[currentFile]?.width}
                                                    setShowAreas={setShowAreas}
                                                    deletingDots={deletingDots}
                                                    drawingLength={drawingLength}
                                                    setDrawingLength={setDrawingLength}
                                                    editingLength={editingLength}
                                                    drawingScale={drawingScale}
                                                    setDrawingScale={setDrawingScale}
                                                    setScale={setScale}
                                                    setShowScaleModal={setShowScaleModal}
                                                    setScaleStart={setScaleStart}
                                                    setScaleEnd={setScaleEnd}
                                                    scaleStart={scaleStart}
                                                    scaleEnd={scaleEnd}        
                                                    setTrades={setTrades}
                                                    individual={individual}
                                                    setIndividual={setIndividual}
                                                />
                                        )
                                    )
                                )}

                                <Group
                                    key={'individual'}
                                    trade={{ trade: 'individual'}}
                                    group={individual}
                                    individual={individual}
                                    zoomScale={files[currentFile]?.zoom}
                                    file={individual.files[currentFile]}
                                    position={position}
                                    drawingPolygon={drawingPolygon}
                                    drawingRectangle={drawingRectangle}
                                    drawingItem={drawingItem}
                                    addingItem={addingItem}
                                    setDrawingPolygon={setDrawingPolygon}
                                    setDrawingRectangle={setDrawingRectangle}
                                    setDrawingItem={setDrawingItem}
                                    setAddingItem={setAddingItem}
                                    dots={dots}
                                    setDots={setDots}
                                    calculateAreaPoly={calculateAreaPoly}
                                    calculateAreaRect={calculateAreaRect}
                                    calculateLength={calculateLength}
                                    files={files}
                                    setFiles={setFiles}
                                    currentFile={currentFile}
                                    curObj={curObj}
                                    setCurObj={setCurObj}
                                    height={files && files[currentFile]?.height}
                                    width={files && files[currentFile]?.width}
                                    setShowAreas={setShowAreas}
                                    deletingDots={deletingDots}
                                    drawingLength={drawingLength}
                                    setDrawingLength={setDrawingLength}
                                    editingLength={editingLength}
                                    drawingScale={drawingScale}
                                    setDrawingScale={setDrawingScale}
                                    setScale={setScale}
                                    setShowScaleModal={setShowScaleModal}
                                    setScaleStart={setScaleStart}
                                    setScaleEnd={setScaleEnd}
                                    scaleStart={scaleStart}
                                    scaleEnd={scaleEnd}
                                    setTrades={setTrades}
                                    setIndividual={setIndividual}
                                />

                                {drawingRectangle &&
                                    <RectangleConstructor
                                        zoomScale={files[currentFile]?.zoom}
                                        position={position}
                                        currentFile={currentFile}
                                        onComplete={(rect) => {
                                            setDrawingRectangle(false);
                                            addRectangle(rect);
                                        }}
                                        width={files[currentFile]?.width}
                                        height={files[currentFile]?.height}
                                    />
                                }
                                {drawingPolygon &&
                                    <PolygonConstructor
                                        zoomScale={files[currentFile]?.zoom}
                                        position={position}
                                        currentFile={currentFile}
                                        onComplete={(points) => {
                                            setDrawingPolygon(false);
                                            addPolygon(points);
                                        }}
                                        width={files[currentFile]?.width}
                                        height={files[currentFile]?.height}
                                    />
                                }
                                {drawingItem &&
                                    <ItemConstructor
                                        zoomScale={files[currentFile]?.zoom}
                                        position={position}
                                        currentFile={currentFile}
                                        dots={dots}
                                        setDots={setDots}
                                        width={files[currentFile]?.width}
                                        height={files[currentFile]?.height}
                                    />
                                }
                                {addingItem && curObj &&
                                    <ItemAdd
                                        zoomScale={files[curObj.file]?.zoom}
                                        position={position}
                                        currentFile={currentFile}
                                        dots={dots}
                                        setDots={setDots}
                                        width={files[curObj.file]?.width}
                                        height={files[curObj.file]?.height}
                                    />
                                }
                                {drawingLength && 
                                    <LengthConstructor
                                        zoomScale={files[currentFile]?.zoom}
                                        position={position}
                                        currentFile={currentFile}
                                        onComplete={(points) => {
                                            setDrawingLength(false);
                                            addLength(points);
                                        }}
                                        width={files[currentFile]?.width}
                                        height={files[currentFile]?.height}
                                    />
                                }
                                {editingLength && curObj &&
                                    <LengthEdit
                                        zoomScale={files[curObj.file]?.zoom}
                                        position={position}
                                        files={files}
                                        setFiles={setFiles}
                                        currentFile={currentFile}
                                        curObj={curObj}
                                        width={files[curObj.file]?.width}
                                        height={files[curObj.file]?.height}
                                        trades={trades}
                                        setTrades={setTrades}
                                        individual={individual}
                                        setIndividual={setIndividual}
                                    />
                                }
                                {drawingScale &&
                                    <ScaleConstructor
                                        zoomScale={files[currentFile]?.zoom}
                                        position={position}
                                        files={files}
                                        setFiles={setFiles}
                                        currentFile={currentFile}
                                        curObj={curObj}
                                        width={files[currentFile]?.width}
                                        height={files[currentFile]?.height}
                                        setScale={setScale}
                                        setDrawingScale={setDrawingScale}
                                        scaleStart={scaleStart}
                                        setScaleStart={setScaleStart}
                                        scaleEnd={scaleEnd}
                                        setScaleEnd={setScaleEnd}
                                        setShowScaleModal={setShowScaleModal}
                                    />
                                }
                            </FloorPlan>
                        }
                    </div>
                }

                {showAreas
                ?   <div className='floorplan-area-container'>
                        <div className='floorplan-area-header-container'> 
                            <div className='floorplan-area-header'>Measurements</div>
                            {/*<button className='default-button' onClick={() => handleAddGroup()}>Add group</button>*/}

                            {runningInference
                            ?   <div className="">
                                    <div className="loading-spinner"></div>
                                </div>  
                            :   <button className='active-button' onClick={() => setShowInferenceModal(true)}>Run AI</button>
                            }

                            <InferenceModal
                                showInferenceModal={showInferenceModal}
                                setShowInferenceModal={setShowInferenceModal}
                                handleInference={handleInference}
                            />
                        </div>

                        <div>
                            {currentFile && trades && Object.values(trades)?.sort((a, b) => DivisionsOrder[a.trade] - DivisionsOrder[b.trade])?.map((trade) =>
                                Object.values(trade.subtrades)?.reduce((acc, subtrade) => {
                                    Object.values(subtrade.files).forEach((file) => {
                                        if(Object.values(file.items).length + Object.values(file.polygons).length + Object.values(file.rectangles).length + Object.values(file.lengths).length > 0){
                                            acc++;
                                        }
                                    })
                                    return acc;
                                }, 0) > 0 &&
                                <TradeDetails
                                    key={trade.id}
                                    trade={trade}
                                    files={files}
                                    calculateAreaPoly={calculateAreaPoly}
                                    calculateAreaRect={calculateAreaRect}
                                    calculateLength={calculateLength}
                                    setFiles={setFiles}
                                    currentFile={currentFile}
                                    curObj={curObj}
                                    setCurObj={setCurObj}
                                    addingItem={addingItem}
                                    setAddingItem={setAddingItem}
                                    dots={dots}
                                    setDots={setDots}
                                    setDeletingDots={setDeletingDots}
                                    setEditingLength={setEditingLength}
                                    trades={trades}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={drawingRectangle || drawingPolygon || drawingItem || drawingLength || drawingScale || editingLength || deletingDots || addingItem}
                                    measureGroup={measureGroup}
                                    setMeasureGroup={setMeasureGroup}
                                />
                            )}
                        </div>

                        <IndividualTradeDetails
                            calculateAreaPoly={calculateAreaPoly}
                            calculateAreaRect={calculateAreaRect}
                            calculateLength={calculateLength}
                            setFiles={setFiles}
                            currentFile={currentFile}
                            files={files}
                            curObj={curObj}
                            setCurObj={setCurObj}
                            addingItem={addingItem}
                            setAddingItem={setAddingItem}
                            dots={dots}
                            setDots={setDots}
                            setDeletingDots={setDeletingDots}
                            setEditingLength={setEditingLength}
                            trades={trades}
                            setTrades={setTrades}  
                            individual={individual}  
                            setIndividual={setIndividual}
                            setCurrentFile={setCurrentFile}
                            usingAction={drawingRectangle || drawingPolygon || drawingItem || drawingLength || drawingScale || editingLength || deletingDots || addingItem}
                            measureGroup={measureGroup}
                            setMeasureGroup={setMeasureGroup}
                        />
                        
                        {/*<DeletePage
                            files={files}
                            setFiles={setFiles}
                            currentFile={currentFile}
                            setCurrentFile={setCurrentFile}
                            setShowAreas={setShowAreas}
                            setTrades={setTrades}
                            setIndividual={setIndividual}
                        />*/}
                    </div>
                :   <div className='floorplan-area-container'>
                        <div>
                            <div className='floorplan-area-header-container'> 
                                <div className='floorplan-area-header'>Pages</div>
                                {(customer?.subscriptions?.data[0] || user.groups.includes(4))
                                ?   <button 
                                        className={files && Object.keys(files).length ? 'default-button' : 'active-button'} 
                                        onClick={() => setShowAddPageModal(true)}
                                    >
                                        Add page
                                    </button>
                                :   files && Object.keys(files).length < 5
                                    ?   <button 
                                            className={files && Object.keys(files).length ? 'default-button' : 'active-button'} 
                                            onClick={() => setShowAddPageModal(true)}
                                        >
                                            Add page
                                        </button>
                                    :   <InfoHover3
                                            message={'The free plan allows up to 5 pages. Upgrade to add more pages.'}
                                            left={true}
                                            bottom={true}
                                        >
                                            <button className="default-button" disabled>Add page</button>
                                        </InfoHover3>
                                }
                            </div>

                            <div className='floorplan-pages-container'>
                                {files && Object.keys(files).length 
                                ?   Object.values(files)?.map((file) => 
                                        <FileDetail
                                            key={file?.id}
                                            file={file}
                                            files={files}
                                            setFiles={setFiles}
                                            currentFile={currentFile}
                                            setCurrentFile={setCurrentFile}
                                            setPosition={setPosition}
                                            setScale={setScale}
                                            trades={trades}
                                            setTrades={setTrades}
                                            individual={individual}
                                            setIndividual={setIndividual}
                                        />
                                    )
                                :   <div className='contractortakeoff-no-pages'
                                    >
                                        Add a page to get started.
                                    </div>
                                }
                            </div>  
                                
                            <AddPageModal
                                show={showAddPageModal}
                                setShow={setShowAddPageModal}
                                projectUUID={projectUUID}
                                user={user}
                                takeOff={takeOff}
                                projectFiles={projectFiles}
                                setFiles={setFiles}
                                uploads={uploads}
                                setUploads={setUploads}
                                setIndividual={setIndividual}
                                setTrades={setTrades}
                            />
                        </div>                
                    </div>
                }
            </div>
        </div>
    );
};

function InferenceModal({ showInferenceModal, setShowInferenceModal, handleInference }) {
    return (
        <Modal
            show={showInferenceModal}
            onHide={() => setShowInferenceModal(false)}
            className='contractortakeoff-groupdetails-modal'
            onClick={(e) => e.stopPropagation()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Run AI options</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='contractortakeoff-groupdetails-modal-container'>
                    <div className='contractortakeoff-groupdetails-modal-section'>
                        {/*<div className='contractortakeoff-groupdetails-modal-section-header'>Run the AI on areas (draw polygons) or on perimeter (draw lengths)?</div>*/}

                        NOTE: The AI models here are an early prototype and may not be accurate.

                        <div className='contractortakeoff-groupdetails-modal-choice-buttons'>
                            <button className='default-button' onClick={() => handleInference('Area')}>Room areas</button>
                            <button className='default-button' onClick={() => handleInference('Perimeter')}>Room perimeters</button>
                            <button className='default-button' onClick={() => handleInference('Doors')}>Find doors</button>
                            <button className='default-button' onClick={() => handleInference('Windows')}>Find windows</button>
                        </div>
                        
                    </div>  
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className='default-button' onClick={() => setShowInferenceModal(false)}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

function TradeDetails({    
    trade,
    files,
    calculateAreaPoly,
    calculateAreaRect,
    calculateLength,
    setFiles,
    currentFile,
    curObj,
    setCurObj,
    addingItem,
    setAddingItem,
    dots,
    setDots,
    setDeletingDots,
    setEditingLength,
    trades,
    setTrades,
    individual,
    setIndividual,
    setCurrentFile,
    usingAction,
    measureGroup,
    setMeasureGroup
}){
    const [showOptions, setShowOptions] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(curObj && curObj?.trade === trade.trade){
            setShow(true);
        }
    }, [curObj])

    return (
        <div className='contractortakeoff-groupdetails-container'>
            <div 
                className='contractortakeoff-groupdetails-header'
                onClick={() => setShow(!show)}
            >
                <div className='contractortakeoff-groupdetails-title-container'>
                    <div>
                        {show
                        ?   <i class="fa-solid fa-caret-down"></i>
                        :   <i class="fa-solid fa-caret-right"></i>
                        }
                    </div>
                        
                    <div>
                        <span className='contractortakeoff-groupdetails-trade-title'>
                            {trade === 'individual' 
                            ? 'Not grouped' 
                            : trade.custom 
                                ?   trade.trade
                                :   DivisionsOrder[trade.trade] + ' ' + trade.trade
                            }
                        </span>
                    </div>            
                </div>

                <div className='contractortakeoff-groupdetails-options-container'>
                    {<div className='contractortakeoff-groupdetails-count'>
                        {Object.values(trade.subtrades)?.reduce((acc, subtrade) => {
                            Object.values(subtrade.files).forEach((file) => {
                                if(Object.values(file.items).length + Object.values(file.polygons).length + Object.values(file.rectangles).length + Object.values(file.lengths).length > 0){
                                    acc++;
                                }
                            })
                            return acc;
                        }, 0)} / {Object.values(trade.subtrades).length}
                    </div>}

                    <div 
                        className='contractortakeoff-groupdetails-color'
                        style={{'backgroundColor': trade.color}}
                        onClick={(e) => {setShowOptions(!showOptions); e.stopPropagation();}}
                    >
                        &nbsp;
                    </div>

                    <GroupDetailsModal
                        showModal={showOptions}
                        setShowModal={setShowOptions}
                        trade={trade}
                        files={files}
                        setFiles={setFiles}
                        currentFile={currentFile}
                        setTrades={setTrades}
                    />
                </div>
            </div>

            {show && 
                <div className='contractortakeoff-groupdetails-subtrades-container'>
                    {Object.values(trade.subtrades)?.filter((subtrade) => Object.values(subtrade.files)?.reduce((acc, file) => acc + Object.values(file.items).length + Object.values(file.polygons).length + Object.values(file.rectangles).length + Object.values(file.lengths).length, 0) > 0)?.map((subtrade) =>
                        <SubTradeDetails
                            key={subtrade.id}
                            subtrade={subtrade}
                            trade={trade}
                            calculateAreaPoly={calculateAreaPoly}
                            calculateAreaRect={calculateAreaRect}
                            calculateLength={calculateLength}
                            setFiles={setFiles}
                            currentFile={currentFile}
                            files={files}
                            curObj={curObj}
                            setCurObj={setCurObj}
                            dots={dots}
                            setDots={setDots}
                            addingItem={addingItem}
                            setAddingItem={setAddingItem}
                            setDeletingDots={setDeletingDots}
                            setEditingLength={setEditingLength}
                            setTrades={setTrades}
                            individual={individual}
                            setIndividual={setIndividual}
                            setCurrentFile={setCurrentFile}
                            trades={trades}
                            usingAction={usingAction}
                            measureGroup={measureGroup}
                            setMeasureGroup={setMeasureGroup}
                        />
                    )}
                </div>
            }
        </div>
    )
}

function FileDetail({
    file,
    files,
    setFiles,
    currentFile,
    setCurrentFile,
    setPosition,
    setScale,
    trades,
    individual,
    setIndividual,
    setTrades
}){
    const [nameChanged, setNameChanged] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <div 
            key={file?.id} 
            onClick={() => {
                setCurrentFile(file.id);
                setPosition({x: files[file.id].position_x, y: files[file.id].position_y});
                setScale(files[file.id].scale);
            }}
            className={String(file.id) === String(currentFile) ? 'contractortakeoff-page-container contractortakeoff-page-container-active' : 'contractortakeoff-page-container'}
        >
            <div className='contractortakeoff-page-name-container'>     
                <div className='contractortakeoff-page-count'>
                    {Object.values(trades)?.reduce((acc, trade) => {
                        return acc + Object.values(trade.subtrades)?.reduce((acc, subtrade) => {
                            return acc + Object.values(subtrade?.files[file.id].items).length + +Object.keys(subtrade?.files[file.id].polygons).length + +Object.keys(subtrade?.files[file.id].rectangles).length + +Object.keys(subtrade?.files[file.id].lengths).length;
                        }, 0);
                    }, 0) + +Object.keys(individual.files[file.id].items)?.length + +Object.keys(individual.files[file.id].polygons)?.length + +Object.keys(individual.files[file.id].rectangles)?.length + +Object.keys(individual.files[file.id].lengths)?.length}

                    &nbsp;measurement(s)
                </div>
                           
                <div
                    className='contractortakeoff-page-icon'
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(true);
                    }}
                >
                    <i className='fa-solid fa-gear'></i>
                </div>

                <FileModal
                    file={file}
                    setFiles={setFiles}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setIndividual={setIndividual}
                    setTrades={setTrades}
                />
            </div>

            <div>
                {file?.name}
            </div>
        </div>
    )
}

function FileModal({ file, setFiles, showModal, setShowModal, setIndividual, setTrades }){
    const [nameChanged, setNameChanged] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = () => {
        axios({
            method: 'delete',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftradefile/' + file.id + '/',
            headers: {
                'Content-Type': 'application/json',
            }      
        })
        .then((response) => {
            console.log(response);

            setFiles(prev => {
                let temp = {...prev};
                delete temp[file.id];
                return temp;
            })
            setIndividual(prev => {
                let temp = {...prev};
                delete temp.files[file.id];
                return temp;
            })
            setTrades(prev => {
                let temp = {...prev};
                Object.values(temp).forEach((trade) => {
                    Object.values(trade.subtrades).forEach((subtrade) => {
                        delete subtrade.files[file.id];
                    })
                })
                return temp;
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <Modal 
            show={showModal} 
            onHide={() => setShowModal(false)} 
            size='small'
            className='contractortakeoff-shapearea-options-modal'
            onClick={(e) => e.stopPropagation()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Page options
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='contractortakeoff-shapearea-modal-container-options'>
                    <div className='contractortakeoff-groupdetails-modal-section'>
                        <div className='contractortakeoff-groupdetails-modal-section-header'>Change page name</div>

                        <input
                            type='text'   
                            className='contractortakeoff-page-name-input'
                            value={file?.name}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                setFiles(prev => {
                                    let temp = {...prev};
                                    temp[file.id].name = e.target.value;
                                    return temp;
                                })

                                setNameChanged(true);
                            }}
                            onBlur={() => {
                                if(nameChanged){
                                    axios({
                                        method: 'put',
                                        url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftradefile/' + file.id + '/',
                                        data: {
                                            contractorprojecttakeoff: file.contractorprojecttakeoff,
                                            name: file.name,
                                        },
                                        headers: {
                                            'Content-Type': 'application/json',
                                        }
                                    })
                                    .then((response) => {
                                        console.log(response);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                                }
                            }}
                            placeholder='Page name...'
                        />
                    </div>

                    <br></br>

                    <div className='contractortakeoff-groupdetails-modal-section'>
                        <div className='contractortakeoff-groupdetails-modal-section-header'>Delete page</div>

                        <div className='contractortakeoff-groupdetails-modal-section-delete-warning'>
                            Deleting a page will delete all measurements on that page. This cannot be undone.
                        </div>
                            
                        {showDelete
                        ?   <div className='contractortakeoff-groupdetails-modal-section-delete'>
                                <button className='default-button' onClick={() => handleDelete()}>Delete</button>
                                <button className='active-button' onClick={() => setShowDelete(false)}>Cancel</button>
                            </div>
                        :   <button className='delete-button' onClick={() => setShowDelete(true)}>Delete page</button>
                        }
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className='default-button' onClick={() => setShowModal(false)}>Cancel</button>
            </Modal.Footer>
        </Modal>
    )
}

function IndividualTradeDetails({
    files,
    calculateAreaPoly,
    calculateAreaRect,
    calculateLength,
    setFiles,
    currentFile,
    curObj,
    setCurObj,
    addingItem,
    setAddingItem,
    dots,
    setDots,
    setDeletingDots,
    setEditingLength,
    trades,
    setTrades,
    individual,
    setIndividual,
    setCurrentFile,
    usingAction,
    measureGroup,
    setMeasureGroup
}){
    const [showOptions, setShowOptions] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        if(curObj && curObj?.trade === 'individual'){
            setShowDetails(true);
        }
    }, [curObj])

    return (
        <div 
            className='contractortakeoff-groupdetails-container'
            style={{ borderColor: measureGroup ? 'lightgray' : individual?.color }}
        >
            <div 
                className='contractortakeoff-groupdetails-header'
                onClick={(e) => {
                    setShowDetails(!showDetails); 
                    e.stopPropagation();
                    setMeasureGroup(null);
                }}
            >
                <div className='contractortakeoff-groupdetails-title-container'>
                    <div>
                        {showDetails
                        ?   <i class="fa-solid fa-caret-down"></i>
                        :   <i class="fa-solid fa-caret-right"></i>
                        }
                    </div>
                    
                    <div>
                        <span className='contractortakeoff-groupdetails-trade-title'>Not grouped</span>
                    </div>
                </div>

                <div className='contractortakeoff-groupdetails-options-container'>
                    <div className='contractortakeoff-groupdetails-count'>
                        {individual.files && Object.values(individual.files).reduce((acc, file) => 
                            acc += +Object.values(file.items).length + +Object.values(file.polygons).length + +Object.values(file.rectangles).length + +Object.values(file.lengths).length
                        , 0)}
                    </div>

                    <div 
                        className='contractortakeoff-groupdetails-color'
                        style={{'backgroundColor': individual?.color}}
                        onClick={(e) => {setShowOptions(!showOptions); e.stopPropagation();}}
                    >
                        &nbsp;
                    </div>

                    <GroupDetailsModal
                        showModal={showOptions}
                        setShowModal={setShowOptions}
                        group={{...individual, trade: 'individual'}}
                        files={files}
                        setFiles={setFiles}
                        currentFile={currentFile}
                        setTrades={setTrades}
                        setIndividual={setIndividual}
                    />
                </div>
            </div>

            {showDetails && Object.values(individual.files).reduce((acc, file) => {
                acc += +Object.values(file.items).length + +Object.values(file.polygons).length + +Object.values(file.rectangles).length + +Object.values(file.lengths).length;
                return acc;
            }, 0) > 0 &&            
                <div className='contractortakeoff-groupdetails-individual-container'>
                    {Object.values(individual.files)?.map((file) =>
                        <div key={file.id}>
                            {file.polygons && Object.values(file.polygons)?.map((polygon) =>
                                <ShapeArea
                                    key={polygon.id + 'poly'}
                                    area={calculateAreaPoly(polygon.points)}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={individual}
                                    shape={polygon}
                                    type='polygon'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    scale={files[String(file.id)].scale}
                                    trade={'individual'}
                                    trades={trades}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}

                            {file.rectangles && Object.values(file.rectangles)?.map((rectangle) =>
                                <ShapeArea
                                    key={rectangle.id + 'rect'}
                                    area={calculateAreaRect(rectangle)}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={individual}
                                    shape={rectangle}
                                    type='rectangle'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    scale={files[String(file.id)].scale}
                                    trade={'individual'}
                                    trades={trades}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}

                            {file.items && Object.values(file.items)?.map((item) =>
                                <ShapeArea
                                    key={item.id + 'item'}
                                    area={Object.values(item?.dots)?.length}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={individual}
                                    shape={item}
                                    type='item'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    dots={dots}
                                    setDots={setDots}
                                    addingItem={addingItem}
                                    setAddingItem={setAddingItem}
                                    setDeletingDots={setDeletingDots}
                                    scale={files[String(file.id)].scale}
                                    trade={'individual'}
                                    trades={trades}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}

                            {file.lengths && Object.values(file.lengths)?.map((length) =>
                                <ShapeArea
                                    key={length.id + 'length'}
                                    area={length?.area ? parseFloat(length?.area) : parseFloat(calculateLength(length.dots))}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={individual}
                                    shape={length}
                                    type='length'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    setEditingLength={setEditingLength}
                                    scale={files[String(file.id)].scale}
                                    trade={'individual'}
                                    trades={trades}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

function SubTradeDetails({
    subtrade,
    trade,
    calculateAreaPoly,
    calculateAreaRect,
    calculateLength,
    setFiles,
    currentFile,
    files,
    curObj,
    setCurObj,
    dots,
    setDots,
    addingItem,
    setAddingItem,
    setDeletingDots,
    setEditingLength,
    file,
    setTrades,
    individual,
    setIndividual,
    setCurrentFile,
    trades,    
    usingAction,
    measureGroup,
    setMeasureGroup,
}){
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        if(curObj && curObj?.subtrade === subtrade.id){
            setShow(true);
        }
    }, [curObj])

    return (
        <div 
            className='contractortakeoff-subsectiondetails-container'
            style={{ borderColor: measureGroup?.subtradeID === subtrade?.id ? trade.color : 'lightgray' }}
        >
            <div 
                className='contractortakeoff-subsectiondetails-header'
                onClick={() => {
                    setShow(!show);
                    setMeasureGroup({trade: trade.trade, subtradeID: subtrade.id});
                }}
                onMouseOver={() => setShowOptions(true)}
                onMouseOut={() => setShowOptions(false)}
            >
                <div className='contractortakeoff-subsectiondetails-title-container'>
                    <div>
                        {show
                        ?   <i class="fa-solid fa-caret-down"></i>
                        :   <i class="fa-solid fa-caret-right"></i>
                        }
                    </div>
                        
                    <div>
                        <span className='contractortakeoff-subsectiondetails-title'>{trade === 'individual' ? <></> : <>{subtrade.group} - {subtrade.choice}</>}</span>        
                    </div>            
                </div>

                {showOptions ?
                    <div onClick={(e) => {setShowModal(true); e.stopPropagation();}}>
                        <i class="fa-solid fa-gear"></i>
                    </div>
                :   <div>&nbsp;</div>}

                <SubtradeModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    subtrade={subtrade}
                    setTrades={setTrades}
                    trade={trade}
                    measureGroup={measureGroup}
                    setMeasureGroup={setMeasureGroup}
                />
            </div>

            {show &&
                <div className='contractortakeoff-subsectiondetails-shapes'>
                    {Object.values(subtrade.files)?.map((file) =>
                        <div key={file.id}>
                            {file.polygons && Object.values(file.polygons)?.map((polygon) =>
                                <ShapeArea
                                    key={polygon.id + 'poly'}
                                    area={calculateAreaPoly(polygon.points)}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={subtrade}
                                    shape={polygon}
                                    type='polygon'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    scale={files[file.id].scale}
                                    trade={trade}   
                                    trades={trades}
                                    subtrade={subtrade}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}
                            {file.rectangles && Object.values(file.rectangles)?.map((rectangle) =>
                                <ShapeArea
                                    key={rectangle.id + 'rect'}
                                    area={calculateAreaRect(rectangle)}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={subtrade}
                                    shape={rectangle}
                                    type='rectangle'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    scale={files[file.id].scale}
                                    trade={trade}   
                                    trades={trades}
                                    subtrade={subtrade}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}
                            {file.items && Object.values(file.items)?.map((item) =>
                                <ShapeArea
                                    key={item.id + 'item'}
                                    area={Object.values(item?.dots)?.length}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={subtrade}
                                    shape={item}
                                    type='item'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    dots={dots}
                                    setDots={setDots}
                                    addingItem={addingItem}
                                    setAddingItem={setAddingItem}
                                    setDeletingDots={setDeletingDots}
                                    scale={files[file.id].scale}
                                    trade={trade}   
                                    trades={trades}
                                    subtrade={subtrade}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}
                            {file.lengths && Object.values(file.lengths)?.map((length) =>
                                <ShapeArea
                                    key={length.id + 'length'}
                                    area={length?.area ? parseFloat(length?.area) : parseFloat(calculateLength(length.dots))}
                                    setCurObj={setCurObj}
                                    curObj={curObj}
                                    group={subtrade}
                                    shape={length}
                                    type='length'
                                    currentFile={currentFile}
                                    files={files}
                                    setFiles={setFiles}
                                    setEditingLength={setEditingLength}
                                    scale={files[file.id].scale}
                                    trade={trade}   
                                    trades={trades}
                                    subtrade={subtrade}
                                    setTrades={setTrades}
                                    individual={individual}
                                    setIndividual={setIndividual}
                                    setCurrentFile={setCurrentFile}
                                    usingAction={usingAction}
                                />
                            )}  
                        </div>
                    )}

                    {Object.values(subtrade.files)?.reduce((acc, file) => acc + +(Object.values(file.lengths)?.filter((length) => !length?.height).length), 0) > 1 &&
                        <div className='contractortakeoff-subsectiondetails-total'>
                            Total length: {Object.values(subtrade.files)?.reduce((acc, file) => acc + Object.values(file.lengths).filter((length) => !length.height).reduce((acc, length) => acc + +((length?.pitch) ? length.length * files[file.id].scale / (Math.cos(Math.atan(length.pitch / 12))) : length.length * files[file.id].scale), 0), 0).toFixed(1)} ft
                        </div>
                    }

                    {Object.values(subtrade.files)?.reduce((acc, file) => acc + +Object.values(file.rectangles)?.filter((rectangle) => !rectangle?.depth).length
                        + +Object.values(file.polygons)?.filter((polygon) => !polygon?.depth).length
                        + +Object.values(file.lengths)?.filter((length) => length?.height > 0).length, 0) > 1 &&
                        <div className='contractortakeoff-subsectiondetails-total'>
                            Total area: {(+Object.values(subtrade.files)?.reduce((acc, file) => acc + +Object.values(file.rectangles)?.filter((rectangle) => !rectangle?.depth).reduce((acc, rectangle) => acc + +(rectangle?.pitch > 0 ? rectangle.area * files[file.id].scale * files[file.id].scale / (Math.cos(Math.atan(rectangle.pitch / 12))) : rectangle.area * files[file.id].scale * files[file.id].scale), 0)
                                + +Object.values(file.polygons)?.filter((polygon) => !polygon?.depth).reduce((acc, polygon) => acc + +(polygon?.pitch > 0 ? polygon?.area * files[file.id].scale * files[file.id].scale / (Math.cos(Math.atan(polygon.pitch / 12))) : polygon?.area * files[file.id].scale * files[file.id].scale), 0)
                                + +Object.values(file.lengths)?.filter((length) => length?.height > 0 && !length?.pitch).reduce((acc, length) => acc + +(length.length * length.height * files[file.id].scale), 0), 0)).toFixed(1)} ft<sup>2</sup>
                        </div>
                    }

                    {Object.values(subtrade.files)?.reduce((acc, file) => acc + +Object.values(file.rectangles)?.filter((rectangle) => rectangle?.depth > 0).length
                        + +Object.values(file.polygons)?.filter((polygon) => polygon?.depth > 0).length, 0) > 1 &&
                        <div className='contractortakeoff-subsectiondetails-total'>
                            Total volume: {(+Object.values(subtrade.files)?.reduce((acc, file) => acc + +Object.values(file.rectangles)?.filter((rectangle) => rectangle?.depth > 0 && !rectangle?.pitch).reduce((acc, rectangle) => acc + +(rectangle.area * rectangle.depth * files[file.id].scale * files[file.id].scale), 0)
                                + +Object.values(file.polygons)?.filter((polygon) => polygon?.depth > 0 && !polygon?.pitch).reduce((acc, polygon) => acc + +(polygon?.area * polygon.depth * files[file.id].scale * files[file.id].scale), 0), 0)).toFixed(1)} ft<sup>3</sup>
                        </div>
                    }

                    {Object.values(subtrade.files)?.reduce((acc, file) => acc + +Object.values(file.items).length, 0) > 1 &&
                        <div className='contractortakeoff-subsectiondetails-total'>
                            Total count: {Object.values(subtrade.files)?.reduce((acc, file) => acc + +Object.values(file.items).reduce((acc, item) => acc + +Object.values(item?.dots)?.length, 0), 0)}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

function SubtradeModal({
    showModal,
    setShowModal,
    subtrade,
    setTrades,
    trade,
    measureGroup, 
    setMeasureGroup,
}) {
    return (
        <Modal 
            show={showModal} 
            onHide={() => setShowModal(false)} 
            size='small'
            className='contractortakeoff-shapearea-options-modal'
            onClick={(e) => e.stopPropagation()}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Subsection Options
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='contractortakeoff-shapearea-options-modal-section'>
                    <div className='contractortakeoff-groupdetails-modal-section'>
                        <div className='contractortakeoff-groupdetails-modal-section-header'>Measure for this subsection</div>
                        <div className='contractortakeoff-groupdetails-modal-section-delete-warning'>
                            All new measurements will be added to this subsection. You can also change this in the dropdown on the middle of the toolbar.
                        </div>

                        <div>
                            {measureGroup?.subtradeID === subtrade.id
                            ?   <button
                                    className='default-button'
                                    onClick={() => {
                                        setMeasureGroup(null);
                                        setShowModal(false);
                                    }}
                                >
                                    Clear selection
                                </button>
                            :   <button
                                    className='active-button'
                                    onClick={() => {
                                        setMeasureGroup({
                                            subtradeID: subtrade.id,        
                                            trade: trade.trade,
                                        });
                                        setShowModal(false);
                                    }}
                                >
                                    Select
                                </button>
                            }
                        </div>
                    </div>
                </div>

                <br></br>

                <div className='contractortakeoff-shapearea-options-modal-section'>
                    <div className='contractortakeoff-groupdetails-modal-section'>
                        <div className='contractortakeoff-groupdetails-modal-section-header'>Unit type</div>
                        <div className='contractortakeoff-groupdetails-modal-section-delete-warning'>
                            If there are multiple unit types in this subsection, please choose the unit type that you would like to use for building a bid with.
                        </div>

                        <div>
                            <select
                                className='contractortakeoff-groupdetails-modal-section-input'
                                value={subtrade.type}
                                onChange={(e) => {
                                    setTrades(prev => {
                                        let temp = {...prev};
                                        temp[trade.trade].subtrades[subtrade.id].type = e.target.value;
                                        return temp;
                                    })

                                    axios({
                                        method: 'put',
                                        url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtrade/' + subtrade.id + '/',
                                        data: {
                                            type: e.target.value,
                                        },
                                        headers: {
                                            'Content-Type': 'application/json',
                                        }
                                    })
                                    .then((response) => {
                                        console.log(response);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                                }}
                            >
                                <option value=''>Choose</option>
                                <option value='count'>Count</option>
                                <option value='length'>Length</option>
                                <option value='area'>Area</option>
                                <option value='volume'>Volume</option>
                            </select>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button
                    className='default-button'
                    onClick={() => setShowModal(false)}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

function DeletePage({ files, setFiles, currentFile, setCurrentFile, setShowAreas, setTrades, setIndividual }){
    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = () => {
        axios({
            method: 'delete',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftradefile/' + currentFile + '/',
            headers: {
                'Content-Type': 'application/json',
            }      
        })
        .then((response) => {
            console.log(response);
            let temp = currentFile;

            setCurrentFile(null);
            setFiles(prev => {
                let temp = {...prev};
                delete temp[currentFile];
                return temp;
            })
            setIndividual(prev => {
                let temp = {...prev};
                delete temp.files[currentFile];
                return temp;
            })
            setTrades(prev => {
                let temp = {...prev};
                Object.values(temp).forEach((trade) => {
                    Object.values(trade.subtrades).forEach((subtrade) => {
                        delete subtrade.files[currentFile];
                    })
                })
                return temp;
            })

            setShowAreas(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div className='contractortakeoff-deletepage-container'>
            {showDelete
            ?   <div>
                    <div className='contractortakeoff-groupdetails-modal-section-delete-warning'>Are you sure you want to delete this page? All your measurements will be deleted as well</div>
                    <div className='contractortakeoff-deletepage-buttons'>
                        <button
                            className='delete-button'
                            onClick={() => handleDelete()}
                        >
                            Delete
                        </button>
                        <button
                            className='default-button'
                            onClick={() => setShowDelete(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            :
                <button 
                    className='delete-button'
                    onClick={() => setShowDelete(true)}
                >Delete page</button>
            }
        </div>
    )
}

function ScaleModal({ showScaleModal, setShowScaleModal, onComplete, scaleLength, handleCancel }){
    const [scale, setScale] = useState(0);
    const [inches, setInches] = useState(0);

    return (
        <Modal show={showScaleModal} onHide={() => setShowScaleModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Enter Scale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    The line you drew is {scaleLength.toFixed(0)} pixels long. Please give the length of the corresponding line in feet.
                </div>

                <div className='contractortakeoff-scale-input-container'>
                    <input 
                        type="number" 
                        className='contractortakeoff-scale-input'
                        placeholder='Feet'
                        onChange={(e) => setScale(e.target.value)} 
                        min={0} 
                    />
                    <input
                        type="number"
                        className='contractortakeoff-scale-input'
                        placeholder='Inches'
                        onChange={(e) => setInches(e.target.value)}
                        min={0}
                        max={11}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button 
                    className='default-button'
                    onClick={() => {setShowScaleModal(false); handleCancel();}}
                >
                    Cancel
                </button>
                
                {scale || inches ?
                    <button 
                        className='active-button'
                        onClick={() => {
                            onComplete({ scaleFt: (scale ? parseFloat(scale) : 0) + (inches ? parseFloat(inches) / 12 : 0), scalePixels: parseFloat(scaleLength) });
                            setShowScaleModal(false);
                            setInches(0);
                            setScale(0);
                        }}
                    >
                        Save
                    </button>
                : null }
            </Modal.Footer>
        </Modal>
    );
}

function GroupDetailsModal({
    showModal,
    setShowModal,
    group,
    trade,
    files,
    setFiles,
    currentFile,
    setTrades,
    setIndividual
}){
    const [color, setColor] = useState(trade ? trade.color : group.color);

    const handleSaveColor = () => {
        axios({
            method: 'put',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeofftrade/' + (trade ? trade.id : group.id) + '/',
            data: {
                color: color,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            if(group?.trade === 'individual'){
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.color = color;
                    return temp;
                })
            }else{
                setTrades(prev => {
                    let temp = {...prev};    
                    temp[trade.trade].color = color;
                    return temp;
                })
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            className='contractortakeoff-groupdetails-modal'
            onClick={(e) => e.stopPropagation()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Trade options</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='contractortakeoff-groupdetails-modal-container'>
                    <div className='contractortakeoff-groupdetails-modal-section'>
                        <div className='contractortakeoff-groupdetails-modal-section-header'>Change group color</div>

                        <input
                            type='color'
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            onBlur={() => handleSaveColor()}
                        />
                    </div>  
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className='default-button' onClick={() => setShowModal(false)}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}


function Group({ 
    group, 
    subtrade,
    file,
    trade,
    zoomScale, 
    position, 
    drawingPolygon, 
    drawingRectangle, 
    drawingItem,
    addingItem,
    setDrawingPolygon, 
    setDrawingRectangle,
    setDrawingItem,
    setAddingItem,
    dots,
    setDots,
    calculateAreaPoly,
    calculateAreaRect,
    calculateLength,
    files,
    setFiles,
    currentFile,
    curObj,
    setCurObj,
    width,
    height,
    setShowAreas,
    deletingDots,
    drawingLength,
    setDrawingLength,
    editingLength,
    drawingScale,
    setDrawingScale,
    setScale,
    scaleStart,
    setScaleStart,
    setScaleEnd,
    scaleEnd,
    setShowScaleModal,
    setTrades,
    individual,
    setIndividual,
}){
    const handleRectTransform = (rect) => {
        let newArea = calculateAreaRect(rect);

        if (trade.trade === 'individual'){
            setIndividual(prev => {
                let temp = {...prev};
                temp.files[currentFile].rectangles[rect.id] = {
                    ...temp.files[currentFile].rectangles[rect.id],
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    height: rect.height,
                    area: newArea,
                }
                return temp;
            })
        }else{
            setTrades(prev => {
                let temp = {...prev};
                temp[trade.trade].subtrades[curObj.subtrade].files[currentFile].rectangles[rect.id] = {
                    ...temp[trade.trade].subtrades[curObj.subtrade].files[currentFile].rectangles[rect.id],
                    x: rect.x,
                    y: rect.y,
                    width: rect.width,
                    height: rect.height,
                    area: newArea,
                }
                return temp;
            })
        }

        axios({
            method: 'put',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffsubtraderectangle/' + rect.id + '/',
            data: {
                x: rect.x,
                y: rect.y,
                width: rect.width,
                height: rect.height,
                area: newArea,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handlePolygonTransform = (polygon) => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/update-contractor-project-trades-takeoff-polygon/',
            data: {
                points: polygon.points,
                polygonID: polygon.id,
                area: calculateAreaPoly(polygon.points),
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);

            if (trade.trade === 'individual'){
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].polygons[polygon.id] = response.data;
                    return temp;   
                })
            }else {
                setTrades(prev => {
                    let temp = {...prev};
                    temp[trade.trade].subtrades[curObj.subtrade].files[currentFile].polygons[polygon.id] = response.data;
                    return temp;
                })
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const handleLengthTransform = (length) => {
        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/update-contractor-project-trades-takeoff-length/',
            data: {
                dots: length.dots,
                lengthID: length.id,
                length: parseFloat(calculateLength(length.dots)),
            },
        })
        .then((response) => {
            console.log(response);

            if(trade.trade === 'individual'){
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[currentFile].lengths[length.id] = response.data;
                    return temp;
                })
            }else{
                setTrades(prev => {
                    let temp = {...prev};
                    temp[trade.trade].subtrades[curObj.subtrade].files[currentFile].lengths[length.id] = response.data;
                    return temp;
                })
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <>
            {file?.polygons && Object.values(file?.polygons).map((polygon) =>
                <Polygon
                    key={polygon.id}
                    zoomScale={zoomScale}
                    curObj={curObj}
                    setCurObj={setCurObj}
                    polygon={polygon}
                    setFiles={setFiles}
                    currentFile={currentFile}
                    group={{...group, file: file.id}}
                    onTransform={(polygon) => handlePolygonTransform(polygon)}
                    setShowAreas={setShowAreas}
                    trade={{...trade, trade: trade ? trade.trade : 'individual'}}
                    subtrade={subtrade}
                    setTrades={setTrades}
                    setIndividual={setIndividual}
                    calculateAreaPoly={calculateAreaPoly}
                    position={position}
                />
            )}
            {file?.rectangles && Object.values(file.rectangles)?.map((rectangle) =>
                <Rectangle
                    key={rectangle.id}
                    {...rectangle}                    
                    zoomScale={zoomScale}
                    setCurObj={setCurObj}
                    curObj={curObj}
                    group={{...group, file: file.id}}
                    drawingRectangle={drawingRectangle}
                    drawingPolygon={drawingPolygon}
                    onTransform={(rect) => handleRectTransform(rect)}
                    setShowAreas={setShowAreas}
                    trade={{...trade, trade: trade ? trade.trade : 'individual'}}
                    subtrade={subtrade}
                    setTrades={setTrades}
                    setIndividual={setIndividual}
                />
            )}
            {file?.items && Object.values(file.items).map((item) =>    
                <Item
                    key={item.id}
                    item={item}
                    group={{...group, file: file.id}}
                    curObj={curObj}
                    setCurObj={setCurObj}   
                    currentFile={currentFile}        
                    zoomScale={zoomScale}      
                    setShowAreas={setShowAreas}     
                    deletingDots={deletingDots}
                    setFiles={setFiles}
                    trade={{...trade, trade: trade ? trade.trade : 'individual'}}
                    subtrade={subtrade}
                    setTrades={setTrades}
                    setIndividual={setIndividual}
                />
            )}
            {file?.lengths && Object.values(file.lengths).map((length) =>
                <Length
                    key={length.id}
                    zoomScale={zoomScale}
                    curObj={curObj}
                    setCurObj={setCurObj}
                    length={length}
                    setFiles={setFiles}
                    currentFile={currentFile}
                    group={{...group, file: file.id}}
                    onTransform={(length) => handleLengthTransform(length)}
                    setShowAreas={setShowAreas}
                    trade={{...trade, trade: trade ? trade.trade : 'individual'}}
                    subtrade={subtrade}
                    setTrades={setTrades}
                    setIndividual={setIndividual}
                    position={position}
                    calculateLength={calculateLength}
                />
            )}
        </>
    )
}

function AddPageModal({ show, setShow, projectUUID, user, takeOff, projectFiles, setFiles, uploads, setUploads, setIndividual, setTrades }){        
    return (
        <Modal show={show} onHide={() => setShow(false)} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Add pages</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='contractortakeoff-addpage-page-container'>
                    <p>You can add images or pages from pdf files that are uploaded by the owner. You can also upload new files to add pages from. Add an page to start measuring!</p>

                    If pages start becoming too cluttered, you can add multiple versions of the same page, measurements are added together across all pages.              
                </div>

                <div className='contractortakeoff-addpage-page-container-uploadfile'>
                    <div>
                        <div className='contractortakeoff-addpage-page-name'>
                            Upload file
                        </div>

                        <div className='contractortakeoff-addpage-upload-container'>
                            <UploadFile
                                takeOff={takeOff}
                                setUploads={setUploads}
                            />
                        </div>
                    </div>
                    <div>
                        After an image successfully uploads, you can add pages from it by clicking "Add". If you want to add a page from a pdf file, you can upload it and then enter the page number you want to add. 
                    </div>
                </div>

                <div>
                    {uploads && Object.values(uploads).sort((a, b) => b.id - a.id).map((file) =>
                        <div 
                            key={file.id}
                            className='contractortakeoff-addpage-page-container'
                        >
                            <div className='contractortakeoff-addpage-page-header'>
                                <div className='contractortakeoff-addpage-page-name'>
                                    {file.name}
                                </div>
                                <div>Uploaded here</div>
                            </div>
                            
                            <div>
                                {file.file.includes('pdf') 
                                ?   <div>
                                        {/*<div>This is a pdf file. Please enter the page you want to add.</div>*/}
                                        <SelectPDF
                                            file={file}
                                            projectUUID={projectUUID}
                                            user={user}
                                            takeOff={takeOff}
                                            setFiles={setFiles}
                                            upload={true}
                                            setIndividual={setIndividual}
                                            setTrades={setTrades}
                                        />
                                        {/*<iframe
                                            src={file.file}
                                            height="400px"
                                            width="100%"
                                        />*/}
                                    </div>
                                :   <div>
                                        <AddImage
                                            file={file}
                                            setFiles={setFiles}
                                            takeOff={takeOff}
                                            upload={true}
                                            setIndividual={setIndividual}
                                            setTrades={setTrades}
                                        />
                                        <div className='contractortakeoff-addpage-image-container'>
                                            <img
                                                src={file.file}
                                                height={400}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </div>

                <div>
                    {projectFiles && Object.values(projectFiles).map((file) =>
                        <div 
                            key={file.id}
                            className='contractortakeoff-addpage-page-container'
                        >
                            <div className='contractortakeoff-addpage-page-header'>
                                <div className='contractortakeoff-addpage-page-name'>
                                    {file.name}
                                </div>

                                <div>Project file</div>
                            </div>

                            <div>
                                {file.file.includes('pdf') 
                                ?   <div>
                                        {/*<div>This is a pdf file. Please enter the page you want to add.</div>*/}
                                        <SelectPDF
                                            file={file}
                                            projectUUID={projectUUID}
                                            user={user}
                                            takeOff={takeOff}
                                            setFiles={setFiles}
                                            setIndividual={setIndividual}
                                            setTrades={setTrades}
                                        />
                                        {/*<iframe
                                            src={file.file}
                                            height="400px"
                                            width="100%"
                                        />*/}
                                    </div>
                                :   <div>
                                        <AddImage
                                            file={file}
                                            setFiles={setFiles}
                                            takeOff={takeOff}
                                            setIndividual={setIndividual}
                                            setTrades={setTrades}
                                        />
                                        <div className='contractortakeoff-addpage-image-container'>
                                            <img
                                                src={file.file}
                                                height={400}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </div>
                
            </Modal.Body>

            <Modal.Footer>
                <button className="default-button dashboard-createproject-closebutton" onClick={() => setShow(false)}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

function UploadFile({ takeOff, setUploads }){
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitButton, setSubmitButton] = useState(false);

    const submit = () => {
        setLoading(true);

        axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/contractorprojecttakeoffupload/',
            data: {
                contractorprojecttakeoff: takeOff.id,
                file: file,
                name: file.name,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then((response) => {
            console.log(response);

            setUploads(prev => ({
                ...prev,
                [response.data.id]: response.data,
            }))

            setLoading(false);
            setSubmitButton(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div>
            <input
                type='file'
                accept='.pdf, .png, .jpg, .jpeg'
                onChange={(e) => {setFile(e.target.files[0]); setSubmitButton(true);}}
            />
            <div>{submitButton ? <button onClick={() => {submit(); setLoading(true)}} className='active-button'>Submit</button> : null}</div>

            {loading &&
                <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...
                </div>  
            }
        </div>
    )
}

function AddImage({ setFiles, takeOff, file, upload, setIndividual, setTrades }){
    const [loading, setLoading] = useState(false);

    const handleAdd = (id) => {
        setLoading(true);

        if (upload) {
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/add-image-contractor-project-trades-takeoff-page/',
                data: {
                    takeoffID: takeOff.id,
                    uploadID: id,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);

                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[response.data.file.id] = {
                        ...response.data.file,
                        'items': {},
                        'polygons': {},
                        'rectangles': {},
                        'lengths': {},
                    }
                    return temp;
                })

                setTrades(prev => {
                    let temp = {...prev};
                    Object.values(temp).forEach((trade) => {
                        Object.values(trade.subtrades).forEach((subtrade) => {
                            subtrade.files[response.data.file.id] = {
                                ...response.data.file,
                                'items': {},
                                'polygons': {},
                                'rectangles': {},
                                'lengths': {},
                            }
                        })
                    })
                    return temp;
                })

                setLoading(false);
                setFiles(prev => ({
                    ...prev,
                    [response.data.file.id]: {
                        ...response.data.file,
                        position_x: 0,
                        position_y: 0,
                    }
                }))

                toast.success('Page added!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch((error) => {
                console.log(error);
            })
        } else {
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/add-image-contractor-project-trades-takeoff-page/',
                data: {
                    takeoffID: takeOff.id,
                    fileID: id
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
                
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[response.data.file.id] = {
                        ...response.data.file,
                        'items': {},
                        'polygons': {},
                        'rectangles': {},
                        'lengths': {},
                    }
                    return temp;
                })

                setTrades(prev => {
                    let temp = {...prev};
                    Object.values(temp).forEach((trade) => {
                        Object.values(trade.subtrades).forEach((subtrade) => {
                            subtrade.files[response.data.file.id] = {
                                ...response.data.file,
                                'items': {},
                                'polygons': {},
                                'rectangles': {},
                                'lengths': {},
                            }
                        })
                    })
                    return temp;
                })

                setLoading(false);
    
                setFiles(prev => ({
                    ...prev,
                    [response.data.file.id]: {
                        ...response.data.file,
                        position_x: 0,
                        position_y: 0,
                    }
                }))

                toast.success('Page added!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    return (
        <>
            {loading
            ?   <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...
                </div>
            :   <button className='default-button' onClick={() => handleAdd(file.id)}>Add</button>
            }
        </>

    )
}

function SelectPDF({ file, projectUUID, user, takeOff, setFiles, upload, setIndividual, setTrades }){
    const [pdf, setPdf] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const [selected, setSelected] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
                const response = await axios.get(file.file, {
                    responseType: 'arraybuffer',
                });
                console.log(response);
                const pdfData = new Uint8Array(response.data);
        
                const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
        
                setNumPages(pdf.numPages);
                setPdf(pdf);

                console.log(pdf.numPages);
            } catch (error) {
                console.error('Error retrieving PDF:', error);
            }
        };
    
        fetchData();
      }, [file]);

    const handleAddPages = () => {
        setLoading(true);

        if (upload){
            selected.forEach((pageNumber) => {
                axios({
                    method: 'post',
                    url: 'https://bob-backendapi.herokuapp.com/api/add-pdf-contractor-project-trades-takeoff-page/',
                    data: {
                        takeoffID: takeOff.id,
                        uploadID: file.id,
                        pageNumber: pageNumber + 1,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) => {
                    console.log(response);
    
                    setIndividual(prev => {
                        let temp = {...prev};
                        temp.files[response.data.file.id] = {
                            ...response.data.file,
                            'items': {},
                            'polygons': {},
                            'rectangles': {},
                            'lengths': {},
                        }
                        return temp;
                    })
    
                    setTrades(prev => {
                        let temp = {...prev};
                        Object.values(temp).forEach((trade) => {
                            Object.values(trade.subtrades).forEach((subtrade) => {
                                subtrade.files[response.data.file.id] = {
                                    ...response.data.file,
                                    'items': {},
                                    'polygons': {},
                                    'rectangles': {},
                                    'lengths': {},
                                }
                            })
                        })
                        return temp;
                    })
    
                    setLoading(false);
                    setFiles(prev => ({
                        ...prev,
                        [response.data.file.id]: {
                            ...response.data.file,
                            position_x: 0,
                            position_y: 0,
                        }
                    }))    

                    toast.success('Page added!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
            })
        } else {
            selected.forEach((pageNumber) => {
                axios({
                    method: 'post',
                    url: 'https://bob-backendapi.herokuapp.com/api/add-pdf-contractor-project-trades-takeoff-page/',
                    data: {
                        takeoffID: takeOff.id,
                        fileID: file.id,
                        pageNumber: pageNumber + 1,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then((response) => {
                    console.log(response);
        
                    setIndividual(prev => {
                        let temp = {...prev};
                        temp.files[response.data.file.id] = {
                            ...response.data.file,
                            'items': {},
                            'polygons': {},
                            'rectangles': {},
                            'lengths': {},
                        }
                        return temp;
                    })
    
                    setTrades(prev => {
                        let temp = {...prev};
                        Object.values(temp).forEach((trade) => {
                            Object.values(trade.subtrades).forEach((subtrade) => {
                                subtrade.files[response.data.file.id] = {
                                    ...response.data.file,
                                    'items': {},
                                    'polygons': {},
                                    'rectangles': {},
                                    'lengths': {},
                                }
                            })
                        })
                        return temp;
                    })
    
                    setLoading(false);
                    setFiles(prev => ({
                        ...prev,
                        [response.data.file.id]: {
                            ...response.data.file,
                            position_x: 0,
                            position_y: 0,
                        }
                    }))
                            
                    toast.success('Page added!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
            })    
        }
    }

    return (
        <div>
            {loading 
            ?   <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...
                </div>
            :   <div className='contractortakeoff-pdfpage-header'>
                    {selected.length > 0
                    ?   <button
                            className='active-button'
                            onClick={() => handleAddPages()}
                        >
                            Add {selected.length} page{selected.length > 1 ? 's' : null}
                        </button>
                    :   <div>
                            Select pages to add
                        </div>
                    }
                </div>
            }

            {numPages 
            ?   <div className='contractortakeoff-pdfpage-container'>
                    {[...Array(numPages)].map((e, i) =>
                        <div 
                            key={i}
                            onClick={() => {
                                if (selected.includes(i)){
                                    setSelected(prev => {
                                        let temp = [...prev];
                                        temp.splice(temp.indexOf(i), 1);
                                        return temp;
                                    })
                                }else{
                                    setSelected(prev => [...prev, i]);
                                }
                            }}
                            className={selected.includes(i) ? 'contractortakeoff-pdfpage-selected' : 'contractortakeoff-pdfpage'}
                        >
                            <PDFPage
                                pdf={pdf}
                                pageNumber={i + 1}
                            />
                        </div>
                    )}
                </div>
            :   <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...  
                </div>
            }
        </div>
    );
}

function PDFPage({ pdf, pageNumber }){
  const canvasRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the page
        const page = await pdf.getPage(pageNumber);

        // Prepare canvas using PDF page dimensions
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale: 0.2 });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext);
      } catch (error) {
        console.error('Error rendering page:', error);
      }
    };

    fetchData();
  }, [pdf, pageNumber]);

  return (
    <div className="contractortakeoff-pdfpage-canvas-container">
        <canvas 
            ref={canvasRef} 
            className='contractortakeoff-pdfpage-canvas'
        />
    </div>
  );
};

function AddPDF({ file, projectUUID, user, takeOff, setFiles, upload, setIndividual, setTrades }){
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleAddPage = () => {
        setLoading(true);

        if (upload){
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/add-pdf-contractor-project-trades-takeoff-page/',
                data: {
                    takeoffID: takeOff.id,
                    uploadID: file.id,
                    pageNumber: pageNumber,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);

                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[response.data.file.id] = {
                        ...response.data.file,
                        'items': {},
                        'polygons': {},
                        'rectangles': {},
                        'lengths': {},
                    }
                    return temp;
                })

                setTrades(prev => {
                    let temp = {...prev};
                    Object.values(temp).forEach((trade) => {
                        Object.values(trade.subtrades).forEach((subtrade) => {
                            subtrade.files[response.data.file.id] = {
                                ...response.data.file,
                                'items': {},
                                'polygons': {},
                                'rectangles': {},
                                'lengths': {},
                            }
                        })
                    })
                    return temp;
                })

                setLoading(false);
                setFiles(prev => ({
                    ...prev,
                    [response.data.file.id]: {
                        ...response.data.file,
                        position_x: 0,
                        position_y: 0,
                    }
                }))
                
                window.alert('Page added!');
            })
            .catch((error) => {
                console.log(error);
            })
        } else {
            axios({
                method: 'post',
                url: 'https://bob-backendapi.herokuapp.com/api/add-pdf-contractor-project-trades-takeoff-page/',
                data: {
                    takeoffID: takeOff.id,
                    fileID: file.id,
                    pageNumber: pageNumber,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log(response);
    
                setIndividual(prev => {
                    let temp = {...prev};
                    temp.files[response.data.file.id] = {
                        ...response.data.file,
                        'items': {},
                        'polygons': {},
                        'rectangles': {},
                        'lengths': {},
                    }
                    return temp;
                })

                setTrades(prev => {
                    let temp = {...prev};
                    Object.values(temp).forEach((trade) => {
                        Object.values(trade.subtrades).forEach((subtrade) => {
                            subtrade.files[response.data.file.id] = {
                                ...response.data.file,
                                'items': {},
                                'polygons': {},
                                'rectangles': {},
                                'lengths': {},
                            }
                        })
                    })
                    return temp;
                })

                setLoading(false);
                setFiles(prev => ({
                    ...prev,
                    [response.data.file.id]: {
                        ...response.data.file,
                        position_x: 0,
                        position_y: 0,
                    }
                }))

                window.alert('Page added!');
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }

    return (
        <div className='contractortakeoff-addpage-input-container'>
            {loading 
            ?   <div className="editproject-sowgeneration-spinner-container">
                    <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                    Loading...
                </div>  
            :   <>
                Page: <input
                    type='number'
                    min='1'
                    max={numPages}
                    value={pageNumber}
                    className='contractortakeoff-addpage-input'
                    onChange={(e) => setPageNumber(e.target.value)}
                />
                {pageNumber && pageNumber <= numPages && pageNumber > 0 &&
                    <button className='default-button' onClick={() => handleAddPage()}>Add</button>
                }
                </>
            }

            
            <Document file={file.file} onLoadSuccess={onDocumentLoadSuccess}>
            </Document>
        </div>
    )
}