import { useState, useEffect } from 'react';
import "../styles/LandingPage.css";
import axios from 'axios';
import emailjs from '@emailjs/browser';

export default function LandingPage() {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [success, setSuccess] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedType, setSelectedType] = useState("");

    function buttonDisabled() {
        if (selectedType === "" || selectedType === "Select") {
            return true;
        }

        /*if (selectedType === "Homeowner" && (!selectedDate || selectedDate === "" || selectedDate === "Select")) {
            return true;
        }*/

        if (emailError !== "" || email === "" || submitted) {
            return true;
        }

        return false;
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        if (!email) {
            setEmailError("");
        } else {
            if (validateEmail(email)) {
                setEmailError("");
            } else {
                setEmailError("Please enter a valid email.");
            }
        }
        setSuccess("");
    }, [email]);

    async function handleSubmit(e) {
        /*ReactGA.event({
            category: 'User',
            action: 'User submitted email to waitlist',
            label: 'User submitted email to waitlist',
        });*/

        e.preventDefault();
        var form_data = {
            email: email,
            selected_date: selectedDate,
            type: selectedType,
            alpha: isChecked,
        };
        console.log(form_data);

        await axios({
            method: 'post',
            url: 'https://bob-backendapi.herokuapp.com/api/waitlist/', //verfies email and password; returns response
            data: form_data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then((response) => {
            console.log(response);
            setSuccess("You have joined the waiting list!");
            setSubmitted(true);
        })
        .catch((error) => {
            setEmailError("Failed to add to wait list. Please try again.");
            console.log(error);
        });

        let message = '';

        if (selectedType === "Homeowner") {
            message = 
            `Hey,

            Thank you so much for filling out our waitlist!
            
            My name is Michael Ding, and I am a Stanford CS undergraduate and the co-founder of Bobyard. Right now, we are offering a pre-service to members of the Bay Area community, where we work with you to create detailed SOWs and contracts for your home remodeling project. If you are interested in learning more, please share a few times that work best for you to hop on a Zoom call in the coming days to go over how we can help in more detail. 
            
            We built bobyard to champion owners and would love to work with you!
            
            Best,
            Michael Ding            
            `;
        }else if (selectedType === "Contractor") {
            message = 
            `Hey,

            Thank you so much for filling out our waitlist!
            
            My name is Michael Ding, a Stanford CS undergraduate and the co-founder of Bobyard. Right now, we are offering exclusive early access to our platform for contractors in the Bay Area. If you are interested, please share a few times that work best for you to hop on a Zoom call in the coming days.
            
            Please let me know if you have any questions about how we can help you!
            
            Best,
            Michael Ding            
            `;
        }

        emailjs.send('service_p6pe8ud', 'template_yynx8io', {
            subject: 'Bobyard Waitlist',
            message: message,
            to_email: email,
            from_name: 'Bobyard',
            reply_to: "info@bobyard.com",
            bcc: "mding26@stanford.edu",
        }, 'UPyEH6AdQ86GCk5j6')
        .then((result) => {
            console.log(result);
        }, (error) => {
            console.log(error); 
        });
    }
    return (
        <div className='landing-background'>
            {/*<div className='landing-popup'>
                <div className='landing-popup-title'>Connect with contractors!</div>

                <div className='landing-popup-body'>
                    Bobyard is hosting networking events for owners with projects and the best contractors in the Bay Area. To attend, make a Bobyard account, create a project, then sign up with the same email.
                </div>

                <div className='landing-popup-footer'>
                    <button className='active-button'>Sign up!</button>
                </div>        
            </div>*/}
            <div className="landing-header">
                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/logo.png"} alt="Logo" className='landing-logo-img' />                
                <div className="landing-title">
                    {/*<span className='landing-title-name'>Bobyard</span>*/}Bobyard bridges the owner-contractor gap.
                </div>
                <div className="landing-title-1230">
                    {/*<span className='landing-title-name'>Bobyard</span>*/}<nobr>Bobyard bridges the</nobr> <nobr>owner-contractor gap.</nobr>
                </div>
                <div className="landing-desc">
                    Easily communicate your vision to contractors through AI generated scopes of work and contracts, then get comparable bids on them.
                </div>
                
                <div className="landing-desc-try">
                    <b>Try Bobyard today, it's free!</b>
                </div>

                <div className='landing-register-container'>
                    <div><a href="/register-choice" className="homepage-startnow-link"><button className='button-29'>Get started</button></a></div>
                    <div><a href="/demo" className="homepage-startnow-link"><button className='button-29'>Book demo</button></a></div>
                </div>
            
                {/*<form onSubmit={handleSubmit}>
                    <div className='landing-form-conatiner'>
                        <div className='landing-form__group'>
                            <input
                                type="email"
                                onChange={e => {
                                    setEmail(e.target.value)
                                    setSubmitted(false)
                                }}
                                className="landing-form__field"
                                placeholder="Your email" name="name" id='name' required
                            />
                            <label className="landing-form__label">Please enter your email</label>
                            <div className="landing-error">{emailError}</div>
                        </div>

                        <div className='landing-form__group'>
                            <div className='landing-selectdate-question'>Are you a homeowner or contractor?</div>
                            <select
                                id="select"
                                className="landing-form__field selectdate"
                                name="select"
                                required
                                onChange={e => setSelectedType(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="Homeowner">Homeowner</option>
                                <option value="Contractor">Contractor</option>
                            </select>
                        </div>

                        selectedType === "Homeowner" &&
                            <div className='landing-form__group'>
                            <div className='landing-selectdate-question'>When are you planning to do a project?</div>
                            <select
                                id="select"
                                className="landing-form__field selectdate"
                                name="select"
                                required
                                onChange={e => setSelectedDate(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="Right now!">Right now!</option>
                                <option value="Next 3-5 months">Next 3-5 months</option>
                                <option value="More than 6 months">More than 6 months</option>
                            </select>
                        </div>
                        

                        <div className='landing-form__group landing-alphacontainer'>
                            <div>
                                Are you intersted in being an alpha user?
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    className='landing-alphacheck'
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                            </div>
                        </div>

                        <div className='landing-header-bottom'>                         
                            {submitted
                                ? <div className="landing-success">{success}</div>
                                : <div>
                                    <button
                                        type="submit"
                                        className="button-29"
                                        style={buttonDisabled() ? { 'pointer-events': 'none' } : {}}
                                        disabled={buttonDisabled()}
                                    >
                                        Join Waitlist
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </form>*/}
            </div>

            <div id="how-it-works" className="homepage-process">
                <div className='homepage-steps-container'>
                    <div className='homepage-process-container'>
                        <div className='homepage-step-title'>The Bobyard process</div>
                        <div className='homepage-step-desc'>
                            Bobyard prevents frustrations from occurring in a project by providing a platform for owners and contractors to thoroughly plan their projects ahead of time. Our tools and services help owners and contractors communicate their vision and expectations to each other, so that they can be on the same page.
                        </div>
                    </div>
                    <div className="homepage-step">
                        <div className='homepage-step-number'>
                            <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-06-03+at+4.26.48+PM.png"} className='homepage-sow-image' />
                        </div>
                        <div className='homepage-step-desc-container homepage-step-desc-container-right'>
                            <div className='homepage-mobile-image-container'>
                                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-06-03+at+4.26.48+PM.png"} className='homepage-mobile-image' />
                            </div>
                            <div className='homepage-step-title'><nobr>Generate scope</nobr> of work</div>
                            <div className='homepage-step-desc'>
                                Create a project profile and let our system generate a comprehensive scope of work for you. It generates punch lists and materials so nothing gets left out.
                            </div>
                        </div>
                    </div>
                    <div className="homepage-step">
                        <div className='homepage-step-desc-container homepage-step-desc-container-left'>
                            <div className='homepage-mobile-image-container'>
                                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/map.png"} className='homepage-mobile-image' />
                            </div>
                            <div className='homepage-step-title'>Market information</div>
                            <div className='homepage-step-desc'>
                                Free access to high fidelity market prices, so you know your deal is good.
                            </div>
                        </div>
                        <div className='homepage-step-number'>
                            <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/map.png"} className='homepage-map-img' />
                        </div>
                    </div>
                    <div className="homepage-step">
                        <div className='homepage-step-number'>
                            <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-06-16+at+4.37.05+PM.png"} className='homepage-commpare-img' />
                        </div>
                        <div className='homepage-step-desc-container homepage-step-desc-container-right'>
                            <div className='homepage-mobile-image-container'>
                                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-06-16+at+4.37.05+PM.png"} className='homepage-mobile-image' />
                            </div>
                            <div className='homepage-step-title'><nobr>Expert review</nobr></div>
                            <div className='homepage-step-desc'>
                                Request an expert review of your scope of work before publishing for bids to ensure that it is comprehensive and accurate. 
                            </div>
                        </div>
                    </div>
                    <div className="homepage-step">
                        <div className='homepage-step-desc-container homepage-step-desc-container-right'>
                            <div className='homepage-mobile-image-container'>
                                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/compare.png"} className='homepage-mobile-image' />
                            </div>
                            <div className='homepage-step-title'><nobr>Compare bids</nobr></div>
                            <div className='homepage-step-desc'>
                                Easily compare bids and contractors' backgrounds on your unified contract.
                            </div>
                        </div>
                        <div className='homepage-step-number'>
                            <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/compare.png"} className='homepage-commpare-img' />
                        </div>
                    </div>
                    <div className="homepage-step">
                        <div className='homepage-step-number'>
                            <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/generate-contract.png"} className='homepage-contract-img' />
                        </div>
                        <div className='homepage-step-desc-container homepage-step-desc-container-left'>
                            <div className='homepage-mobile-image-container'>
                                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/generate-contract.png"} className='homepage-mobile-image' />
                            </div>
                            <div className='homepage-step-title'>Sign contract</div>
                            <div className='homepage-step-desc'>
                                Generate a protective contract and send it to your contractor for signatures so you can feel safe.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='homepage-final'>
                {/*<img src={background2} className='backgroudn2-img'/>*/}
                <div className='homepage-final-title'>Remodel your home today,<br /> it's simple!</div>
                <div className='landing-register-container'>
                    <div><a href="/register-choice" className="homepage-startnow-link"><button className='button-29'>Get started</button></a></div>
                    <div><a href="/demo" className="homepage-startnow-link"><button className='button-29'>Book demo</button></a></div>
                </div>
            </div>

            <div className='homepage-footer-container'>
                <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/logo.png"} className='homepage-footer-logoimg' />
                <div>
                    <div>Venture backed</div>
                    <div>Bobyard, Inc 2023</div>
                </div>
            </div>
        </div>
    );
}