

import { useEffect, useState } from "react";
import "../styles/ContractorAccount.css"
import axios from "axios";

const stripe = require('stripe')('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');

export default function ContractorAccount({ user }) {
    const [customer, setCustomer] = useState(null);
    const [loadingMonthly, setLoadingMonthly] = useState(false);
    const [loadingAnnually, setLoadingAnnually] = useState(false);

    useEffect(() => {
        stripe.customers.list({
            email: user.email,
            expand: ['data.subscriptions']
        })
        .then((response) => {
            console.log(response);
            setCustomer(response.data[0]);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [user]);

    const handleCheckoutMonthly = () => {
        setLoadingMonthly(true);

        axios({
            method: "POST",
            url: "https://bob-backendapi.herokuapp.com/api/checkout-contractor-monthly/",
            data: {},
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.data);

            setLoadingMonthly(false);

            if (response.data.id && response.data.url){
                window.location.href = response.data.url;
            }
        })
        .catch((error) => {
            console.log(error);
        })        
    }

    const handleCheckoutAnnually = () => {
        setLoadingAnnually(true);

        axios({
            method: "POST",
            url: "https://bob-backendapi.herokuapp.com/api/checkout-contractor-yearly/",
            data: {},
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.data);

            setLoadingAnnually(false);

            if (response.data.id && response.data.url){
                window.location.href = response.data.url;
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (
        <div className="contractorprojectsettings-container">
            <div className="contractorprojectsettings-header">
                <div className="contractorprojectsettings-title">Account details</div>
            </div>

            <div className="contractorprojectsettings-body">
                <div className="contractorprojectsettings-setting">
                    <div className="contractorprojectsettings-setting-title">Log out of your account</div>

                    <a href="/logout">
                        <button className="default-button">Log out</button>
                    </a>

                    {/*get current time and compare it to trial end of user.subsecriptions.data[0] */}

                    {/*
                    {customer && customer.subscriptions.data[0] && customer.subscriptions.data[0].trial_end > Date.now() / 1000 && 
                        <>
                            <hr className="contractorprojectsettings-setting-linebreak"></hr>

                            <div className="contractorprojectsettings-setting-title">Free trial</div>

                            <div className="contractorprojectsettings-setting-desc">
                                <div>Days left on free trial: {Math.floor((customer.subscriptions.data[0].trial_end - Date.now() / 1000) / 86400)}</div>
                                <div>Ends on: {new Date(customer.subscriptions.data[0].trial_end * 1000).toLocaleDateString()}</div>
                            </div>
                        </>
                    }*/}

                    {customer?.subscriptions.data[0]
                    ?   <>
                            <hr className="contractorprojectsettings-setting-linebreak"></hr>

                            <div className="contractorprojectsettings-setting-title">Plan details</div>

                            <div className="contractorprojectsettings-setting-desc">
                                <div>Current plan: {customer && customer.subscriptions.data[0] && customer.subscriptions.data[0].plan.interval === "month" ? "Monthly" : "Yearly"}</div>
                                <div>Pricing: {customer && customer.subscriptions.data[0] && customer.subscriptions.data[0].plan.interval === "month" ? "$50/month" : "$500/year"}</div>

                                <br></br>
                                
                                <div>
                                    <a href="https://billing.stripe.com/p/login/28o3fkeoGd1RelOdQQ" target="_blank">
                                        <button className="default-button">Manage plan</button>
                                    </a>
                                </div>
                            </div>
                        </>
                    :   <>
                            <hr className="contractorprojectsettings-setting-linebreak"></hr>

                            <div className="contractorprojectsettings-setting-title">Plan details</div>

                            <div className="contractorprojectsettings-setting-desc">
                                <div>Current plan: Free</div>
                                <div>5 free projects</div>  
                                <div>5 takeoff pages per project</div>
                                <div><b>Upgrade to a paid plan to get unlimited projects and takeoff pages.</b></div>                         
                            </div>

                            <br></br>

                            <div className="contractoraccount-plans-container">                        
                                <div className="pricing-plan-container">
                                    <div className="pricing-plan-header">Monthly</div>
                                    <div className="pricing-plan-price-container">
                                        <span className="pricing-plan-price">$50</span> / month
                                    </div>
                                    {loadingMonthly 
                                    ?   <div className="pricing-loading-container">
                                            <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                        </div>  
                                    :   <button 
                                            className="active-button"
                                            onClick={() => handleCheckoutMonthly()}
                                        >
                                            Upgrade to monthly
                                        </button>
                                    }
                                </div>

                                <div className="pricing-plan-container">
                                    <div className="pricing-plan-header">Annually</div>

                                    <div className="pricing-plan-discount">17% Savings</div>
                                    <div className="pricing-plan-price-container">
                                        <span className="pricing-plan-price">$500</span> / year
                                    </div>
                                    <p>
                                    <span className="pricing-plan-savings">Save $100</span> vs monthly
                                    </p>
                                    {loadingAnnually
                                    ?   <div className="pricing-loading-container">
                                            <div className="loading-spinner editproject-sowgeneration-spinner"></div>
                                        </div>
                                    :   <button 
                                            className="active-button"
                                            onClick={() => handleCheckoutAnnually()}
                                        >
                                            Upgrade to annually
                                        </button>
                                    } 
                                </div>
                            </div>
                        </>   
                    }
                </div>
            </div>
        </div>
    )
}